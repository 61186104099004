import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, forkJoin } from 'rxjs';
import { ClaimsService } from '../../services/claims.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from '../../services/common.service';
import { ClaimStatuses } from '../../models/claim.model';
import { ApiResponse, LinksEnum, UserRoles } from '../../models/app.model';
import { ExecutionTabService } from 'src/app/modules/service-order/services/execution-tab.service';
import { ServiceOrderService } from 'src/app/modules/service-order/services/service-order.service';
import { CostTypes, IscEmployeeModel, IscRuleModel } from 'src/app/modules/service-order/models/service-order.model';
import { IscSOClaimsComponent } from './isc-so-claims/isc-so-claims.component';
import { IscClaimsRulesBusinessComponent } from './isc-claims-business-rules/isc-claims-business-rules.component';
import { CLAIM_APPROVED_PROMPT, CLAIM_COMPLETED_OPENED_FlAGGED_CASE_PROMPT, CLAIM_COMPLETED_PROMPT, CLAIM_REJECTED_PROMPT } from '../../messages';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-isc-claims-popup',
    templateUrl: './isc-claims-popup.component.html',
    styleUrls: ['./isc-claims-popup.component.scss']
})
export class IscClaimsPopupComponent implements OnInit {

    @Output() onClose = new EventEmitter();
    @Output() onLinkIdClick = new EventEmitter();

    @Input() tab = 'details';
    @Input() serviceOrderId = null;
    @Input() claimId = null;
    @Input() showComments = false;
    @Input() triggerSaveAction = '';

    @ViewChild('claimItemsTab') claimItemsTab: IscSOClaimsComponent;
    @ViewChild('businessRulesRef') businessRulesComponent: IscClaimsRulesBusinessComponent;
    
    showLineComments: boolean = false
    
    claimPopupForm: UntypedFormGroup;
    
    showLoader: boolean = false;
    isExpanded: boolean = true;
    
    title: string = 'Claims'
    defaultCommentValue: string = ''
    lineId: string = ''
    lineComment;

    businessClaimRules = null;
    referenceActivities;
    statusList;
    isCSA;
    USER;
    costTypes: CostTypes;

    reviewerData
    paymentMethodData
    pausedReasonData
    iscRulesForSO: IscRuleModel;
    iscEmployeeDetails: IscEmployeeModel

    showAlternateInvoiceNumber: boolean;
    showReviewer: boolean;
    showPayVia : boolean;
    showClosingDate : boolean;
    showPausedReason : boolean;
    showRejectionReason : boolean;
    showRulesSection: boolean;
    showInstallDate: boolean;
    
    editAlternateInvoiceNumber: boolean;
    editReviewer: boolean;
    editPayVia : boolean;
    editClosingDate : boolean;
    editPausedReason : boolean;
    editRejectionReason : boolean;

    statusMenuItem = [];
    businessRules = [];
    showServiceOrderPopup: boolean;
    serviceOrderData: any;
    dataFetchedFromAPI: boolean = false;
    showReasonCodePopup: boolean;
    statusChangeAction: string;
    allStatuses: any;
    validationApplied: boolean = false;
    links: any;
    soReferenceActivities: any;

    constructor(
        private claimsService: ClaimsService,
        private serviceOrderService: ServiceOrderService,
        private commonService: CommonService,
        private executionTabService: ExecutionTabService,
        private fb: UntypedFormBuilder
    ) {
        this.USER = this.commonService.USER;
        this.isCSA = this.USER.role === UserRoles.csa;
        this.statusMenuItem = this.getAllStatusMenuItems();
        this.businessRules = this.setBusinessRules();
    }

    getAllStatusMenuItems() {
        return [
            {
                status: 'Open',
                option: 'Save & Set to Open'
            },
            {
                status: 'Submitted',
                option: 'Save & Submit (CSA)'
            },
            {
                status: 'In Progress',
                option: 'Save & Set to In Progress'
            },
            {
                status: 'Awaiting Information',
                option: 'Save & Set to Awaiting Information (ISC)'
            },
            {
                status: 'Pending Approval',
                option: 'Save & Set to Pending Approval (FSM)'
            },
            {
                status: 'Rejected',
                option: 'Save & Set to Rejected'
            },
            {
                status: 'Completed',
                option: 'Save & Set to Completed'
            },
            {
                status: 'Paused',
                option: 'Save & Set to Paused'
            }
        ];
    }
    setBusinessRules() {
        return [
            {
              "id": 1,
              "rule": "Service Type(s) in service order are not TRN, POL, PAD, DEM, WSC, RFB, ECN",
              "status": "Failed"
            },
            {
              "id": 2,
              "rule": "Install Date Available",
              "status": "Failed"
            },
            {
              "id": 3,
              "rule": "Install Date on INS match Service Date",
              "status": "Failed"
            },
            {
                "id": 4,
                "rule": "Service Date Less than 30 Days",
                "status": "Failed"
            },
            {
                "id": 5,
                "rule": "Travel under 100 Miles (US); 200 KM (CA)",
                "status": "Failed"
            },
            {
                "id": 6,
                "rule": "ISC Claim is under limit",
                "status": "Failed"
            },
            // {
            //   "id": 4,
            //   "rule": "Serial Number Available (Non WO Types)",
            //   "status": "Failed"
            // },
            // {
            //   "id":6,
            //   "rule": "Labor Rate $82 or less. Canada BC $94 or Less.",
            //   "status": "Failed"
            // },
            // {
            //   "id": 8,
            //   "rule": "Service Type TRN Training",
            //   "status": "Failed"
            // }
        ];
    }

    ngOnInit(): void {
        this.claimPopupForm = this.fb.group({
            id: [''],
            claimId: [''],
            distanceZone: [''],
            estimatedMiles: [''],
            serviceOrderId: [''],
            serviceOrderIdLN: [''],
            soCustomerCode: [''],
            technicianId: [''],
            technicianName: [''],
            status: ['Open'],
            model: [''],
            serialNumber: [''],
            serviceCompletionDate: [''],
            serviceManager: [''],
            serviceManagerName: [''],
            currency: [''],
            country: [''],
            callGroup: [''],
            installationDate: [''],
            includeTax: [false],
            isNationalAccount: [false],
            claimAmount: [0],
            disputedAmount: [0],
            linkCount: [''],
            attachmentCount: [''],
            commentCount: [''],
            netClaimAmount: [0],
            taxAmount: [0],
            totalClaimAmount: [0],
            alternateInvoiceNumber: [''],
            reviewer: [''],
            payVia: [''],
            submissionDate: [''],
            closingDate: [''],
            pausedReason: [''],
            rejectionReason: ['', Validators.required],
            iscClaimDetails: this.fb.array([]),
            iscBusinessRules: this.fb.array([]),
            businessRulesPassed: [false],
            iscCustomerRules: this.fb.array([]),
            comments: this.fb.array([]),
            attachments: this.fb.array([]),
            history: this.fb.array([]),
            iscNotes: [''],
            iscSoldToBP: [''],
            serviceDescription: [''],
            customerComplaint: [''],
            fstCommentsToCustomer: [''],
            createdDate: [''],
            createdBy: [''],
            createdByName: [''],
            lastUpdatedDate: [''],
            lastUpdatedBy: [''],
            lastUpdatedByName: ['']
        });
        this.getClaimsData()
    }

    setFieldsByClaimRules() {
        this.setVisiblityPermissions();
        this.setEditPermissionsByRole();
    }

    setVisiblityPermissions() {
        this.showAlternateInvoiceNumber = this.commonService.roleClaims['Claims_Popup_Fields_Alternate_Invoice_Number']?.visible;
        this.showReviewer = this.commonService.roleClaims['Claims_Popup_Fields_Reviewer']?.visible && this.claimPopupForm.value.status !== ClaimStatuses.open;
        this.showPayVia = this.commonService.roleClaims['Claims_Popup_Fields_Pay_Via']?.visible && this.claimPopupForm.value.status !== ClaimStatuses.open;
        this.showClosingDate = this.commonService.roleClaims['Claims_Popup_Fields_Closing_Date']?.visible && this.claimPopupForm.value.status !== ClaimStatuses.open;
        this.showPausedReason = this.commonService.roleClaims['Claims_Popup_Fields_Paused_Reason']?.visible && this.claimPopupForm.value.pausedReason;
        this.showRejectionReason = this.commonService.roleClaims['Claims_Popup_Fields_Rejection_Reason']?.visible && this.claimPopupForm.value.status === ClaimStatuses.rejected;
        this.showInstallDate = this.commonService.roleClaims['Claims_Popup_Fields_InstallDate']?.visible;
        this.showRulesSection = this.commonService.roleClaims['Claims_Popup_Rules_Section']?.visible;
    }

    setEditPermissionsByRole() {
        const isReviewer = this.claimPopupForm.value?.reviewer && this.claimPopupForm.value.reviewer === this.USER.employeeId;
        const isManager = this.USER.role === UserRoles.conwayServiceManager;
        const isCsa = this.USER.role === UserRoles.csa;
        
        this.editAlternateInvoiceNumber = this.commonService.roleClaims['Claims_Popup_Fields_Alternate_Invoice_Number']?.edit 
                            && [ClaimStatuses.open, ClaimStatuses.awaitingInfo].includes(this.claimPopupForm.value.status);
        this.editReviewer = this.commonService.roleClaims['Claims_Popup_Fields_Reviewer']?.edit 
                            && (isManager && [ClaimStatuses.inProgress, ClaimStatuses.pendingApproval, ClaimStatuses.paused].includes(this.claimPopupForm.value.status)
                                || (isCsa && [ClaimStatuses.inProgress, ClaimStatuses.submitted, ClaimStatuses.paused].includes(this.claimPopupForm.value.status)));
        this.editPayVia = (isReviewer || isManager) && this.commonService.roleClaims['Claims_Popup_Fields_Pay_Via']?.edit 
                            && [ClaimStatuses.inProgress, ClaimStatuses.pendingApproval].includes(this.claimPopupForm.value.status);
        this.editClosingDate = (isReviewer || isManager) && this.commonService.roleClaims['Claims_Popup_Fields_Closing_Date']?.edit 
                            && [ClaimStatuses.completed].includes(this.claimPopupForm.value.status);
        this.editPausedReason = (isReviewer || isManager) && this.commonService.roleClaims['Claims_Popup_Fields_Paused_Reason']?.edit 
                            && [ClaimStatuses.paused].includes(this.claimPopupForm.value.status);
        this.editRejectionReason =  (isReviewer || isManager) && this.commonService.roleClaims['Claims_Popup_Fields_Rejection_Reason']?.edit 
                            && [ClaimStatuses.rejected].includes(this.claimPopupForm.value.status);
    }

    getClaimsData() {
        const apis = [
            this.claimsService.getClaimsStatuses(),
            this.claimsService.getReviewersList(),
            this.claimsService.getISCPaymentMethods(),
            this.claimsService.getReasonsForDDL(this.USER.company,'Claim Paused'),
            this.serviceOrderService.getIscRuleForServiceOrder(this.serviceOrderId),
            this.executionTabService.getIscCostTypes(),
            this.claimsService.getReferenceActivitiesByServiceOrderId(this.serviceOrderId)
        ];
        if(this.claimId){
            apis.push(this.claimsService.getClaimByClaimId(this.claimId));
            apis.push(this.commonService.getLinkedRecords(LinksEnum.claims, this.claimId));
        } else {
            apis.push(this.claimsService.getClaimDetailByServiceOrderId(this.serviceOrderId));
        }
        this.showLoader = true;
        forkJoin(apis).subscribe((res: any) => {
            if(res[0].result && res[7].result) {
                this.allStatuses = res[0].result;               
                this.reviewerData = res[1].result;
                this.paymentMethodData = res[2].result;
                this.pausedReasonData = res[3].result;
                this.iscRulesForSO = res[4].result;
                this.soReferenceActivities = res[6].result;
                this.claimPopupForm.patchValue(res[7].result)
                // this.claimPopupForm.get('status').setValue('Submitted')
                this.statusList = this.getStatusList();
                this.costTypes = res[5].result;
                this.title = res[7].result.claimId + (res[7].result.serviceOrderId? ' - ' + res[7].result.serviceOrderId : '') + (res[7].result.serviceOrderIdLN ? ' - ' + res[7].result.serviceOrderIdLN : '') + (res[7].result.soCustomerDesc ? ' - ' + res[7].result.soCustomerDesc : '')
                this.links = this.claimId ? res[8].result : []; 
                const iscClaimDetailsArray = this.claimPopupForm.get('iscClaimDetails') as UntypedFormArray;
                const iscClaimDetailsData = res[7].result.iscClaimDetails;
                iscClaimDetailsArray.clear();
                iscClaimDetailsData.forEach((itemData, index) => {
                    itemData.rowId = index + 1;
                    itemData.taxAmount = +(itemData.totalAmount * itemData.taxPercent / 100).toFixed(2);
                    const itemFormGroup = this.fb.group(itemData);
                    iscClaimDetailsArray.push(itemFormGroup);
                    itemFormGroup.get('lineComments').patchValue(itemData.lineComments);
                });
                const iscBusinessRulesArray = this.claimPopupForm.get('iscBusinessRules') as UntypedFormArray;
                const iscBusinessRulesData = this.businessRules;
                iscBusinessRulesArray.clear()
                iscBusinessRulesData ? iscBusinessRulesData.forEach((rule) => {
                    const businessRule = this.fb.group(rule);
                    iscBusinessRulesArray.push(businessRule);
                }) : [];
                const iscCustomerRulesArray = this.claimPopupForm.get('iscCustomerRules') as UntypedFormArray;
                const iscCustomerRulesData = res[7].result.iscCustomerRules
                iscCustomerRulesArray.clear()
                iscCustomerRulesData ? iscCustomerRulesData.forEach((itemData, index) => {
                    const itemFormGroup = this.fb.group(itemData);
                    iscCustomerRulesArray.push(itemFormGroup);
                }) : [];
                const attachmentsArray = this.claimPopupForm.get('attachments') as UntypedFormArray;
                const attachmentsData = res[7].result.attachments
                attachmentsArray.clear()
                attachmentsData ? attachmentsData.forEach((itemData) => {
                    const itemFormGroup = this.fb.group(itemData);
                    attachmentsArray.push(itemFormGroup);
                }) : [];
                const historyArray = this.claimPopupForm.get('history') as UntypedFormArray;
                const historyData = res[7].result.history
                historyArray.clear()
                historyData ? historyData.forEach((itemData) => {
                    const itemFormGroup = this.fb.group(itemData);
                    historyArray.push(itemFormGroup);
                }) : [];
                const commentsArray = this.claimPopupForm.get('comments') as UntypedFormArray;
                const commentsData = res[7].result.comments
                commentsArray.clear()
                commentsData ? commentsData.forEach((itemData) => {
                    const itemFormGroup = this.fb.group(itemData);
                    commentsArray.push(itemFormGroup);
                }) : [];
                this.statusMenuItem = this.getAllStatusMenuItems();

                this.callGetApis();
                this.dataFetchedFromAPI = true;
                this.claimItemsTab?.showRoleBasedElementsInToolbar(this.claimPopupForm.value.status);
                this.claimItemsTab?.setActions();
                this.setFieldsByClaimRules();
                setTimeout(() => {
                    if (this.triggerSaveAction) {
                        const status = this.triggerSaveAction === 'Complete' ? ClaimStatuses.completed : ClaimStatuses.rejected;
                        this.promptUserBeforeSave(status, `Save & Set to ${status}`);
                    }
                });
            }
            this.showLoader = false;
        }, (error: HttpErrorResponse) => {
            this.showLoader = false;
        });
    }

    callGetApis() {
        forkJoin([
            this.executionTabService.getReferenceActivities(this.USER.company, this.claimPopupForm.value.callGroup),
            this.serviceOrderService.getIscServiceEmployeeDetailsById(this.claimPopupForm.value.technicianId || this.USER.employeeId),
            // this.executionTabService.getCallOutCharge(this.claimPopupForm.value.distanceZone, 0)
        ]).subscribe((res: any) => {
            if (res[0]?.isSuccessful && res[1]?.isSuccessful /*&& res[3]?.Data*/) {
                // get reference activities response
                this.referenceActivities = res[0].result;
                this.claimItemsTab?.updateBusinessRulesStatuses(this.claimPopupForm.value, this.referenceActivities);
                this.businessRulesComponent?.grid?.refresh();
                this.udpateStatusList();

                // isc service employee details by Id response
                this.iscEmployeeDetails = res[1].result;

                // get callout rate response
                // this.calloutRate = res[2].Data.callOutCharge;
            } else {
                this.commonService.showNotification('error', res.message);
            }

            this.showLoader = false;
        }, error => {
            this.showLoader = false;
            this.commonService.showNotification('error', error.message);
        });
    }

    udpateStatusList() {
        this.statusList = this.getStatusList();
        this.statusMenuItem = this.getAllStatusMenuItems().filter(arrayItem1 =>
            this.statusList.some(arrayItem2 => (arrayItem1.status === arrayItem2.status) && (arrayItem1.status != this.claimPopupForm.value.status))
        );
        this.statusMenuItem.push({
            status: this.claimPopupForm.value.status,
            option: 'Save'
        });
    }

    getDefaultStatus(status) {
        return [{
            status: status,
            option: 'Save'
        }];
    }

    getStatusList(){
        if(!this.claimId || this.claimPopupForm.value.status === ClaimStatuses.open){
            if([UserRoles.isc, UserRoles.iscManager, UserRoles.IscSupervisor].includes(this.USER.role)) {
                return this.allStatuses.filter(dataRow => [ClaimStatuses.submitted].includes(dataRow.status));
            } else {
                return [{
                    status: ClaimStatuses.open,
                    option: 'Save'
                }];
            }
        } else if(this.claimId){
            const assignedReviewer : boolean = this.USER.employeeId === this.claimPopupForm.value.reviewer;
            switch(this.claimPopupForm.value.status){
                case ClaimStatuses.submitted:
                    if(this.USER.role === UserRoles.csa){
                        if ( !this.claimPopupForm.value.reviewer || assignedReviewer) {
                            return this.allStatuses.filter(dataRow => [
                                ClaimStatuses.inProgress, ClaimStatuses.paused
                            ].includes(dataRow.status));
                        } else {
                            return this.getDefaultStatus(ClaimStatuses.submitted);
                        }
                    } else {
                        return this.getDefaultStatus(ClaimStatuses.submitted);
                    }
                case ClaimStatuses.paused:
                    if(this.USER.role === UserRoles.csa && assignedReviewer){
                        return this.allStatuses.filter(dataRow => [
                            ClaimStatuses.inProgress
                        ].includes(dataRow.status));
                    } else {
                        return this.getDefaultStatus(ClaimStatuses.paused);
                    }
                case ClaimStatuses.inProgress:
                    if(this.USER.role === UserRoles.csa && assignedReviewer){
                        let statuses = this.allStatuses.filter(dataRow => [
                            ClaimStatuses.paused,
                            ClaimStatuses.awaitingInfo,
                            ClaimStatuses.pendingApproval,
                        ].includes(dataRow.status));
                        if ( this.claimPopupForm.value.businessRulesPassed ) {
                            statuses = statuses.concat(this.allStatuses.filter(x => [ClaimStatuses.completed,ClaimStatuses.rejected].includes(x.status)));
                        }
                        return statuses;
                    } else {
                        return this.getDefaultStatus(ClaimStatuses.inProgress);
                    }
                case ClaimStatuses.awaitingInfo:
                    if(this.USER.role === UserRoles.isc || this.USER.role === UserRoles.iscManager || this.USER.role === UserRoles.IscSupervisor){
                        return this.allStatuses.filter(dataRow => [
                            ClaimStatuses.submitted
                        ].includes(dataRow.status));
                    } else {
                        return this.getDefaultStatus(ClaimStatuses.awaitingInfo);

                    }
                case ClaimStatuses.pendingApproval:
                    if(this.USER.role === UserRoles.conwayServiceManager || this.USER.role === UserRoles.administratorRole){
                        return this.allStatuses.filter(dataRow => [
                            ClaimStatuses.paused,
                            ClaimStatuses.awaitingInfo,
                            ClaimStatuses.inProgress,
                            ClaimStatuses.completed,
                            ClaimStatuses.rejected
                        ].includes(dataRow.status));
                    } else {
                        return this.getDefaultStatus(ClaimStatuses.pendingApproval);
                    }
                case ClaimStatuses.rejected:
                    if(this.USER.role === UserRoles.csa && assignedReviewer || this.USER.role === UserRoles.conwayServiceManager){
                        return this.allStatuses.filter(dataRow => [
                            ClaimStatuses.open,
                        ].includes(dataRow.status));
                    } else {
                        return this.getDefaultStatus(ClaimStatuses.rejected);
                    }
                case ClaimStatuses.completed:
                    if( (this.USER.role === UserRoles.csa && assignedReviewer 
                        || this.USER.role === UserRoles.conwayServiceManager) 
                        && this.claimPopupForm.value.closingDate 
                        && this.commonService.getTimeDifferenceInHrs(this.claimPopupForm.value.closingDate) <= 24
                    ) {
                        return this.allStatuses.filter(dataRow => [
                            ClaimStatuses.inProgress,
                        ].includes(dataRow.status));
                    } else {
                        return this.getDefaultStatus(ClaimStatuses.completed);
                    }
            }
        }
    }

    onAttachmentsUpdate(data: any) {
        const attachmentsArray = this.claimPopupForm.get('attachments') as UntypedFormArray;
        attachmentsArray.clear()
        const attachmentsData = data
        attachmentsData ? attachmentsData.forEach((itemData) => {
            const itemFormGroup = this.fb.group(itemData);
            attachmentsArray.push(itemFormGroup);
        }) : [];
        this.claimPopupForm.get('attachmentCount').setValue(data.filter(comment => !comment.isDeleted).length);
    }

    onCommentUpdate(data: any) {
        const comments = data.map(comment => {
            if (comment.isNew) {
                comment.commentId = 0;
            }
            return comment;
        });
        const commentsArray = this.claimPopupForm.get('comments') as UntypedFormArray;
        commentsArray.clear()
        const commentsData = data
        commentsData ? commentsData.forEach((itemData) => {
            const itemFormGroup = this.fb.group(itemData);
            commentsArray.push(itemFormGroup);
        }) : [];
        this.claimPopupForm.get('commentCount').setValue(data.filter(comment => !comment.isDeleted).length);
    }

    onLineCommentUpdate() {
        this.claimItemsTab.refreshGrid()
    }

    onServiceOrderClick() {
        this.serviceOrderService.getServiceOrderById(this.claimPopupForm.value.serviceOrderId, true)
        .subscribe((res:ApiResponse) => {
            if (res.isSuccessful) {
                this.serviceOrderData = res.result;
                this.showServiceOrderPopup = true;
            } else {
                this.commonService.showNotification('error', res.message);
            }
        }, error => {
            this.commonService.showNotification('error', error.message)
            throw error;
        });
    }

    onClosingDate(event) {
        if (event.isInteracted) {
          this.claimPopupForm.get('closingDate').setValue(event.value);
        }
    }

    approveAllFlaggedClaimLines() {
        this.claimPopupForm.value.iscClaimDetails.forEach( claimLine => {
            if (claimLine.status === ClaimStatuses.flagged)
                claimLine.status = ClaimStatuses.approved;
        });
        this.claimItemsTab.updateTotalClaimAmounts();
    }

    rejectAllClaimLines() {
        this.claimPopupForm.value.iscClaimDetails.forEach( claimLine => {
            claimLine.status = ClaimStatuses.rejected;
        });
    }

    saveReasonCodePopup(data) {
        this.claimPopupForm.get('status').setValue(this.statusChangeAction === ClaimStatuses.paused ? ClaimStatuses.paused : ClaimStatuses.rejected);
        this.statusChangeAction === ClaimStatuses.paused && this.claimPopupForm.get('pausedReason').setValue(data.reasonCode);
        this.statusChangeAction === ClaimStatuses.rejected && this.claimPopupForm.get('rejectionReason').setValue(data.reasonCode);
        this.save(this.claimPopupForm.value.status, true);
        this.closeReasonPopup();
    }

    closeReasonPopup() {
        this.statusChangeAction = '';
        this.showReasonCodePopup = false;
    }

    promptUserBeforeSave(status?, option?) {
        this.validationApplied = true;
        let closePopup = false;
        if (this.claimItemsTab?.validate()) {
            let promptMessage = '';
            let approveOrRejectClaimLines;
            let claims = []
            let isAnyOpenedOrFlagged: boolean = false;
            let isAllRejected: boolean = false;
            if (status) {
                closePopup = option && !['Save', 'Save & Set to In Progress'].includes(option) ;
                if(status === ClaimStatuses.completed) {
                    if (this.claimPopupForm?.value?.iscClaimDetails.length > 0) {
                        claims = this.claimPopupForm.value.iscClaimDetails;
                        isAnyOpenedOrFlagged = claims.some(claim => claim.status === ClaimStatuses.open || claim.status === ClaimStatuses.flagged);
                        isAllRejected = claims.every(claim => claim.status === ClaimStatuses.rejected);
            
                        if (isAllRejected) {
                            this.commonService.showNotification('error', 'At least one claim line must be approved to complete the claim');
                            return;
                        }
            
                        promptMessage = isAnyOpenedOrFlagged 
                        ? CLAIM_COMPLETED_OPENED_FlAGGED_CASE_PROMPT
                        : CLAIM_COMPLETED_PROMPT;
                    }
                    approveOrRejectClaimLines = this.approveAllFlaggedClaimLines.bind(this);
                } else if (status === ClaimStatuses.rejected && status !== this.claimPopupForm.value.status && !this.showRejectionReason) {
                    promptMessage = CLAIM_REJECTED_PROMPT;
                    approveOrRejectClaimLines = this.rejectAllClaimLines.bind(this);
                } else if (status === ClaimStatuses.paused && status !== this.claimPopupForm.value.status) {
                    promptMessage =  'Do you want to pause this claim?';
                } else {
                    promptMessage =  'Do you want to update this claim?';
                }
            } else {
                closePopup = true;
                promptMessage =  'Do you want to update this claim?';
            }
            
            this.commonService.showConfirmation(promptMessage).then(res => {
                if (res.isConfirmed) {
                    if ([ClaimStatuses.rejected ].includes(status) && typeof approveOrRejectClaimLines === 'function') {
                        approveOrRejectClaimLines();
                    }
                    if(status) {
                        if ([ClaimStatuses.paused, ClaimStatuses.rejected].includes(status)) {
                            this.statusChangeAction = status === ClaimStatuses.paused ? ClaimStatuses.paused : ClaimStatuses.rejected;
                            if (status !== this.claimPopupForm.value.status) {
                                if(status = ClaimStatuses.rejected && this.claimPopupForm.value.rejectionReason) {
                                    this.save(status, false);
                                } else {
                                    this.showReasonCodePopup = true;
                                }
                            }
                            else{
                                this.save(status, false);
                            }
                        } else if(status == ClaimStatuses.completed) {
                            if (isAnyOpenedOrFlagged) {
                                claims.forEach(rule => (rule.status === ClaimStatuses.flagged || rule.status === ClaimStatuses.open) ? rule.status = ClaimStatuses.approved : null);
                                typeof approveOrRejectClaimLines === 'function' && approveOrRejectClaimLines();
                            }
                            this.claimPopupForm.get('status').setValue(status);
                            this.save(status, false);
                        } 
                        else {
                            if(this.showRejectionReason) {
                                if(!this.claimPopupForm.get('rejectionReason').invalid) {
                                    this.claimPopupForm.get('status').setValue(status);
                                }
                                this.save(status, closePopup);
                            } else {
                                this.claimPopupForm.get('status').setValue(status);
                                this.save(status, closePopup);
                            }
                        }
                    } else {
                        this.save('', closePopup);
                    }
                }
            });
        } else {
            this.commonService.showNotification('error', 'Please provide all the required information to proceed');
        }
    }

    save(status?, closePopup?) {
        // status && this.promptUserBeforeSave(status);
        if(this.showRejectionReason && this.claimPopupForm.get('rejectionReason').invalid)
        {
            Swal.fire({
                icon: 'warning',
                title: 'Invalid Data',
                text: 'Please enter rejection reason',
                confirmButtonColor: '#479e47',
                showDenyButton: false,
                reverseButtons: true,
                denyButtonColor: '#d33',
                confirmButtonText: 'OK',
                width: '400px'
              }).then((result) => {
              });
              this.claimPopupForm.get('rejectionReason').markAsTouched()
              return
        }
        this.showLoader = true;
        if (status === ClaimStatuses.inProgress && !this.claimPopupForm.value.reviewer) {
            this.claimPopupForm.value.reviewer = this.USER.employeeId;
        }
        
        this.claimsService.upsertClaim(this.claimPopupForm.value).subscribe((res: any) => {
            if (res.isSuccessful) {
                this.claimId = res.result.claimId;
                this.commonService.showNotification('success', 'Claim saved successfully!');
                this.triggerSaveAction = ''; // This was being used to show confirmation, when approved/rejected from dashboard.
                if(closePopup) {
                    this.onClose.emit()
                } else {
                    // this.dataFetchedSuccessfully = false;
                    this.getClaimsData();
                }
            } else {
                this.commonService.showNotification('error', res.message);
            }
            this.validationApplied = false;
            this.showLoader = false;
            }, (error: HttpErrorResponse) => {
                this.showLoader = false
                this.commonService.showNotification('error', 'There was a problem in creating/updating this claim, please check data and try again!');
            });
    }

    close(): void {
        this.onClose.emit();
    }
}

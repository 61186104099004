import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/app.model';
import { CommonService } from './common.service';
import { TokenRefreshService } from './token-refresh.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    isLogged: boolean = false;
    redirectUrl: string;
    constructor(
        private auth: AuthService,
        private router: Router,
        private commonService: CommonService,
        private tokenRefreshService: TokenRefreshService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        this.commonService.setIsCallingApi(true);
        return new Promise(resp => {
            this.auth.validateToken()
                .subscribe((res: ApiResponse) => {
                    this.commonService.setIsCallingApi(false);
                    if (res.isSuccessful) {
                        //do nothing
                    } else {
                        localStorage.clear();
                    }
                    return resp(this.tokenHandling(route, state));
                }, (error: HttpErrorResponse) => {
                    this.commonService.setIsCallingApi(false);
                    if (error.status === 401 || error.status === 403) {
                        localStorage.clear();
                        return resp(this.tokenHandling(route, state));
                    }
                });
        });
    }

    tokenHandling(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let urlsplit = state.url.split(/[/#/?/&]/);
        if ( urlsplit.length && urlsplit.filter(x => x.includes('id_token=')).length ) {
            let innerSplit = urlsplit.filter(x => x.includes('access_token='))[0].split('=');
            const token = innerSplit[1];
            this.auth.getExternalToken(token)
            .subscribe((response: any) => {
                localStorage.setItem('webauth_token', response.accessToken);
                this.auth.generateApplicationToken().subscribe((res: ApiResponse) => {
                    if(res.isSuccessful){
                        localStorage.removeItem('webauth_token');
                        localStorage.setItem('access_token', res.result.accessToken);
                        localStorage.setItem('refresh_token', res.result.refreshToken);
                        localStorage.setItem('token_expiry', ''+ new Date(res.result.expiration).getTime());
                    this.tokenRefreshService.calculateIntervalDuration();
                        const token = res.result.accessToken;
                        this.isLogged = token ? true : false;
                        this.auth.isLogged = this.isLogged;
                        this.commonService.setIsCallingApi(false);
                        return this.setStateUrlAndRedirect(state);
                    } else {
                        localStorage.clear();
                        this.router.navigate(['/auth']);
                    }

                },(error: HttpErrorResponse) => {
                    this.commonService.setIsCallingApi(false);
                    
                    let queryParams = { errorExist: 'true', errorMessage: error.message };
                    let navigationExtras: NavigationExtras = { skipLocationChange: true, queryParams: queryParams };
                    this.router.navigate(['/auth'], navigationExtras);

                });
            },(error: HttpErrorResponse) => {
                this.commonService.setIsCallingApi(false);
                
                let queryParams = { errorExist: 'true', errorMessage: error.error.message };
                let navigationExtras: NavigationExtras = { skipLocationChange: true, queryParams: queryParams };
                this.router.navigate(['/auth'], navigationExtras);

            });
        } else if ( urlsplit.length && urlsplit.filter(x => x.includes('access_token=')).length ) {
            let innerSplit = urlsplit.filter(x => x.includes('access_token='))[0].split('=');
            localStorage.setItem('webauth_token', innerSplit[1]);
            
            this.auth.generateApplicationToken().subscribe((res: ApiResponse) => {
                if(res.isSuccessful){
                    localStorage.removeItem('webauth_token');
                    localStorage.setItem('access_token', res.result.accessToken);
                    localStorage.setItem('refresh_token', res.result.refreshToken);
                    localStorage.setItem('token_expiry', ''+ new Date(res.result.expiration).getTime());
                    this.tokenRefreshService.calculateIntervalDuration();
                    const token = res.result.accessToken;
                    this.isLogged = token ? true : false;
                    // this.isLogged = true;
                    this.auth.isLogged = this.isLogged;
                    this.commonService.setIsCallingApi(false);
                    return this.setStateUrlAndRedirect(state);
                } else {
                    localStorage.clear();
                    this.router.navigate(['/auth']);
                }
            },(error: HttpErrorResponse) => {
                this.commonService.setIsCallingApi(false);
                
                let queryParams = { errorExist: 'true', errorMessage: error.message };
                let navigationExtras: NavigationExtras = { skipLocationChange: true, queryParams: queryParams };
                this.router.navigate(['/auth'], navigationExtras);

            });
        } else {
            const token = localStorage.getItem('access_token');
            const timeOut = localStorage.getItem('token_expiry');
            this.tokenRefreshService.calculateIntervalDuration();
            this.isLogged = token ? true : false;
            // this.isLogged = true;
            this.auth.isLogged = this.isLogged;

            for (var i = 0; i < urlsplit.length; i++) {
                let innerSplit = urlsplit[i].split('=');
                if (innerSplit.length > 1)
                    localStorage.setItem(innerSplit[0], innerSplit[1]);
            }
            return this.setStateUrlAndRedirect(state);
        }

    }

    setStateUrlAndRedirect(state) {
        // state.url = state.url.split('?')[0];
        let urlsplit = state.url.split(/[/#/?/&]/);
        if(urlsplit.length && urlsplit.filter(x => x.includes('id_token=')).length ){
            state.url = state.url.split('#')[0];
        } else {
            state.url = state.url.split('?')[0];
        }
        const redirectUrl = this.redirectUrl || '/home';
        if (
            state.url === '/' ||
            state.url === '/auth' ||
            state.url === '/auth/register'
        ) {
            if (this.isLogged) {
                this.router.navigate([redirectUrl]);
                return false;
            } else { // if user is logged out, allow to proceed to login
                return true;
            }
        } else {
            if (this.isLogged) {
                return true;
            } else {
                this.auth.redirectUrl = state.url;
                this.router.navigate(['/auth'], {queryParams:{'redirectURL':state.url}});
                return false;
            }
        }
    }

    splitByString(source, splitBy) {
        var splitter = splitBy.split('');
        splitter.push([source]); //Push initial value

        return splitter.reduceRight(function (accumulator, curValue) {
            var k = [];
            accumulator.forEach(v => k = [...k, ...v.split(curValue)]);
            return k;
        });
    }
}

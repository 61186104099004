import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { ComboBox, DropDownList } from '@syncfusion/ej2-angular-dropdowns';
import { EditService, GridComponent, ExcelExportProperties, rowCell, recordClick } from '@syncfusion/ej2-angular-grids';
import { NumericTextBox, TextBox } from '@syncfusion/ej2-angular-inputs';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { CommonService } from 'src/app/shared/services/common.service';
import { Query } from '@syncfusion/ej2-data';
import { partsOrderStatuses } from 'src/app/modules/home/models/orders.model';
import { getExcelDataSource, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiResponse, OrderTypes, UserRoles, gridNames, PersistenceData, GridPersistRequests, PartsAvailabilityStatus } from 'src/app/shared/models/app.model';
import { EditSettingsModel } from '@syncfusion/ej2-angular-grids';
import { MouseEventArgs } from '@syncfusion/ej2-base';

@Component({
  selector: 'app-items-tab',
  templateUrl: './items-tab.component.html',
  styleUrls: ['./items-tab.component.scss'],
  providers: [EditService]
})
export class ItemsTabComponent implements OnInit, OnChanges, OnDestroy {

  USER;
  preference;
  orderType = OrderTypes.shipmentOrder;
  statuses = partsOrderStatuses;
  showItemsGrid: boolean = false;

  showColumnChooser: boolean;
  gridName = gridNames.partsOrderItemsGrid;
  validationApplied: boolean = false;
  skipBeginEditEvent: boolean = false;
  columns: Object[];
  itemsField;
  itemsObject;

  itemGroup: any;
  itemGroupField;
  itemGroupObject: any;

  itemDescriptionField;
  itemDescriptionObject: any;

  itemQuantityField;
  itemQuantityObject: any;

  itemQuantityReceivedField: any;
  itemQuantityReceivedObject: any;

  itemReasonsField;
  itemReasonsObject: any;

  itemsQuery: any;
  selectedRowId: string;
  showItemAdvancedSearchPopup: boolean = false;

  isReadOnly: boolean = false;

  onItemUpdate = new Subject<string>();

  gridHeight: any;
  @Input() status;
  @Input() isManager: boolean;
  @Input() items = [];
  @Input() technicianId: string;
  itemsList: any;
  isCSA: boolean = false;
  showLoader: boolean = false;
  @Input() inventoryItems: any;
  @Input() reasonsDDL: any;

  @Output() onQuantityReceivedUpdate = new EventEmitter();
  @Output() onQuantityReceivedReset = new EventEmitter();
  @Output() onShipmentIdClick: EventEmitter<any> = new EventEmitter();
  @ViewChild('partsOrderItemsGrid') partsOrderItemsGrid: GridComponent;
  @ViewChild('itemsGridColumnChooser') columnChooser;

  public editSettings: EditSettingsModel;

  constructor(
    private commonService: CommonService,
    private eRef: ElementRef
  ) {
    this.USER = this.commonService.USER;
  }


  @HostListener('document:click', ['$event'])
  onClick(event) {
    if (this.partsOrderItemsGrid?.isEdit) {
      if (!this.eRef.nativeElement.contains(event.target)
        && !document.getElementById(gridNames.partsOrderItemsGrid + 'itemCode_popup')?.contains(event.target)
        && !document.getElementsByClassName('swal2-container')[0]?.contains(event.target)
      ) {
        this.partsOrderItemsGrid.endEdit();
      }
    }
  }

  ngOnInit(): void {
    this.gridHeight = document.getElementById('parts-order-popup-body').clientHeight - 330;
    this.isCSA = this.USER.role === UserRoles.csa;
    this.isReadOnly = this.USER.role === UserRoles.readOnly
    this.columns = this.getGridColumns();
    this.commonService.getUserPreference(this.gridName, this.USER.employeeId).subscribe((res: ApiResponse) => {
      if (res && res.result && res.result.length && res.result[0].value) {
        this.preference = res.result[0].value;
        localStorage.setItem(`grid${this.gridName}`, this.preference);
      } else {
        localStorage.removeItem(`grid${this.gridName}`);
      }
      this.showItemsGrid = true;
      this.showLoader = false;
    }, error => {
      localStorage.removeItem(`grid${this.gridName}`);
      this.showLoader = false;
    });
    this.showLoader = false;
    this.setItemsData();
    this.getItemList();
    this.onItemUpdate
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(value => {
        if (this.itemsObject) {
          this.itemsObject.showPopup();
          this.itemsObject.showSpinner();
        }
        this.getItemList(value);
      });
  }


  ngOnChanges() {
    this.partsOrderItemsGrid && this.partsOrderItemsGrid.refresh();
  }

  setItemsData() {
    this.items?.map((item, index) => {
      return this.setItemsRow(item, index);
    });
    if (this.status === partsOrderStatuses.open) {
      this.addEmptyRowsToItemsData();
    }
  }

  addEmptyRowsToItemsData() {
    for (let i = 0; i < 2; i++) {
      this.items.push(this.setItemsRow({}, this.items.length));
    }
  }

  setItemsRow(item, index) {
    item.id = item.id || 0,
      item.shipmentOrderId = item.shipmentOrderId || null,
      item.shipmentOrderLN = item.shipmentOrderLN || null,
      item.lineNumber = index + 1;
    item.itemCode = item.itemCode || item.item || '';
    item.itemDescription = item.itemDescription || item.description || '';
    item.itemGroup = item.itemGroup || '';
    item.serviceItemGroup = item.serviceItemGroup || '';
    item.quantity = item.quantity === 0 && item.sequenceLN != 1 ? null : item.quantity || 1;
    item.quantityReceived = item.quantityReceived || null;
    // item.plannedReceiptDate = item.plannedReceiptDate || null;
    item.status = item.status || '';
    item.trackingNumber = item.trackingNumber || null;
    // item.estimatedDeliveryDate = item.estimatedDeliveryDate || null;
    item.createdBy = item.createdBy || this.USER.userId;
    item.createdByName = item.createdByName || this.USER.userName;
    item.lastUpdatedBy = item.lastUpdatedBy || this.USER.userId;
    item.lastUpdatedByName = item.lastUpdatedByName || this.USER.userName;
    item.isDeleted = item.isDeleted || false;
    return item;
  }

  getItemList(value: string = null) {
    // this.showLoader = true;
    this.commonService.getItems(value, 30, this.USER.company)
      .subscribe((res: ApiResponse) => {
        if (res && res.isSuccessful) {
          const existingItems = [];
          (this.partsOrderItemsGrid?.dataSource as any)?.forEach(item => {
            if (item.itemCode !== this.itemsObject?.value && !item.isDeleted) {
              existingItems.push(item.itemCode);
            }
          });
          this.itemsList = res.result.filter(item => {
            item.value = item.item;
            item.text = `${item.item} | ${item.itemDescription}`;
            if (existingItems.length) {
              return !existingItems.includes(item.value)
            } else {
              return true;
            }
          });
          if (this.itemsObject) {
            this.itemsObject.dataSource = this.itemsList;
            this.itemsObject.hideSpinner();
          }
          this.showLoader = false;
        } else {
          this.itemsList = [];
          if (this.itemsObject) {
            this.itemsObject.hideSpinner();
            this.itemsObject.dataSource = [];
          }
          this.showLoader = false;
        }
      });
  }

  load(args) {
    this.partsOrderItemsGrid.element.addEventListener('mouseup', (e: MouseEventArgs) => {
      if ((e.target as HTMLElement).classList.contains("e-rowcell")) {
        const cellIndex = +(e.target as HTMLElement).getAttribute('data-colindex');
        this.partsOrderItemsGrid.isEdit && this.partsOrderItemsGrid.endEdit();
        let rowIndex: number = parseInt((e.target as HTMLElement).getAttribute("Index"));
        this.partsOrderItemsGrid.selectRow(rowIndex);
        this.partsOrderItemsGrid.startEdit();
        const clickedColumnName = this.partsOrderItemsGrid.getColumnByIndex(cellIndex).field;
        clickedColumnName && document?.getElementById(gridNames.partsOrderItemsGrid + clickedColumnName)?.focus();
      };
    });
  }

  onGridCreated() {
    this.itemsQuery = new Query().where('isDeleted', 'equal', false);
    this.partsOrderItemsGrid.dataSource = this.items;
    this.partsOrderItemsGrid.toolbar = [
      { text: '', id: 'column-chooser', align: 'Left', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
      'Search',
      { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = this.partsOrderItemsGrid.toolbar.findIndex(item => item === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
      this.partsOrderItemsGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'grid_excelexport', align: 'Right', prefixIcon: 'e-excelexport', tooltipText: 'Excel Export' });
    }
    if (this.commonService.roleClaims['PO_Popup_ItemTabGrid_Toolbar_Add_Item']?.visible) {
      this.partsOrderItemsGrid.toolbar.push({ text: '', id: 'add-item', align: 'Left', tooltipText: 'Add Item', prefixIcon: 'e-add' })
    }
    updateFilterIcon({ requestType: 'filtering' }, this.partsOrderItemsGrid);
  }

  actionBegin(args) {
    if (args && args.requestType === 'beginEdit') {
      if (args.rowData?.itemCode) {
        if (this.skipBeginEditEvent) {
          this.skipBeginEditEvent = false;
        } else {
          this.getItemList(args.rowData?.itemCode);
        }
      }
      else {
        this.getItemList();
      }
    }
    if (args.requestType === 'sorting' || args.requestType === 'filterbeforeopen' || args.requestType === 'filterchoicerequest') {
      if (this.partsOrderItemsGrid.isEdit) {
        args.cancel = true;
      }
    }
    updateFilterIcon({ requestType: 'filtering' }, this.partsOrderItemsGrid);
  }

  actionComplete(args, grid) {
    if (args.requestType === 'save') {
      this.toggleButtonDisable();
      const rowData = (this.partsOrderItemsGrid.dataSource as any).find(item => item.lineNumber === args.rowData.lineNumber);
      rowData.itemGroup = this.itemGroup;
      // if(rowData.itemCode != ""){
      const emptyRecordsList = (this.partsOrderItemsGrid.dataSource as any).filter((item, index) => {
        if (!item.itemCode) {
          item.rowIndex = index;
          return true;
        }
      });
      if (emptyRecordsList?.length < 2) {
        this.addItem();
        setTimeout(() => {
          this.partsOrderItemsGrid.selectRow(emptyRecordsList[0].rowIndex);
          this.partsOrderItemsGrid.startEdit();
        }, 100);
      }
      // }
    }

    // gridActionsForFiltering(args, grid);
    updateFilterIcon(args, grid);
    if (GridPersistRequests.includes(args.requestType)) {
      this.savePersistance(grid);
    }
    updateFilterIcon({ requestType: 'filtering' }, this.partsOrderItemsGrid);
  }

  addItem() {
    let newIndex = (this.partsOrderItemsGrid.dataSource as any).filter(item => !item.isDeleted).length;
    let newRow = this.setItemsRow({}, newIndex);
    (this.partsOrderItemsGrid.dataSource as any).push(newRow);
    this.partsOrderItemsGrid.refresh();
  }

  savePersistance(grid) {
    const persistenceData: PersistenceData = {
      userId: this.USER.userId,
      name: gridNames.partsOrderItemsGrid,
      value: grid.getPersistData()
    }
    this.commonService.saveUserPreference(persistenceData);
    localStorage.setItem(`grid${gridNames.partsOrderItemsGrid}`, grid.getPersistData());
  }

  dataBound(args) {
    this.partsOrderItemsGrid?.toolbarModule?.enableItems(['add-item'], this.status === partsOrderStatuses.open);
  }

  rowDataBound(args) {
    if (args.data && args.data.quantityReceived) {
      args.data.quantityReceived < args.data.quantity ? args.row.classList.add('bg-custom-danger') :
        args.data.quantityReceived === args.data.quantity ? args.row.classList.add('bg-custom-success') : args.row.classList.add('bg-custom-warning');
    }
  }

  getData() {
    return {
      itemList: (this.partsOrderItemsGrid?.dataSource as any)?.filter(x => x.itemCode)
    }
  }

  commandColumnClick(args) {
    if (args.commandColumn.title === 'Delete Item') {
      this.partsOrderItemsGrid.endEdit();
      if (args.rowData.id || args.rowData.itemCode) {
        this.commonService.showConfirmation('Are you sure? You want to delete this item?').then(result => {
          if (result.isConfirmed) {
            const row = (this.partsOrderItemsGrid?.dataSource as any).find(x => x.lineNumber == args.rowData.lineNumber);
            row.lineNumber = args.rowData.lineNumber + '';
            row.isDeleted = true;
            this.resetLineNumbers();
            this.toggleButtonDisable();
          }
        })
      } else {
        const index = (this.partsOrderItemsGrid?.dataSource as any).findIndex(x => x.lineNumber === args.rowData.lineNumber);
        (this.partsOrderItemsGrid.dataSource as any).splice(index, 1);
        this.resetLineNumbers();
        this.toggleButtonDisable();
      }
    }
  }

  toggleButtonDisable() {
    const elem: any = document.getElementById('parts-order-btn');
    if (!(this.partsOrderItemsGrid.dataSource as any).filter(item => !item.isDeleted && item.itemCode).length) {
      // if (!this.items.filter(item => !item.isDeleted).length) {
      elem.disabled = true;
    } else {
      elem.disabled = this.status !== partsOrderStatuses.open;
    }
  }

  resetLineNumbers() {
    (this.partsOrderItemsGrid?.dataSource as any).map((item, index) => {
      item.lineNumber = index + 1;
      return item;
    });
    if ((this.partsOrderItemsGrid?.dataSource as any).filter(item => !item.itemCode).length < 2) {
      if ((this.partsOrderItemsGrid?.dataSource as any).filter(item => !item.itemCode).length === 0) {
        this.items.push(this.setItemsRow({}, this.items.length));
      }
      this.items.push(this.setItemsRow({}, this.items.length));
    }
    this.partsOrderItemsGrid.refresh();
  }

  getGridColumns() {
    const disabled = this.status === partsOrderStatuses.open && !this.commonService.roleClaims['PO_Popup_ItemTabGrid_Action_Delete_Item']?.disable ? 'e-flat' : 'e-flat disabled-grid-button';
    let columns: any = [
      { field: 'lineNumber', headerText: 'No.', type: 'number', format: 'n', textAlign: 'Left', width: 100, isPrimaryKey: true, visible: false, showInColumnChooser: false },
      { field: 'itemCode', headerText: 'Item', type: 'string', textAlign: 'Left', filter: { operator: 'contains', type: 'CheckBox' }, showInColumnChooser: false, width: 250, edit: this.initItemsDDL(), editType: 'dropdownedit', validationRules: { required: false } },
      { field: 'itemDescription', headerText: 'Description', type: 'string', textAlign: 'Left', edit: this.initItemDescription(), showInColumnChooser: false },
      { field: 'serviceItemGroup', headerText: 'Service Item Group', type: 'string', textAlign: 'Left', filter: { type: 'Excel' }, width: 150, edit: this.initItemGroup() },
      { field: 'quantity', headerText: 'Ord Qty', type: 'number', format: 'n', step: 1, decimals: 0, validateDecimalOnType: true, textAlign: 'right', width: 142, showInColumnChooser: false, edit: this.initItemQuantity(), editType: 'numericedit', validationRules: { required: false } },
      { field: 'warehouseOrderLN', headerText: 'Order No.', type: 'string', textAlign: 'Left', width: 138, allowEditing: false, visible: false, },
      { field: 'lineNumberLN', headerText: 'Line No. (LN)', type: 'number', format: 'n', textAlign: 'Left', width: 158, allowEditing: false, visible: false },
      { field: 'sequenceLN', headerText: 'Sequence (LN)', type: 'number', format: 'n', textAlign: 'Left', width: 168, allowEditing: false, visible: false },
      { field: 'quantityOrderedLN', headerText: 'Ordered Qty (LN)', type: 'number', format: 'n', textAlign: 'Left', width: 179, allowEditing: false, visible: false },
      { field: 'status', headerText: 'Status', type: 'string', textAlign: 'Left', width: 120, allowEditing: false, visible: false, filter: { type: 'Excel' } },
      { field: 'estimatedDeliveryDate', headerText: 'Estimated Delivery Date', type: 'date', format: 'd MMM, y', textAlign: 'Left', width: 220, allowEditing: false, visible: false },
      { field: 'plannedReceiptDate', headerText: 'Planned Receipt Date', type: 'date', format: 'd MMM, y', textAlign: 'Left', width: 206, allowEditing: false, visible: false },
      { field: 'blockedLN', headerText: 'Blocked (LN)', type: 'string', textAlign: 'Left', width: 157, allowEditing: false, visible: false, filter: { type: 'Excel' } },
      { field: 'isDirectDelivery', headerText: 'Direct Delivery', type: 'string', textAlign: 'Left', width: 165, allowEditing: false, filter: { type: 'Excel' }, visible: false },
      { field: 'shipmentOrderLN', headerText: 'Shipment ID', type: 'string', textAlign: 'Left', width: 100, visible: false },
      { field: 'quantityShippedInInventoryUnit', headerText: 'Ship Qty', type: 'number', format: 'n', textAlign: 'right', width: 157, visible: false },
      { field: 'quantityReceived', headerText: 'Rcvd Qty', type: 'number', format: 'n', step: 1, decimals: 0, validateDecimalOnType: true, textAlign: 'right', width: 142, editType: 'numericedit', visible: false },
      { field: 'trackingNumber', headerText: 'Tracking Number', type: 'string', textAlign: 'Left', width: 182, allowEditing: false, visible: false },
      { field: 'lastUpdatedDate', headerText: 'Updated Date', type: 'date', format: 'd MMM, y', textAlign: 'Left', width: 191, allowEditing: false, visible: false },
      { field: 'lastUpdatedBy', headerText: 'Updated By', type: 'string', textAlign: 'Left', width: 149, allowEditing: false, visible: false, filter: { type: 'Excel' } },
      //{ field: 'lineNumberLN', headerText: 'Line No. (LN)', type: 'number', textAlign: 'Left', width: 250, allowEditing: false },
      //{ field: 'plannedReceiptDateLN', headerText: 'Planned Receipt Date', type: 'date', textAlign: 'Left', width: 250, allowEditing: false },
      {
        field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  textAlign: 'Center', allowFiltering: false, allowSorting: false, clipMode: 'Clip', width: 130,
        commands: [
          { title: 'Delete Item', buttonOption: { iconCss: 'fas fa-trash', cssClass: `${disabled}` } }
          //(type: 'Edit') -- removed because edit delete button is needed even in edit mode
        ]
      }
    ];
    return columns;
  }

  toolbarClick(args: ClickEventArgs, grid: GridComponent) {
    if (this.partsOrderItemsGrid.isEdit) {
      this.commonService.showNotification('warning', 'Item is in edit mode!');
    }
    else {
      if (args.item.id === 'grid_excelexport') {
        const dataSource = getExcelDataSource(grid);
        let excelExportProperties: ExcelExportProperties = {
          dataSource,
          hierarchyExportMode: 'Expanded',
          theme: {
            header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
          },
          fileName: `Parts Order Items (${getCurrentDate()}).xlsx`
        };
        grid.excelExport(excelExportProperties);
      } else if (args.item.id === 'grid_pdfexport') {
        grid.pdfExport();
      } else if (args.item.id === 'clear-filter') {
        grid.clearFiltering();
        grid.search('');
      } else if (args.item.id === 'reset-persistence') {
        this.showLoader = true;
        this.commonService.showConfirmation("Are you sure? This will reset the grid filters and persistence.", "Ok", "Cancel").then(result => {
          if (result.isConfirmed) {
            this.commonService.resetUserPreference({ userId: this.USER.userId, name: gridNames.partsOrderItemsGrid, value: '' }).subscribe((response) => {
              grid.search('');
              grid.clearFiltering();
              grid.clearSorting();
              this.showLoader = false;
            });
          }
          else {
            this.showLoader = false;
            return
          }
        });
      } else if (args.item.id === 'add-item') {
        this.showLoader = true;
        if (this.items[this.items.length - 1]?.itemCode == '' || this.items[this.items.length - 2]?.itemCode == '') {
          this.commonService.showNotification('warning', 'There are already empty row(s) present');
        }
        else {
          this.partsOrderItemsGrid.endEdit();
          if (!this.partsOrderItemsGrid.isEdit) {
            grid.clearFiltering();
            grid.search('');
            grid.clearSorting();
            this.addItem();
          } else {
            this.commonService.showNotification('warning', 'Item is in edit mode!');
          }
        }
        this.showLoader = false;
      } else if (args.item.id === 'column-chooser') {
        this.showColumnChooser = !this.showColumnChooser;
      }
    }

  }

  validate() {
    this.validationApplied = true;
    this.partsOrderItemsGrid.endEdit();
    if ((this.partsOrderItemsGrid?.dataSource as any)?.filter(x => !x.itemCode && x.itemDescription).length) {
      this.commonService.showNotification('error', 'Items grid validation failed. Item value is missing in a row no. ' + (this.partsOrderItemsGrid?.dataSource as any)?.filter(x => !x.itemCode && x.itemDescription)[0].lineNumber);
      return false;
    } else if ((this.partsOrderItemsGrid?.dataSource as any)?.filter(x => x.itemCode && !x.quantity).length) {
      this.commonService.showNotification('error', `There is an item with no or zero quantity ${(this.partsOrderItemsGrid?.dataSource as any)?.filter(x => x.itemCode && !x.quantity)[0].lineNumber}`);
      return false;
    } else {
      if (Boolean(this.partsOrderItemsGrid.dataSource) && !this.partsOrderItemsGrid.isEdit) {
        return true;
      } else {
        return false;
      }
    }
  }

  removeItems() {
    this.partsOrderItemsGrid.dataSource = [];
    this.partsOrderItemsGrid.refresh();
  }

  showItemPopup() {
    this.showItemAdvancedSearchPopup = true;
  }

  onItemAdvancedSearchPopupClose(data: any) {
    this.showItemAdvancedSearchPopup = false;
    const rowIndex = this.partsOrderItemsGrid.getRowIndexByPrimaryKey(this.selectedRowId);
    this.partsOrderItemsGrid.selectRow(rowIndex);
    setTimeout(() => {
      this.partsOrderItemsGrid.startEdit();
      this.skipBeginEditEvent = true;
      setTimeout(() => {
        this.itemsObject.value = data.item;
        this.itemsObject.dataSource = [{
          text: `${data.item} | ${data.itemDescription}`,
          value: data.item,
          ...data
        }];
      }, 400);
    }, 300);
  }

  addAdvancedSearchIcon() {
    const itemAnchor = document.createElement('a');
    itemAnchor.classList.add('item-advance-search-icon');
    itemAnchor.setAttribute('href', 'javascript:');
    itemAnchor.setAttribute('title', 'Advanced Search');
    itemAnchor.addEventListener('click', this.showItemPopup.bind(this));
    const icon = document.createElement('i');
    icon.classList.add('fas');
    icon.classList.add('fa-info');
    itemAnchor.appendChild(icon);
    this.itemsField.parentElement.parentElement.append(itemAnchor);
  }

  initItemsDDL() {
    return {
      create: args => {
        this.selectedRowId = args.data.rowId;
        this.itemsField = document.createElement('input');
        return this.itemsField;
      },
      read: () => {
        if (this.itemsObject) {
          return this.itemsObject.value;
        }
      },
      destroy: () => {
        this.itemsObject.destroy();
      },
      write: (args) => {
        this.itemsObject = new DropDownList({
          dataSource: this.itemsList,
          fields: { value: 'value', text: 'text' },
          value: args.rowData.itemCode,
          allowFiltering: true,
          showClearButton: true,
          cssClass: 'generic-items-dropdown-list',
          filterType: 'Contains',
          enabled: this.status === partsOrderStatuses.open,
          filtering: args => { this.onItemUpdate.next(args.text); },
          focus: args => { this.itemsObject.showPopup() },
          blur: args => {
            if (this.itemsObject['isSelectCustom']) {
              this.itemsObject.value = null;
            }
          },
          change: (event: any) => {
            if (event.value && event.item) {
              if (event.itemData.status === PartsAvailabilityStatus.inActive) {
                if (event?.itemData?.alternateItem !== null) {
                  this.commonService.showConfirmation('\nItem ' + event?.itemData?.item + '-' + event?.itemData?.itemDescription + ' is obsolete.' + '\n\n Would you like to add the alternate item ' + event?.itemData?.alternateItem)
                    .then(result => {
                      if (result.isConfirmed) {
                        // mapping data according to alternate item
                        if ((this.partsOrderItemsGrid?.dataSource as any).find(x => x.itemCode === event?.itemData?.alternateItem && !x.isDeleted)) {
                          this.commonService.showNotification('warning', "Item already added in the items list.");
                          args.cancel = true;
                          this.itemsObject.value = null;
                          return;
                        } else {
                          this.itemsObject.value = null;
                          this.commonService.getAlternateItemData(event?.itemData?.alternateItem)
                            .subscribe((res: ApiResponse) => {
                              this.itemsObject.dataSource = [{
                                text: `${res.result.item} | ${res.result.itemDescription}`,
                                value: res.result.item,
                                ...res.result
                              }];
                              this.itemsObject.value = res.result.item;
                              this.itemGroup = res.result.itemGroup;
                              this.itemGroupObject.value = res.result.serviceItemGroup;
                              this.itemDescriptionObject.value =  res.result.itemDescription || res.result.description;
                            })
                        }
                      } else {
                        args.cancel = true;
                        this.itemsObject.value = null;
                        return;
                      }
                    })
                } else {
                  this.commonService.showNotification('error', '\nItem ' + event?.itemData?.item + '-' + event?.itemData?.itemDescription + ' is obsolete');
                  args.cancel = true;
                  this.itemsObject.value = null;
                  return;
                }
              }
              else {
                this.itemGroup = event.itemData.itemGroup;
                this.itemGroupObject.value = event.itemData.serviceItemGroup;
                this.itemDescriptionObject.value = event.itemData.description || event.itemData.itemDescription;
                if (event.isInteracted) {
                  // this.itemQuantityObject.value = 1;
                  this.itemQuantityObject.max = this.inventoryItems?.find(x => x.itemCode === event.value)?.onHand;
                }
              }
            }
          }
        });
        this.itemsObject.appendTo(this.itemsField);
        this.addAdvancedSearchIcon();
      }
    }
  }

  initItemGroup() {
    return {
      create: () => {
        this.itemGroupField = document.createElement('input');
        return this.itemGroupField;
      },
      read: () => {
        if (this.itemGroupObject) {
          return this.itemGroupObject.value;
        }
      },
      destroy: () => {
        this.itemGroupObject.destroy();
      },
      write: (args) => {
        this.itemGroupObject = new TextBox({
          value: args.rowData.serviceItemGroup,
          enabled: false
        });
        this.itemGroupObject.appendTo(this.itemGroupField);
      }
    }
  }

  initItemDescription() {
    return {
      create: () => {
        this.itemDescriptionField = document.createElement('input');
        return this.itemDescriptionField;
      },
      read: () => {
        if (this.itemDescriptionObject) {
          return this.itemDescriptionObject.value;
        }
      },
      destroy: () => {
        this.itemDescriptionObject.destroy();
      },
      write: (args) => {
        this.itemDescriptionObject = new TextBox({
          value: args.rowData.itemDescription,
          enabled: false
        });
        this.itemDescriptionObject.appendTo(this.itemDescriptionField);
      }
    }
  }

  initItemQuantity() {
    return {
      create: () => {
        this.itemQuantityField = document.createElement('input');
        return this.itemQuantityField;
      },
      read: () => {
        if (this.itemQuantityObject) {
          return this.itemQuantityObject.value;
        }
      },
      destroy: () => {
        this.itemQuantityObject.destroy();
      },
      write: (args) => {
        this.itemQuantityObject = new NumericTextBox({
          value: args.rowData.quantity || 1,
          showSpinButton: false,
          min: 1,
          step: 1,
          decimals: 0,
          validateDecimalOnType: true,
          created: args => {
            this.itemQuantityField.onkeyup = event => {
              if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                this.itemQuantityObject.value = +event.target.value;
              }
            }
          },
          change: args => {
          }
        });
        this.itemQuantityObject.appendTo(this.itemQuantityField);
      }
    }
  }

  ngOnDestroy() {
    this.status = '';
    this.partsOrderItemsGrid.destroy();
    this.partsOrderItemsGrid = null;
    this.items.length = 0;
    this.inventoryItems = [];
    this.reasonsDDL = [];
    this.onItemUpdate.unsubscribe();
  }

}

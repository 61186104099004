import { Component } from '@angular/core';
import { createSpinner, showSpinner } from '@syncfusion/ej2-angular-popups';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
    
    constructor() { }

    ngOnInit(): void {
        const loaderElem = document.getElementById('spinner-container') as any;
        createSpinner({ target: loaderElem });
        showSpinner(loaderElem);
    }
}

import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ExcelExportProperties, GridComponent } from '@syncfusion/ej2-angular-grids';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { ApiResponse, Companies, UserRoles } from 'src/app/shared/models/app.model';
import { Attachment } from 'src/app/shared/models/attachment.model';
import { AttachmentsService } from 'src/app/shared/services/attachments.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { downloadResource } from 'src/app/shared/utils/general-utils';
import { excelQueryCellInfo, getExcelDataSource, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { environment } from 'src/environments/environment';
import { ServiceOrderService } from '../../services/service-order.service';
import { returnOrderTypes } from 'src/app/modules/home/models/orders.model';


const BASE_URL = environment.apiUrl;

let USER;
@Component({
    selector: 'app-attachments-tab',
    templateUrl: './attachments-tab.component.html',
    styleUrls: ['./attachments-tab.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AttachmentsTabComponent implements OnInit, OnChanges {
    showColumnChooser: boolean = false;
    loading: boolean = false;
    isGridView: boolean = true;
    addingAttachment: boolean = true;

    @ViewChild('attachmentUpload')
    public uploadObj: UploaderComponent;

    public path: Object = {
        saveUrl: `${BASE_URL}BlobManager/UploadTempBlobAsync`,
        removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove'
    };
    public dropElement: HTMLElement = document.getElementsByClassName('uploader-section')[0] as HTMLElement;

    attachmentGrid: any;
    @Input() attachments: Attachment[] = [];
    @Input() customerData: any;
    @Input() serviceOrderId = null;
    @Input() serviceOrderIdLN = null;
    @Input() moduleId = null;
    @Input() module = null;
    @Input() isEditable: boolean = true;
    @Input() fieldRules: any;

    @ViewChild('columnChooser') columnChooser;
    @ViewChild('attachmentsGrid') grid: GridComponent;
    @Output() attachmentsUpdated = new EventEmitter();
    showEmailPopup: boolean = false;
    emailAttachment: Attachment;
    emailSubject: string = `Service Order`;
    link: string = null;
    attachmentReadOnly: boolean = false
    returnOrderTypes: { returnOrder: string; coreReturnOrder: string; damagedReturnOrder: string; };
    employeeName: any;

    constructor(
        private serviceOrderService: ServiceOrderService,
        private commonService: CommonService,
        private attachmentService: AttachmentsService
    ) {
        USER = this.commonService.USER;
        this.employeeName = this.commonService.USER.employeeName;
        this.returnOrderTypes = returnOrderTypes;
    }

    @HostListener('document:click', ['$event'])
    onClick(event) {
        if (this.grid?.isEdit) {
            if (!document.getElementById('attachmentsListEditForm')?.contains(event.target)) {
                this.grid?.endEdit();
            }
        }
    }

    ngOnInit(): void {
        this.attachments = this.attachments === undefined ? [] : this.attachments;
        this.attachmentReadOnly = this.commonService.roleClaims['SO_Popup_Field_attachments']?.readOnly
        if (this.commonService.roleClaims['Attachment_Tab_isEditable']?.edit) {
            this.isEditable = true;
        }
        if (this.isEditable && this.attachmentReadOnly) {
            this.isEditable = false;
        }
        if (!this.isEditable) { this.isGridView = true; this.addingAttachment = false; }
        this.initGrid();
        if (this.attachments && this.attachments.length) {
            this.isGridView = true;
            this.addingAttachment = false;
        }

        if([returnOrderTypes.coreReturnOrder, returnOrderTypes.damagedReturnOrder].includes(this.module)){
            if(this.module === returnOrderTypes.coreReturnOrder){
                this.emailSubject = `Attachment  - Core Return order ${this.moduleId}`;
              } else if (this.module === returnOrderTypes.damagedReturnOrder){
                this.emailSubject = `Attachment  - Damaged Return order ${this.moduleId}`;
              }
        } else {
            this.emailSubject= `Attachment of Service Order: ${ this.commonService.USER.company === Companies.kingslynn ? this.serviceOrderIdLN || this.serviceOrderId : this.serviceOrderId}`;
        }

        this.attachments?.map((attachment, index) => {
            attachment.tempId = attachment.attachmentId;
            attachment.serialNumber = index + 1;
            let lastUpdated = new Date(attachment.lastUpdated).setHours(0, 0, 0);
            attachment.lastUpdatedDate = new Date(lastUpdated);
            attachment.fileType = attachment.fileName.split('.')[attachment.fileName.split('.').length - 1];
        });
    }

    ngOnChanges(change: SimpleChanges) {
        if (change && change.attachments && change.attachments.currentValue && change.attachments.previousValue) {
            this.attachments = change.attachments.currentValue;
            this.attachments.map((attachment, index) => {
                let lastUpdated = new Date(attachment.lastUpdated).setHours(0, 0, 0);
                attachment.lastUpdatedDate = new Date(lastUpdated);
                attachment.serialNumber = index + 1;
                attachment.fileType = attachment.fileName.split('.')[attachment.fileName.split('.').length - 1];
            });
            this.isGridView = true;
            this.addingAttachment = false;
            if (this.grid) {
                this.grid.dataSource = this.attachments;
                this.grid.refresh();
            }
        }
    }

    updateToolbar() {
        this.grid.toolbar = [
            { id: 'column-chooser', template: this.columnChooser, tooltipText: 'Show/Hide Columns' }, 'Search',
            { text: '', id: 'clear-attchments-grid-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
        ];
        const columnChooserIndex = this.grid.toolbar.findIndex(item => item === 'Search');
        if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
            this.grid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
        }
    }

    initGrid() {
        this.attachmentGrid = {
            toolbar: [],
            filterSettings: { type: 'Menu' },
            columns: this.getGridColumns()
        };
    }

    getGridColumns() {
        return [
            { field: 'serialNumber', headerText: 'Sr. No', width: 120, textAlign: 'Left', allowEditing: false },
            { field: 'fileName', headerText: 'File name', textAlign: 'Left', allowEditing: false },
            { field: 'fileType', headerText: 'File Type', textAlign: 'Left', visible: false, tree: true, allowEditing: false, filter: { type: 'Excel' } },
            { field: 'fileId', headerText: 'File Id', textAlign: 'Left', visible: false, tree: true, allowEditing: false },
            { field: 'lastUpdatedDate', headerText: 'Last Updated', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y' }, width: 160, allowEditing: false },
            { field: 'fileSize', headerText: 'Size', textAlign: 'Left', allowEditing: false },
            { field: 'comment', headerText: 'Comments', textAlign: 'Left', type:"string", allowEditing: true },
            {
                field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  textAlign: 'Center', width: 140, allowFiltering: false, allowSorting: false, allowEditing: false,
                commands: [
                    {
                        type: 'Email',
                        buttonOption: {
                            iconCss: `fas fa-envelope email-button`,
                            cssClass: 'e-flat email-button'
                        }
                    },
                    {
                        type: 'Download',
                        buttonOption: {
                            iconCss: `fas fa-download download-button`,
                            cssClass: 'e-flat download-button color-green'
                        }
                    },
                    {
                        type: 'Delete',
                        buttonOption: {
                            iconCss: `fas fa-trash delete-button`,
                            cssClass: 'e-flat delete-button'
                        }
                    }
                ]
            }
        ]
    }

    beforeFileUpload(args: any) {
        const token: string = localStorage.getItem('access_token');
        if (args.fileData.name.length > 250) {
            args.cancel = true;
        } else {
            args.currentRequest.setRequestHeader('Authorization', `Bearer ${token}`);
            this.serviceOrderService.popupLoader.next(true);
        }
    }

    onUploaderCreated(uploader: UploaderComponent) {
        if (uploader) {
            uploader.dropArea = document.getElementsByClassName('uploader-section')[0] as HTMLElement;
        }
    }

    onGridCreated(args) {
        this.updateToolbar();
    }

    onRowDataBound(args) {
        const data = args.data;
        const $row = args.row;
        if (data?.readOnly) {
            $row.getElementsByClassName('email-button')[0].disabled = false;
            $row.getElementsByClassName('delete-button')[0].disabled = true;
        } else {
            $row.getElementsByClassName('email-button')[0].disabled = true;
            $row.getElementsByClassName('delete-button')[0].disabled = false;
        }
    }

    onUploadSuccess(args: any) {
        const file = args.file;
        const response = JSON.parse(args.e.srcElement.responseText);
        const attachment = {
            attachmentId: 0,
            tempId: this.attachments.length + 1,
            serialNumber: this.attachments.length + 1,
            fileName: file.name,
            lastUpdated: new Date(),
            lastUpdatedDate: new Date(),
            size: file.size,
            fileSize: file.size,
            comment: 'New file',
            fileType: file.type.toLowerCase(),
            fileId: response.result.fileId,
            uri: response.result.uri,
            uploadedByName: USER.userName,
            uploadedById: USER.userId
        }
        this.loading = true;
        this.attachmentService.saveAttachments({
            id: this.moduleId,
            moduleName: this.module,
            attachments: [attachment]
        }).subscribe((res: ApiResponse) => {
            if (res.isSuccessful && res.result ) {
                res.result?.map((attachment, index) => {
                    attachment.tempId = attachment.attachmentId;
                    attachment.serialNumber = index + 1;
                    let lastUpdated = new Date(attachment.lastUpdated).setHours(0, 0, 0);
                    attachment.lastUpdatedDate = new Date(lastUpdated);
                    attachment.fileType = attachment.fileName.split('.')[attachment.fileName.split('.').length - 1];
                });
                this.attachments = res.result;
                this.attachmentsUpdated.emit(this.attachments);
            } else {
                this.commonService.showNotification('error', res.message);
            }
            this.loading = false;
        }, error => {
            this.loading = false;
            this.commonService.showNotification('error', error.message);
        });
    }

    onActionComplete(args: any) {
        if (args.name === 'actionComplete' && args.fileData) {
            this.serviceOrderService.popupLoader.next(false);
            this.addingAttachment = false;
            // this.attachmentsUpdated.emit(this.attachments);
        }
    }

    onGridActionBegin(args: any) {
        if (args.requestType === 'beginEdit') {
            if (this.attachmentReadOnly) {
                args.cancel = true;
                this.commonService.showPermissionNotification();
            }
        }
    }

    onGridActionComplete(args: any, grid: GridComponent) {
        updateFilterIcon(args, grid);
        if (args.requestType === 'save') {
            const data = this.setDataForUpdate(args.data);
            if (args.data.attachmentId) {
                this.loading = true;
                this.attachmentService.update(data).subscribe(res => {
                    this.loading = false;
                    this.updateAttachmentLocally(args.data);
                    this.commonService.showNotification('success', 'Attachment updated successfully')
                },
                    error => {
                        this.loading = false;
                        this.commonService.showNotification('error', 'Attachment update failed!')
                        args.cancel = true;
                        throw error;
                    });
            } else {
                this.updateAttachmentLocally(args.data);
            }
        }
    }

    updateAttachmentLocally(data) {
        const index = this.attachments.findIndex(att => att.tempId === data.tempId);
        this.attachments[index].comment = data.comment;
        this.attachments[index].lastUpdated = new Date();
        this.grid.dataSource = this.attachments;
        this.grid.refresh();
    }

    setDataForUpdate(data) {
        return {
            id: this.moduleId,
            moduleName: this.module,
            lastUpdatedBy: data.uploadedById,
            lastUpdatedByName: data.uploadedByName,
            attachment: {
                attachmentId: data.attachmentId,
                fileName: data.fileName,
                fileId: data.fileId,
                uploadedById: data.uploadedById,
                uploadedByName: data.uploadedByName,
                lastUpdated: data.lastUpdated,
                uri: data.uri,
                fileSize: data.fileSize,
                size: data.size,
                comment: data.comment,
                readOnly: false
            }
        }
    }

    onToolbarClick(args) {
        if (args.item.id === 'clear-attchments-grid-filter') {
            this.grid.clearFiltering();
            this.grid.search('');
        } else if (args.item.id === 'column-chooser') {
            // this.showColumnChooser = !this.showColumnChooser;
        } else if (args.item.id === 'excel-export') {
            const dataSource = getExcelDataSource(this.grid);
            let excelExportProperties: ExcelExportProperties = {
                dataSource,
                hierarchyExportMode: 'Expanded',
                theme: {
                    header: { bold: true, backColor: '#eeeeee', fontSize: 14 }
                },
                fileName: `Attachments ${new Date()}.xlsx`
            };
            this.grid.excelExport(excelExportProperties);
        }
    }

    onExcelQueryCellInfo(args: any) {
        excelQueryCellInfo(args);
    }

    onCommandClick(args: any) {
        if (args.commandColumn.type === 'Download') {
            downloadResource(`${BASE_URL}BlobManager/DownloadFile?fileUrl=${args.rowData.uri}`, args.rowData.fileName);
        } else if(args.commandColumn.type === 'Email'){
            this.emailAttachment = args.rowData;
            this.showEmailPopup = true;
            this.link = args.rowData.uri;
        } else {
            const data = args.rowData;
            if (!data.readOnly) {
                this.deleteAttachment(data);
            }
        }
    }

    deleteAttachment(data: any) {
        if (this.attachmentReadOnly) {
            this.commonService.showPermissionNotification();
        } else {
            this.commonService.showConfirmation('Are you sure you want to delete this attachment?')
                .then(result => {
                    if (result.isConfirmed) {
                        if (this.moduleId && data.attachmentId) {
                            const attachment = {
                                id: this.moduleId,//ItemOrderId
                                moduleName: this.module,
                                attachmentId: data.attachmentId,
                                lastUpdatedBy: USER.userId,
                                lastUpdatedByName: USER.userName
                            }
                            this.loading = true;
                            this.attachmentService.delete(attachment)
                                .subscribe((res: ApiResponse) => {
                                    this.loading = false;
                                    if (res.isSuccessful) {
                                        const index = this.attachments.findIndex(att => att.attachmentId === data.attachmentId);
                                        this.commonService.showNotification('success', 'Attachment deleted successfully!');
                                        this.removeAttachment(index);
                                        this.attachmentsUpdated.emit(this.attachments);
                                    }
                                }, (error: HttpErrorResponse) => {
                                    this.commonService.showNotification('error', 'Attachment delete failed!');
                                    this.loading = false;
                                });
                        } else {
                            const index = this.attachments.findIndex(att => att.tempId === data.tempId);
                            this.removeAttachment(index);
                            this.attachmentsUpdated.emit(this.attachments);
                        }
                    }
                });
        }
    }

    removeAttachment(index: number) {
        if (index > -1) {
            this.attachments.splice(index, 1);
        }
        if (this.grid) {
            this.grid.dataSource = this.attachments;
            this.grid.refresh();
        }
    }

    getFileSize(size: any) {
        size = +size;
        return size > 1e+6 ? Math.round(size / 1e+6) + " MB" : Math.round(size / 1000) + " KB"
    }

    downloadFile(uri: string, fileName: string) {
        downloadResource(uri, fileName);
    }

    onClose(){
        this.showEmailPopup = false;
    }

}

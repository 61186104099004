import { Component, ViewChild, OnInit, ElementRef, HostListener } from '@angular/core';
import { ApiResponse, gridNames, PersistenceData, GridPersistRequests, ModuleNames } from 'src/app/shared/models/app.model';
import { GridComponent, EditService, ExcelExportProperties, CommandClickEventArgs, SearchSettingsModel } from '@syncfusion/ej2-angular-grids';
import { RefEquipmentService } from '../ref-equipment/services/ref-equipment.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToolbarClickEventArgs } from '@syncfusion/ej2-angular-richtexteditor';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { DatePicker } from '@syncfusion/ej2-angular-calendars';
import { downloadResource } from 'src/app/shared/utils/general-utils';
import { DataManager, Query } from '@syncfusion/ej2-data';
import { environment } from 'src/environments/environment';
import { CustomAdaptor, CustomOdataV4Adaptor, getExcelDataSource, gridActionBeginForFiltering, gridActionBeginForFilteringOdataV4, gridActionsForFiltering, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { CycleCountService } from './cycle-counts.service';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';

const BASE_URL = environment.apiUrl;

@Component({
  selector: 'app-cycle-counts',
  templateUrl: './cycle-counts.component.html',
  styleUrls: ['./cycle-counts.component.scss']
})
export class CycleCountComponent implements OnInit {
  showLoader: boolean = true;
  showColumnChooser: boolean = false;
  gridName = gridNames.cycleCountGrid;
  columns: any = [];
  cycleCountList: any = [];
  grid: GridComponent;
  mainGrid: any;
  gridHeight: number = window.innerHeight - 220;
  showPopup: boolean =false;
  object;
  query: Query;
  searchSettings?: SearchSettingsModel = {
    fields: [
      'cycleCountId',
      'cycleCountNumber',
      'serviceDepartment',
      'technicianName',
      'warehouse',
      'address',
      'city',
      'state',
      'zipCode',
      'phone',
      'duration',
      'status',
      'totalCost',
      'totalExtCost'
    ]
  };
  public pageSettings: Object
  rowCycleCountData = null
  selectedTab: string = 'details';
  startDateElement: HTMLInputElement;
  startDateObject: DatePicker;
  showAddPopup: boolean = false;
  statusList
  showComments: boolean = false;
  canEditStartDate: boolean = false;

  @ViewChild('cycleCountGrid') set gridComponent(gridComponent: GridComponent) {
    if ( gridComponent ) {
      this.grid = gridComponent;
    }
  }
  @ViewChild('columnChooser') columnChooser;

  USER: any;
  preference;
  showCycleCountGrid: boolean = false;

  constructor(
    private commonService : CommonService,
    private cycleCountService: CycleCountService,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
  ) {
    this.USER = this.commonService.USER;
    this.canEditStartDate = this.commonService.roleClaims['CycleCount_StartDate']?.edit; "True for manager & CSA"
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
    let el = document.querySelector("div.e-datepicker");
    if (this.grid?.isEdit && !el?.contains(event.target)) {
      this.grid.closeEdit();
    }
  }

  ngOnInit(): void {
    this.pageSettings = { pageSizes: [20,50,100,500], pageSize: 20 };
    this.getColumns();
    this.getData();
    this.commonService.getUserPreference(this.gridName, this.USER.employeeId).subscribe((res: ApiResponse) => {
      if (res && res.result && res.result.length && res.result[0].value) {
        this.preference = res.result[0].value;
        localStorage.setItem(`grid${this.gridName}`, this.preference);
      } else {
          localStorage.removeItem(`grid${this.gridName}`);
      }
      this.showCycleCountGrid = true;
      this.showLoader = false;
    }, error => {
      localStorage.removeItem(`grid${this.gridName}`);
      this.showLoader = false;
    });
    this.showLoader = false;
    this.route.paramMap.subscribe(params => {
      if(params && params.get('id') && params.get('showComments')) {
        this.showComments = params.get('showComments') == 'showComments' ? true : false
        this.onCycleCountClick()
      }
    });
  }

  getData() {
    this.query = new Query().addParams('employeeId', this.USER.employeeId);
    this.cycleCountList = new DataManager({
      url: `${BASE_URL}odata/CycleCountOdata`,
      adaptor: new CustomOdataV4Adaptor()
    });
  }

  getColumns() {
    this.mainGrid = {
      toolbar: [],
      columns: [
        { field: 'cycleCountId', headerText: 'Order ID', type: 'string', textAlign: 'Left', width: 130, isPrimaryKey: true, filter: { type: 'Menu' }},
        { field: 'cycleCountNumber', headerText: 'Cycle Count No.', type: 'string', textAlign: 'Left', width: 120, filter: { type: 'Menu' }},
        { field: 'serviceDepartment', visible: false, headerText: 'Service Department', type: 'string', textAlign: 'Left', width: 120, filter: { type: 'Menu' }},
        { field: 'technicianName', visible: false, headerText: 'Technician', type: 'string', textAlign: 'Left', width: 140, filter: { type: 'Excel' } },
        { field: 'warehouse', headerText: 'Warehouse', type: 'string', textAlign: 'Left', width: 140, filter: { type: 'Excel' }},
        { field: 'address', visible: false, headerText: 'Address', type: 'string', textAlign: 'Left', width: 140, filter: { type: 'Menu' }},
        { field: 'city', visible: false, headerText: 'City', type: 'string', textAlign: 'Left', width: 140, filter: { type: 'Menu' }},
        { field: 'state', visible: false, headerText: 'State', type: 'string', textAlign: 'Left', width: 140, filter: { type: 'Menu' }},
        { field: 'zipCode', visible: false, headerText: 'Zip code', type: 'string', textAlign: 'Left', width: 140, filter: { type: 'Menu' }},
        { field: 'phone', visible: false, headerText: 'Phone', type: 'string', textAlign: 'Left', width: 140, filter: { type: 'Menu' }},
        { field: 'startDate', visible: true, headerText: 'Start Date', type: 'date', allowEditing: true, textAlign: 'Left', format: { type: 'date', format: 'd MMM, y' }, width: 140, edit: this.editstartDate(), showInColumnChooser: true },
        { field: 'createdDate', headerText: 'Created Date', type: 'date', format: { type: 'date', format: 'd MMM, y' }, filter: { type: 'Menu' }, textAlign: 'Left', width: 140 , },
        { field: 'endDate', visible: false, headerText: 'End Date', type: 'date', format: { type: 'date', format: 'd MMM, y' }, filter: { type: 'Menu' }, textAlign: 'Left', width: 140 , },
        { field: 'duration', headerText: 'Duration', type: 'number', format: 'n', textAlign: 'Left', width: 100, filter: { type: 'Menu' }},
        { field: 'status', headerText: 'Status', type: 'string', textAlign: 'Left', width: 140 , filter: { type: 'Excel' }}, 
        { field: 'totalCost', headerText: 'Cost VAR (LN - ADJ)', type: 'number', format: 'n', textAlign: 'Left', width: 170, filter: { type: 'Menu' }},
        { field: 'totalExtCost', headerText: 'Ext Cost (LN)', type: 'number', format: 'n', textAlign: 'Left', width: 170, filter: { type: 'Menu' }},
        {
          field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  width: 300, textAlign: 'Center', allowFiltering: false, allowSorting: false, freeze: 'Right', allowReordering: false,
          commands: [
              { title: 'Schedule', buttonOption: { iconCss: `fa fa-calendar-check`, cssClass: 'e-flat schedule' } },
              { title: 'Details', buttonOption: { iconCss: `fa fa-list`, cssClass: 'e-flat' } },
              { title: 'Cost Analysis', buttonOption: { iconCss: `fa fa-table`, cssClass: 'e-flat' } },
              { title: 'Attachment', buttonOption: { iconCss: `fa fa-download`, cssClass: 'e-flat attachments' } },
              { title: 'Comment', buttonOption: { iconCss: `fa fa-comment`, cssClass: 'e-flat comments' } },
              { title: 'History', buttonOption: { iconCss: `fa fa-history`, cssClass: 'e-flat' } },
              { title: 'Delete Cycle Count', buttonOption: { iconCss: `fa fa-trash`, cssClass: 'e-flat delete-cycle-count' } },
          ]
      }
      ]};
  }

  onGridActionBegin(args, grid?) {
    gridActionBeginForFilteringOdataV4(args);
  }

  onGridActionComplete(args, grid){
    gridActionsForFiltering(args, grid);
    updateFilterIcon(args, grid);
    if (GridPersistRequests.includes(args.requestType)) {
      this.savePersistance(grid);
  }
  }

  getStatusClass(status: string) {
    return this.commonService.getStatusClass(status);
  }

  setGridToolbar() {
    this.grid.toolbar = [
        { id: 'column-chooser', template: this.columnChooser, tooltipText: 'Show/Hide Columns' },
        // { id: 'reset-persistence',align: 'Right', prefixIcon: 'fas fa-sync', cssClass: '', tooltipText: 'Reset Persistence' },
        { id: 'refresh',align: 'Right', prefixIcon: 'fas fa-redo-alt', cssClass: '', tooltipText: 'Refresh' },
         'Search',
        { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' },
        { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: `grid-filter-icon`, tooltipText: 'Clear all Filters' }
    ];

    if (this.commonService.roleClaims['CycleCountGrid_Toolbar_AddCycleCount']?.visible) {
      const index = this.grid.toolbar.findIndex(tool => tool['id'] === 'refresh');
      this.grid.toolbar.splice(index, 0, { text: '', id: 'add-new-cycle-count', prefixIcon: 'e-add', cssClass: '', tooltipText: 'Add Cycle Count' });
    }
  }

  onGridCreated() {
    updateFilterIcon({ requestType: 'filtering' }, this.grid);
    this.setGridToolbar();
    this.showLoader = false;
  }

  onToolbarClick(args: ToolbarClickEventArgs, grid:GridComponent) {
    if (args.item.id === 'add-new-cycle-count') {
      this.showAddPopup = true;
    }
    if (args.item.id === 'excel-export') {
        const dataSource = getExcelDataSource(this.grid);
        let excelExportProperties: ExcelExportProperties = {
            dataSource,
            hierarchyExportMode: 'Expanded',
            theme: {
                header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
            },
            fileName: `Cycle count (${getCurrentDate()}).xlsx`
        };
        this.grid.excelExport(excelExportProperties);
    } else if (args.item.id === 'column-chooser') {
        // this.showColumnChooser = !this.showColumnChooser;
    } else if (args.item.id === 'clear-filter') {
        this.grid.clearFiltering();
        this.grid.search('');
    }  else if (args.item.id === 'refresh') {
      this.getData();
    } else if (args.item.id === 'reset-persistence') {
      this.showLoader = true;
      this.commonService.showConfirmation("Are you sure? This will reset the grid filters and persistence.", "Ok", "Cancel").then(result => {
        if (result.isConfirmed) {
          this.commonService.resetUserPreference({ userId: this.USER.userId, name: gridNames.cycleCountGrid, value: '' }).subscribe((response) => {
            this.showLoader = false;
          });
        }
        else{
          this.showLoader = false;
          return
        }
      });
    }
  }

  closeAddPopup(event) {
    this.showAddPopup = false
  }

  onRowDataBound(args: any) {
    const data = args.data;
    const $row = args.row;
    if (data.status !== 'Planning' || data.startDate == null) {
      $row.getElementsByClassName('schedule')[0]?.classList.add('disabled');
    } else {
      $row.getElementsByClassName('schedule')[0]?.classList.remove('disabled');
    }
    if (data.status !== 'Open') {
      $row.getElementsByClassName('delete-cycle-count')[0]?.classList.add('disabled');
    } else {
      $row.getElementsByClassName('delete-cycle-count')[0]?.classList.remove('disabled');
    }
    if (data.commentCount != 0) {
      $row.getElementsByClassName('comments')[0]?.classList.add('color-orange');
    } else {
      $row.getElementsByClassName('comments')[0]?.classList.remove('color-orange');
    }
    if (data.attachmentCount != 0) {
      $row.getElementsByClassName('attachments')[0]?.classList.add('color-green');
    } else {
      $row.getElementsByClassName('attachments')[0]?.classList.remove('color-green');
    }
  }

  savePersistance(grid) {
    const persistenceData: PersistenceData = {
        userId: this.USER.userId,
        name: gridNames.cycleCountGrid,
        value: grid.getPersistData()
    }
    this.commonService.saveUserPreference(persistenceData);
    localStorage.setItem(`grid${gridNames.cycleCountGrid}`, grid.getPersistData());
  }

  onCommandClick(args: any) {
    switch (args.commandColumn.title) {
      case 'Details':
        this.showPopup = true
        this.showComments = false;
        this.rowCycleCountData = args.rowData
        this.selectedTab = 'details';
        break;

      case 'Delete Cycle Count':
        this.commonService.showConfirmation(`Are you sure? ${args.rowData.cycleCountId} will be deleted.`)
          .then(result => {
            if (result.isConfirmed) {
              this.deleteCycleCount(args.rowData.cycleCountId);
            }
          });
        break;

      case 'Cost Analysis':
        this.showPopup = true
        this.showComments = false;
        this.rowCycleCountData = args.rowData
        this.selectedTab = 'costAnalysis';
        break;

      case 'History':
        this.showPopup = true
        this.showComments = false;
        this.rowCycleCountData = args.rowData
        this.selectedTab = 'history';
        break;

      case 'Attachment':
        this.showPopup = true
        this.showComments = false;
        this.rowCycleCountData = args.rowData
        this.selectedTab = 'attachments';
        break;

      case 'Comment':
        const commentData = {
          grid: this.grid,
          module: ModuleNames.cycleCount,
          moduleId: args.rowData.cycleCountId,
          showComments: true
        }
        this.commonService.globalComments = commentData
        break;

      case 'Schedule':
        this.scheduleCycleCount(args)

      default:
        // Default action if none of the cases match.
        break;
    }
  }


  scheduleCycleCount(args) {
    const data = {
      cycleCountId: args?.rowData?.cycleCountId,
      status: 'Scheduled'
    }
    if (args?.rowData?.openExceptionCount > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please adjust all exceptions before scheduling the cycle count!',
        confirmButtonColor: '#479e47'
      })
    } else {
      this.cycleCountService.updateCycleCountStatus(data)
      .subscribe((response: any) => {
        if (response.isSuccessful) {
          this.grid.refresh();
          this.commonService.showNotification('success', response.message)
          this.showLoader = false;
        } else {
          this.commonService.showNotification('error', response.message)
          this.showLoader = false;
        }
      }, (error: HttpErrorResponse) => {
        this.showLoader = false;
        this.commonService.showNotification('error', error.message);
      }
      );
    }
  }


  editstartDate() {
    return {
      create: () => {
        this.startDateElement = document.createElement('input');
        return this.startDateElement;
      },
      read: () => {
        if (this.startDateObject) {
          return this.startDateObject.value;
        }
      },
      destroy: () => {
        this.startDateObject.destroy();
      },
      write: (args) => {
        this.startDateObject = new DatePicker({
          placeholder: "Select start date...",
          value: args.rowData.startDate,
          min: new Date(),
          format: 'd MMM, y',
          showClearButton: false,
          allowEdit: false,
          enabled: true,
          openOnFocus: true,
          change: event => {
            this.showLoader = true;
            if (Date.parse(event.value) || !event.value) {
              let startDate = event.value ? new Date(event.value).toISOString() : null;
              const payload = {
                cycleCountId: args.rowData.cycleCountId,
                status: 'Planning',
                startDate: startDate
              }
              this.cycleCountService.updateCycleCountStartDate(payload)
                .subscribe((response: any) => {
                  if (response.isSuccessful) {
                    // const rowIndex = this.serviceOrders.findIndex(row => row.serviceOrderId === args.rowData.serviceOrderId);
                    // this.serviceOrders[rowIndex] = response.result;
                    this.grid.refresh();
                    this.commonService.showNotification('success', response.message)
                    this.showLoader = false;
                  } else {
                    this.commonService.showNotification('error', response.message)
                    this.showLoader = false;
                  }
                }, (error: HttpErrorResponse) => {
                  this.showLoader = false;
                  this.commonService.showNotification('error', error.message);
                }
                );
            } else {
              this.showLoader = false;
              this.commonService.showNotification('warning', "Inavlid start date. kindly, add a valid date.");
            }
          }
        });
        this.startDateObject.appendTo(this.startDateElement);
      }
    }
  }


  deleteCycleCount(cycleCountId) {
    this.cycleCountService.deleteCycleCount(cycleCountId)
      .subscribe((res: any) => {
        this.grid.refresh();
        this.commonService.showNotification('warning', `${cycleCountId} has been deleted.`)
      }, (error: HttpErrorResponse) => {
      });
  }

  refreshGrid() {
    this.grid.refresh()
  }

  onCycleCountClick(data?: string) {
    this.selectedTab = 'details';
    this.showPopup = true;
    this.rowCycleCountData = data
  }

  onClose(){
    this.showPopup=false;
    this.rowCycleCountData = null
    this.statusList = null
  }

}

import { HttpErrorResponse } from '@angular/common/http';

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  CommandColumnService,
  ExcelExportProperties,
  ExcelExportService,
  FilterService,
  GridComponent,
  SortService,
  ToolbarService,
  EditService,
  ForeignKeyService
} from '@syncfusion/ej2-angular-grids';
import { Tooltip } from '@syncfusion/ej2-angular-popups';
import { DateTimePicker } from '@syncfusion/ej2-calendars';
import { Query } from '@syncfusion/ej2-data';
import { DropDownList, ComboBox } from '@syncfusion/ej2-dropdowns';
import { NumericTextBox, TextBox } from '@syncfusion/ej2-inputs';
import { forkJoin, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { InventoryService } from 'src/app/modules/home/services/inventory.service';
import { ApiResponse, Companies, gridNames, GridPersistRequests, PartsAvailabilityStatus, PersistenceData, UserRoles } from 'src/app/shared/models/app.model';
import { CommonService } from 'src/app/shared/services/common.service';
import { getCurrentDate } from 'src/app/shared/utils/date-functions';
import { excelQueryCellInfo, getExcelDataSource, updateFilterIcon } from 'src/app/shared/utils/grid-functions';
import { CostTypes, CostTypesList, ModeList, SubCostTypeList, SubCostTypes } from '../../models/service-order.model';
import { ExecutionTabService } from '../../services/execution-tab.service';
import { ServiceOrderService } from '../../services/service-order.service';

@Component({
  selector: 'app-execution-tab',
  templateUrl: './execution-tab.component.html',
  styleUrls: ['./execution-tab.component.scss'],
  providers: [
    ToolbarService,
    FilterService,
    SortService,
    ExcelExportService,
    CommandColumnService,
    EditService,
    ForeignKeyService
  ]
})

export class ExecutionTabComponent implements OnInit, OnChanges {

  private destroy$: Subject<null> = new Subject();

  serviceTypes = [];
  transactionGridName: string = gridNames.serviceOrderTransactionsGrid;
  @Input() data = null;
  @Input() equipmentInfoData = null;
  @Input() configurationId = null;
  @Input() distanceZone = null;
  @Input() customerCurrency = 'USD';
  @Input() isEditable: boolean;
  @Input() fieldRules: any;
  @Input() assignments = [];
  @Input() assignmentDetails = [];
  @Input() taxRates = [];
  @Input() checkinTime: any = null;
  @Input() costTypes = CostTypesList;

    preference: any;
    isOffline: boolean;
    isIsc: boolean;
    isIscManager: boolean;
    isKingslynn: boolean = false;
    ddlFetched: boolean = false;
    showAssignmentsPopup: boolean = false;
    showAssignmentsColumnChooser: boolean = false;
    showCartCheckoutPopup: boolean = false;
    getPriceForItem: boolean = true;
    itemChangedFromAdvanceSearch: boolean = false;
    assignmentGrid = null;
    selectedAssignment = null;
    gridButtonClass: string = '';
    warranty = '';
    contract = '';
    USER;

    fromExecutionTab: any;

  coreItemGroup = ['450SVC'];

  isTabValid: boolean = true;
  mandatoryItem: boolean = false;
  showAssignmentDetailsPopup: boolean = false;
  showAssignmentDetailsColumnChooser: boolean = false;
  skipBeginEditEvent: boolean = false;
  assignmentDetailGrid = null;
  selectedAssignmentDetail = null;
  /**
   * Set this variable if there is only one assignment and reset to null once reference assignment value is populated.
   * We are using this to populate Reference Activity by default if there is only one assignment in assignmentsGrid
   */
  defaultReferenceActivity = null;

  @ViewChild('assignmentTemplate') assignmentTemplate: any;
  @ViewChild('assignmentsGrid') assignmentsGrid: GridComponent;
  @ViewChild('assignmentDetailsGrid') assignmentDetailsGrid: GridComponent;
  @ViewChild('columnChooserAssignments') assignmentsColumnChooser;
  @ViewChild('columnChooserAssignmentDetails') assignmentDetailsColumnChooser;

  query = new Query().where('isDeleted', 'equal', false);

  @Output() tabUpdated = new EventEmitter();
  @Output() showPartsOrderPopup: EventEmitter<any> = new EventEmitter();

  warehouse: string;

  referenceActivities = [];
  reportedProblems = [];
  solutions = [];
  priorities = [];
  units = [];
  serviceItems = [];
  returnReasons = [];
  reportedProblem: any;
  reportedProblemObject: DropDownList;
  reportedProblemDescription: any;

  description: any;
  descriptionObject: TextBox;

  solution: any;
  solutionObject: DropDownList;
  solutionDescription: any;

  priority: any;
  priorityObject: DropDownList;

  startDate: any;
  startDateObject: DateTimePicker;

  endDate: any;
  endDateObject: DateTimePicker;

  assignment: any;
  assignmentObject: any;

  assignmentDetailReferenceActivity: any;
  assignmentDetailReferenceActivityObject: TextBox;
  assignmentDetailReferenceActivityDescription: any;
  assignmentDetailReferenceActivityCode: any;

  assignmentDetailServiceType: any;
  assignmentDetailServiceTypeObject: TextBox;
  assignmentDetailServiceTypeCode: TextBox;
  assignmentDetailServiceTypDescription: any;

  comments: any;
  commentsObject: TextBox;

  costType: any;
  costTypeObject: DropDownList;

  subcostType: any;
  subcostTypeObject: any;

  itemGroup: any;
  serviceItemGroup: any;
  rowServiceItemGroup: any;

  itemCode: any;
  itemCodeObject;

  selectedRowId: string;
  showItemAdvancedSearchPopup: boolean = false;
  onItemUpdate = new Subject<string>();
  itemsList = [];

  itemDescription: any;
  itemDescriptionObject: TextBox;

  quantity: any;
  quantityObject: NumericTextBox;
  onHandQuantity;

  discountAmount: any;
  discountAmountObject: NumericTextBox;

  warrantyAmount: any;
  warrantyAmountObject: NumericTextBox;

  contractAmount: any;
  contractAmountObject: NumericTextBox;

  price: any;
  priceObject: NumericTextBox;

  totalAmount: any = 0;
  totalAmountValue: any;
  totalAmountObject: NumericTextBox;

  tempUnit: string = '';
  unit: any;
  unitObject: DropDownList;
  unitCode: any;

  returnReason: any;
  returnReasonObject: DropDownList;

  currency: any;
  currencyObject: TextBox;

  assignmentDetailStartTime: any;
  assignmentDetailStartTimeValue: any;
  assignmentDetailStartTimeObject: DateTimePicker;

  assignmentDetailEndTime: any;
  assignmentDetailEndTimeObject: DateTimePicker;

  task: any;
  taskValue: string;
  taskObject: TextBox;

  tax: any;
  taxAmount: any;
  taxObject: NumericTextBox;

  taxPercent: any;
  taxPercentObject: NumericTextBox;

  laborType: any;
  laborTypeObject: TextBox;

  costComponent: any;
  costComponentValue: string;
  costComponentObject: TextBox;

  transactionWarehouse: any;
  transactionWarehouseObject: TextBox;

  costTypes1 = [
    { text: 'Material', value: 'Material' },
    { text: 'Other Cost', value: 'Costitem' }
  ];

  activityId: any;
  refActivityCategory: any;
  assignmentId: any;
  assignmentDetailId: any;
  warrantyInfo: any;
  contractInfo: any;

  referenceActivity: any;
  referenceActivityObject: DropDownList;
  referenceActivityDescription: any;

  serviceType: any;
  serviceTypeObject: TextBox;
  serviceTypeCode: any;
  priceValue: any = 0;
  quantityValue: any = 0;
  discountValue: any = 0;
  startTimeValue: any;
  laborRate: any = 0;
  calloutRate: any = 0;
  modelDescription: any;
  serialNumber: any;

  addAssignmentRow: any = null;
  addTransactionRow: any = null;

  loadedAssignmentItemDetails: any = [];

  assignmentDetailAction: string = '';
  showLoader: boolean = false;
  assignmentsReadOnly: boolean = false;
  transactionsReadOnly: boolean = false;

  constructor(
    public commonService: CommonService,
    private executionTabService: ExecutionTabService,
    private serviceOrderService: ServiceOrderService,
    private inventoryService: InventoryService,
    private eRef: ElementRef
  ) {
    this.USER = this.commonService.USER;
    this.assignmentsReadOnly = this.commonService.roleClaims['SO_Popup_Field_assignments']?.readOnly;
    this.transactionsReadOnly = this.commonService.roleClaims['SO_Popup_Fields_transactions']?.readOnly;
  }

  @HostListener('document:click', ['$event'])
  onClick(event) {
      const assignmentGridEdited = this.assignmentsGrid?.isEdit;
      const assignmentDetailsGridEdited = this.assignmentDetailsGrid?.isEdit;
      const grid: GridComponent = assignmentGridEdited ? this.assignmentsGrid : assignmentDetailsGridEdited ? this.assignmentDetailsGrid : null;
      if ( grid?.isEdit ) {
        const excludedClasses = ['e-ddl', 'e-popup', 'e-lib', 'e-control', 'e-popup-close', 'e-day', 'loader-container'];
        let targetElement = event.target as HTMLElement;
        while (targetElement) {
            if (excludedClasses.some(className => targetElement.classList.contains(className))) {
                event.stopPropagation();
                return;
            }
            targetElement = targetElement.parentElement;
        }
        grid.endEdit();
    }
  }

  ngOnInit(): void {
    this.warehouse = this.data?.technicianWarehouse || this.USER.warehouse;
    this.isOffline = this.data?.offlineOrder;
    this.isIsc = this.USER.role === UserRoles.isc;
    this.isIscManager = this.USER.role === UserRoles.iscManager;
    this.data.warrantyCoverageLines && (this.warrantyInfo = JSON.parse(this.data.warrantyCoverageLines));
    this.data.contractCoverageLines && (this.contractInfo = JSON.parse(this.data.contractCoverageLines));


    this.isKingslynn = this.USER.company === Companies.kingslynn
    this.serviceOrderService.nteChange.pipe(
      takeUntil(this.destroy$),
    ).subscribe((args: any) => {
      if (args.value) {
        this.data.approvedAmount = args.value;
      }
    });
    this.serviceOrderService.modelDescriptionChange.subscribe(modelDescription => {
      this.modelDescription = modelDescription;
    });

    this.serviceOrderService.callgroupChange.pipe(
      takeUntil(this.destroy$)
    ).subscribe(callGroup => {
      this.executionTabService.getReferenceActivities(this.USER.company, callGroup).subscribe((res: ApiResponse) => {
        this.referenceActivities = res.result.map(activity => {
          activity.text = activity.description;
          return activity;
        });
      });
    });

    this.serviceOrderService.serialNumberChange.pipe(
      takeUntil(this.destroy$),
    ).subscribe((args: any) => {
      if (args.itemData && args.itemData.serialNumber) {
        this.serialNumber = args.itemData.serialNumber;
      }
    });

    this.serviceOrderService.equipmentInfoSubject.pipe(
      takeUntil(this.destroy$),
    ).subscribe(equipmentInfo => {
      if (equipmentInfo) {
        this.equipmentInfoData = equipmentInfo.data;
        this.warrantyInfo = equipmentInfo.warrantyInfo;
        this.contractInfo = equipmentInfo.contractInfo;
        this.equipmentInfoData.warrantyDescriptionObj = equipmentInfo.warrantyInfo;
        this.equipmentInfoData.serviceContractDescriptionObj = equipmentInfo.contractInfo;
      }
    });
    this.serviceOrderService.hasTransaction.next(this.assignmentDetails.filter(x => !x.isDeleted).length > 0);
    this.setLoadedAssignmentItemDetails(this.assignmentDetails);
    this.gridButtonClass = this.isEditable ? '' : 'disabled-grid-button';
    this.setPageData();
    this.loadTab();
    this.warranty = localStorage.getItem('warranty') || '';
    this.contract = localStorage.getItem('contract') || '';
    this.assignmentDetailStartTimeValue = this.checkinTime;
    if (this.USER.company === Companies.conway) {
      this.getItemList();
    }
    this.onItemUpdate
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(value => {
        if (this.itemCodeObject) {
          this.itemCodeObject.showPopup();
          this.itemCodeObject.showSpinner();
        }
        this.getItemList({ itemCode: value });
      });
  }

  setLoadedAssignmentItemDetails(transactions) {
    this.loadedAssignmentItemDetails = transactions.map(x => {
      return {
        itemCode: x.itemCode,
        issuedQty: x.issuedQty
      }
    });
  }

  getItemList(rowData?) {
    const value = rowData?.itemCode || '';
    this.showLoader = true;
    let serviceItemGroup = '';
    if (rowData?.costType === CostTypes.otherCost || this.costTypeObject?.value === CostTypes.otherCost) {
      serviceItemGroup = '350SVC';
    }
    this.commonService.getItems(value, 30, this.USER.company, serviceItemGroup)
      .subscribe((res: ApiResponse) => {
        if (res && res.result && res.result.length) {
          res.result.map(item => {
            item.value = item.item;
            item.text = `${item.item} | ${item.itemDescription}`;
          });

          const existingItems = [];
          (this.assignmentDetailsGrid?.dataSource as any)?.forEach(transaction => {
            if (transaction.itemCode !== this.itemCodeObject?.value) {
              existingItems.push(transaction.itemCode);
            }
          });
          this.itemsList = res.result.filter(item => !existingItems.includes(item.value));
          if (this.itemCodeObject) {
            this.itemCodeObject.hideSpinner();
            this.itemCodeObject.dataSource = this.itemsList;
          }
          this.showLoader = false;
        } else {
          this.itemsList = [];
          if (this.itemCodeObject) {
            this.itemCodeObject.hideSpinner();
            this.itemCodeObject.dataSource = [];
          }
          this.showLoader = false;
        }
      });
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change && change.isEditable) { // Calls if mode changed
      // Do nothing . . .
      // If we want to do anything other than enable/disable we do that here
    }

    if (change && change.assignments && change.assignments.currentValue) {
      this.assignments = change.assignments.currentValue;
      if (this.assignments && this.assignments.length) {
        this.assignments.map(assignment => {
          assignment.tempId = parseInt(assignment.assignmentId);
        });
      }
      if (!change.assignments.firstChange) {
        if (this.assignmentsGrid) {
          this.assignmentsGrid.dataSource = this.assignments;
          this.assignmentsGrid.refresh();
        }
      }
    }

    if (change && change.taxRates && change.taxRates.currentValue) {
      this.taxRates = change.taxRates.currentValue;
    }

    if (change && change.distanceZone && change.distanceZone.currentValue) {
      this.distanceZone = change.distanceZone.currentValue;
      this.getCalloutChargeRate();
    }

    if (change && change.configurationId && change.configurationId.currentValue) {
      this.configurationId = change.configurationId.currentValue;
      this.getLaborRate();
    }

    if (change && change.assignmentDetails && change.assignmentDetails.currentValue) {
      this.assignmentDetails = change.assignmentDetails.currentValue;
      if (!change.assignmentDetails.firstChange) {
        this.assignmentDetailGrid.dataSource = change.assignmentDetails.currentValue;
        this.assignmentDetailsGrid.columns[2] = {
          field: 'assignmentId', headerText: 'Reference Activity *', width: 148, filter: { type: 'Excel' }, textAlign: 'Left',
          foreignKeyValue: 'referenceActivityDescription', foreignKeyField: 'tempId', showInColumnChooser: false, dataSource: this.assignments, edit: this.editAssignment()
        };
        this.assignmentDetailsGrid.refreshColumns();
        this.assignmentDetailsGrid.refresh();
      }
    }

    if (change && change.isEditable && change.isEditable.currentValue !== undefined && !change.isEditable.firstChange) {
      this.isEditable = change.isEditable.currentValue;
      if (this.isEditable) {
        document.getElementsByClassName('grid-add-button')[0].classList.remove('disabled-grid-button');
        document.getElementsByClassName('grid-add-button')[1].classList.remove('disabled-grid-button');
      } else {
        document.getElementsByClassName('grid-add-button')[0].classList.add('disabled-grid-button');
        document.getElementsByClassName('grid-add-button')[1].classList.add('disabled-grid-button');
      }
      // this.calculateAmounts(false);
      this.refreshGrids();
    }
  }

  refreshGrids() {
    this.assignmentsGrid && this.assignmentsGrid.refresh();
    this.assignmentDetailsGrid && this.assignmentDetailsGrid.refresh();
  }

  getLaborRate() {
    this.executionTabService.getLaborSalesRate(this.configurationId)
      .subscribe((response: ApiResponse) => {
        if (response && response.isSuccessful) {
          this.laborRate = response.result;
        }
      });
  }

  getCalloutChargeRate() {
    this.executionTabService.getCallOutCharge(this.distanceZone, 0)
      .subscribe((response: ApiResponse) => {
        if (response && response.isSuccessful && response.result) {
          this.calloutRate = response.result;
        }
      });
  }

  validate() {
    this.assignmentsGrid?.endEdit();
    this.assignmentDetailsGrid?.endEdit();
    if (this.assignmentsGrid?.isEdit || this.assignmentDetailsGrid?.isEdit) {
      this.isTabValid = false;
      return false;
    } else {
      this.isTabValid = true;
      return true;
    }
  }

  setPageData() {
    if (!this.data) {
      this.data = {
        serviceOrderId: null,
        serviceType: null,
        salesAmount: 0,
        discountAmount: 0,
        warrantyCoverage: 0,
        contractCoverage: 0,
        invoiceAmount: 0,
        tax: 0,
        taxable: false,
        totalAmount: 0
      }
    } else {
      if (this.assignmentDetails && this.assignmentDetails.length) {
        this.calculateAmounts(true);
      }
    }
  }

  onAssignmentsGridCreated(args) {
    const disableAddIcon = this.isEditable ? this.USER.company === Companies.kingslynn && this.checkBillable() ? 'disabled-grid-button' : this.gridButtonClass : 'disabled-grid-button';

    this.assignmentsGrid.toolbar = [
      { id: 'column-chooser', tooltipText: 'Show/Hide Columns', template: this.assignmentsColumnChooser },
      { text: '', id: 'add-new-assignment', prefixIcon: 'e-add', cssClass: `grid-add-button ${disableAddIcon}`, tooltipText: 'Add Assignment' },
      'Search',
      { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = this.assignmentGrid.toolbar.findIndex(item => item === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
      this.assignmentGrid.toolbar.splice(columnChooserIndex + 1, 0, { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
    }
  }

  onTransactionsGridCreated(args) {
    this.assignmentDetailsGrid.toolbar = [
      { id: 'column-chooser-assignment-detail', tooltipText: 'Show/Hide Columns', template: this.assignmentDetailsColumnChooser },
      { text: '', id: 'add-new-assignment-detail', prefixIcon: 'e-add', cssClass: `grid-add-button ${this.gridButtonClass}`, tooltipText: 'Add Assignment Detail' },
      'Search',
      { text: '', id: 'clear-filter', align: 'Right', prefixIcon: 'fas fa-filter', cssClass: 'grid-filter-icon', tooltipText: 'Clear all Filters' }
    ];
    const columnChooserIndex = this.assignmentDetailGrid.toolbar.findIndex(item => item === 'Search');
    if (this.commonService.roleClaims['AllGrid_Toolbar_Excel_Export']?.visible) {
      this.assignmentDetailGrid.toolbar.splice(columnChooserIndex, 0, { text: '', id: 'excel-export', align: 'Right', prefixIcon: 'e-excelexport', cssClass: '', tooltipText: 'Excel Export' });
    }
  }

  loadTab() {
    this.serviceOrderService.popupLoader.next(true);
    forkJoin([
      this.executionTabService.getServiceTypes(this.USER.company),
      this.executionTabService.getReferenceActivities(this.USER.company, this.data.callGroup),
      this.executionTabService.getReportedProblems(this.USER.company, this.data.serializedItemGroupCode),
      this.executionTabService.getSolutions(this.USER.company, this.data.serializedItemGroupCode),
      this.serviceOrderService.getPriorities(this.USER.company),
      this.executionTabService.getUnits(this.USER.company),
      this.executionTabService.getReturnReasons(this.USER.company),
      this.commonService.getUserPreference(gridNames.serviceOrderTransactionsGrid, this.USER.employeeId)
    ]).subscribe((res: any) => {
      if (res[7] && res[7].result && res[7].result.length && res[7].result[0].value) {
        this.preference = res[7].result[0].value;
        localStorage.setItem(`grid${this.transactionGridName}`, this.preference);
      } else {
        localStorage.removeItem(`grid${this.transactionGridName}`);
      }

      this.serviceTypes = res[0].result;
      this.referenceActivities = res[1].result.map(activity => {
        activity.text = activity.description;
        return activity;
      });
      this.reportedProblems = res[2].result.map(problem => {
        problem.text = problem.description;
        return problem;
      });
      this.solutions = res[3].result;
      this.priorities = res[4].result.map(priority => {
        priority.text = priority.description;
        return priority;
      });

      this.units = res[5].result.map(row => {
        row.text = row.description;
        return row;
      });

      this.returnReasons = res[6].result.map(row => {
        row.text = row.description;
        return row;
      });

      this.ddlFetched = true;
      this.initGrids();
      // this.data.priority = this.data.priority ? this.data.priority : this.priorities.find( priority => priority.text === 'Normal').value;
      this.serviceOrderService.popupLoader.next(false);
      this.commonService.getUserDefaults(this.data.technicianId ?? this.USER.userId);
    }, (error: HttpErrorResponse) => {
      this.serviceOrderService.popupLoader.next(false);
    });
    // this.executionTabService.getServiceTypes()
    // .subscribe( (res: any) => {
    //     this.serviceTypes = res.result;
    // });
  }

  initGrids() {
    if (this.assignments && this.assignments.length) {
      this.assignments.map((assignment, index) => {
        assignment.tempId = parseInt(assignment.assignmentId);
        assignment.rowId = index + 1;
      });
    }
    if (this.assignmentDetails && this.assignmentDetails.length) {
      this.assignmentDetails.map((detail, index) => {
        detail.tempId = parseInt(detail.assignmentDetailId);
        detail.rowId = index + 1;
      });
    }
    this.initAssignmentsGrid();
    this.initAssignmentDetailsGrid()
  }

  initAssignmentsGrid() {
    this.assignmentGrid = {
      toolbar: [],
      filterSettings: { type: 'Menu' },
      columns: [
        { field: 'rowId', type: 'number', isPrimaryKey: true, showInColumnChooser: false, visible: false },
        { field: 'activityId', type: 'number', headerText: 'ID', width: 120, textAlign: 'Left', format: 'n', visible: false },
        {
          field: 'referenceActivityCode', headerText: 'Reference Activity', textAlign: 'Left', showInColumnChooser: false, validationRules: { required: true }, filter: { type: 'Excel' },
          foreignKeyValue: 'description', foreignKeyField: 'value', dataSource: this.referenceActivities, edit: this.editReferenceActivity()
        },
        { field: 'serviceTypeDescription', headerText: 'Service Type', textAlign: 'Left', type: 'string', showInColumnChooser: false, filter: { type: 'Excel' }, edit: this.editServiceType() },
        {
          field: 'reportedProblem', headerText: 'Reported Problem', filter: { type: 'Excel' }, textAlign: 'Left',
          foreignKeyValue: 'description', foreignKeyField: 'value', dataSource: this.reportedProblems, edit: this.editReportedProblem()
        },
        { field: 'description', headerText: 'Description', textAlign: 'Left', type: 'string', showInColumnChooser: false, allowEditing: true, validationRules: { required: false } },
        {
          field: 'solutionCode', headerText: 'Solution', type: 'string', filter: { type: 'Excel' }, textAlign: 'Left',
          foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: this.solutions, edit: this.editSolution()
        },
        {
          field: 'priority', headerText: 'Priority', type: 'string', filter: { type: 'Excel' }, textAlign: 'Left', visible: false,
          foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: this.priorities, edit: this.editPriority(), validationRules: { required: true }
        },
        { field: 'startDate', headerText: 'Start Date', type: 'date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y h:mm a ' }, width: 160, edit: this.editStartDate(), visible: false },
        { field: 'endDate', headerText: 'End Date', type: 'date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y h:mm a' }, width: 160, edit: this.editEndDate(), visible: false },
        {
          field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  textAlign: 'Center', width: 120, allowFiltering: false, allowSorting: false,
          commands: [
            {
              title: 'Edit Assignment',
              type: 'Edit',
              buttonOption: {
                iconCss: `fas fa-pencil-alt`,
                cssClass: `e-flat action-button`
              }
            },
            {
              title: 'Delete Assignment',
              type: 'Edit',
              buttonOption: {
                iconCss: `fas fa-trash`,
                cssClass: `e-flat action-button`
              }
            }
          ]
        }
      ]
    }
  }

  assignmentActionBegin(args) {
    if (args.requestType === 'beginEdit') {
      if (this.assignmentDetailsGrid.isEdit) {
        args.cancel = true;
        this.commonService.showNotification('warning', 'Transactions grid is in edit mode! ');
      }
      if (this.USER.company === Companies.kingslynn && !args.rowIndex && args.rowData.referenceActivityCode) { // only for kinglynn: User can not edit 1st assignment
        args.cancel = true;
      }
      if (this.assignmentsReadOnly) {
        args.cancel = true;
        this.commonService.showPermissionNotification();
      }
      this.referenceActivityDescription = args.rowData.referenceActivityDescription;
      this.serviceTypeCode = args.rowData.serviceTypeCode;
      this.reportedProblemDescription = args.rowData.reportedProblemDescription;
      this.solutionDescription = args.rowData.solutionDescription;
  } else if (args.requestType === 'save') {
      args.data.referenceActivityDescription = this.referenceActivityDescription;
      args.data.serviceTypeCode = this.serviceTypeCode;
      args.data.reportedProblemDescription = this.reportedProblemDescription;
      args.data.solutionDescription = this.solutionDescription;
      args.data['category'] = this.refActivityCategory;

      if (!this.assignmentsGrid.getColumnByField('startDate').visible) {
        args.data.startDate = this.checkinTime ? new Date(this.checkinTime) : null;
      }
    } else if (args.requestType === 'delete') {
      args.cancel = true;
      if (this.assignmentsReadOnly) {
        this.commonService.showPermissionNotification();
      } else {
        if (!args.data[0].assignmentId || !args.data[0].activityIdLN || args.data[0].activityIdLN !== '10') {
          this.deleteAssignment(args.data[0], this.assignmentsGrid);
        }
      }
    }
  }

  setTempValuesForTransaction(data) {
    // this.assignmentId = +data.tempId;
    this.assignmentDetailId = +data.assignmentDetailId;
    this.activityId = data.activityId;
    this.assignmentDetailReferenceActivityDescription = data.referenceActivityDescription;
    this.assignmentDetailReferenceActivityCode = data.referenceActivityCode
    this.assignmentDetailServiceTypeCode = data.serviceTypeCode;
  }

  onAssignmentDetailsActionBegin(args) {
    if (args.requestType === 'beginEdit') {
      this.taxAmount = 0;
      this.getPriceForItem = false;
      this.serviceItemGroup = args.rowData.serviceItemGroup;
      this.rowServiceItemGroup = args.rowData.serviceItemGroup;
      this.tempUnit = this.getUnit(args.rowData.costType);
      if (args.rowData.costType === CostTypes.material || args.rowData.costType === CostTypes.otherCost) {
        if (this.skipBeginEditEvent) {
          this.skipBeginEditEvent = false;
        }
        this.getItemList(args.rowData);

      }
      this.setItemCodeValidation(args.rowData.costType);
      if (this.assignmentsGrid.isEdit) {
        args.cancel = true;
        this.commonService.showNotification('warning', 'Assignment is in edit mode! ');
      } else {
        if (args.rowData && args.rowData.costType === 'Material') {
          !this.isIsc && !this.isIscManager && this.data.mode === 'Actual' && this.checkOnHandQuantity(args.rowData.itemCode, args.rowData.qty, args.rowData.issuedQty, args.rowData);
          this.mandatoryItem = true;
        } else {
          this.assignmentDetailsGrid.getColumnByField('qty').validationRules = { required: true };
          this.mandatoryItem = false;
        }
      }
      if (this.transactionsReadOnly) {
        args.cancel = true;
        this.commonService.showPermissionNotification();
      }
      this.setTempValuesForTransaction(args.rowData);
    } else if (args.requestType === 'delete') {
      args.cancel = true;
      if (this.transactionsReadOnly) {
        this.commonService.showPermissionNotification();
      } else if (this.isEditable) {
        this.deleteTransaction(args.data[0]);
      }
    }
  }

  setAssignmentRow(assignment) {
    const index = (this.assignmentsGrid.dataSource as any)?.filter(row => !row.isDeleted).length;
    const currentTime = this.checkinTime ? new Date(this.checkinTime) : null;
    const rowId = this.getMaxRowId(this.assignmentsGrid.dataSource) + 1;
    assignment.rowId = rowId;
    assignment.assignmentId = 0;
    assignment.activityId = rowId * 10;
    assignment.tempId = this.getTempId();
    assignment.description = "";
    assignment.serviceTypeCode = "";
    assignment.serviceTypeDescription = "";
    assignment.referenceActivityCode = "";
    assignment.referenceActivityDescription = "";
    assignment.reportedProblem = "";
    assignment.reportedProblemDescription = "";
    assignment.solutionCode = "";
    assignment.solutionDescription = "";
    assignment.priority = this.priorities?.find(priority => priority.text === 'Manual Action' || priority.text === 'Low')?.value;
    assignment.startDate = currentTime;
    assignment.endDate = null;
    assignment.isDeleted = false;
    return assignment;
  }

  getTempId() {
    const assignments = this.assignments.filter(assignment => !assignment.isDeleted);
    if (assignments.length) {
      let tempId = 1;
      assignments.forEach(assignment => { // sqltodo
        if (!isNaN(assignment.tempId) && +assignment.tempId > +tempId) {
          tempId = +assignment.tempId;
        }
      });
      return tempId + 1;
    } else {
      return 1;
    }
  }

  initAssignmentDetailsGrid() {
    const laborFieldHeader = this.USER.company === Companies.kingslynn ? 'Labour Type' : 'Labor Type';
    this.assignmentDetailGrid = {
      toolbar: [],
      filterSettings: { type: 'Menu' },
      columns: [
        { field: 'rowId', type: 'number', isPrimaryKey: true, showInColumnChooser: false, visible: false },
        { field: 'assignmentDetailId', headerText: 'ID', width: 140, textAlign: 'Left', visible: false, showInColumnChooser: false },
        {
          field: 'assignmentId', headerText: 'Reference Activity', width: 148, filter: { type: 'Excel' }, textAlign: 'Left', validationRules: { required: true },
          foreignKeyValue: 'referenceActivityDescription', foreignKeyField: 'tempId', showInColumnChooser: false, dataSource: this.assignments, edit: this.editAssignment()
        },
        { field: 'referenceActivityCode', headerText: 'Reference Activity', width: 184, filter: { type: 'Excel' }, textAlign: 'Left', visible: false, showInColumnChooser: false, validationRules: { required: true } },
        { field: 'referenceActivityDescription', headerText: 'Term Description', clipMode: 'EllipsisWithTooltip', visible: false, showInColumnChooser: false },
        {
          field: 'costType', headerText: 'Cost Type', width: 137, textAlign: 'Left', filter: { type: 'Excel' }, showInColumnChooser: false,
          foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: this.costTypes, edit: this.editCostType(), validationRules: { required: true }
        },
        { field: 'itemCode', headerText: 'Item', width: 175, textAlign: 'Left', filter: { type: 'Excel' }, showInColumnChooser: false, edit: this.editItem(), validationRules: { required: true } },
        { field: 'itemDescription', headerText: 'Description', width: 195, type: 'string', textAlign: 'Left', edit: this.editItemDescription(), showInColumnChooser: false },
        { field: 'estimateQty', headerText: 'Estimated Qty', textAlign: 'Right', width: 130, format: 'n2', showInColumnChooser: false, visible: this.data.mode === ModeList.actual && this.data.estimateApproved, type: 'number', allowEditing: false },
        { field: 'qty', headerText: 'Quantity', textAlign: 'Right', type: 'number', showInColumnChooser: false, width: 130, format: 'n2', step: 0.1, decimals: 2, validateDecimalOnType: true, edit: this.editQuantity(), validationRules: { required: true } },
        { field: 'price', headerText: 'Price', textAlign: 'Right', type: 'number', showInColumnChooser: false, width: 110, format: 'n2', edit: this.editPrice(), validationRules: { required: true } },
        { field: 'discountAmount', headerText: 'Discount Amount', type: 'number', width: 160, showInColumnChooser: false, textAlign: 'Right', format: 'n2', edit: this.editDiscountAmount() },
        { field: 'warrantyCoverage', headerText: 'Warranty Amount', type: 'number', width: 160, format: 'n2', textAlign: 'Right', allowEditing: false, visible: false, edit: this.editWarrantyAmount() },
        { field: 'contractCoverage', headerText: 'Contract Amount', type: 'number', width: 160, format: 'n2', textAlign: 'Right', allowEditing: false, visible: false, edit: this.editContractAmount() },
        { field: 'totalAmount', headerText: 'Total Amount', width: 160, type: 'number', textAlign: 'Right', showInColumnChooser: false, format: 'n2', edit: this.editTotalAmount() },
        {
          field: 'unit', headerText: 'Unit', textAlign: 'Left', type: 'string', width: 150, visible: false, filter: { type: 'Excel' },
          foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: this.units, edit: this.editUnit()
        },
        { field: 'laborType', headerText: laborFieldHeader, width: 145, type: 'string', textAlign: 'Left', allowEditing: false, edit: this.editLaborType(), visible: false, filter: { type: 'Excel' }, },
        { field: 'warehouse', headerText: 'Warehouse', width: 145, type: 'string', textAlign: 'Left', allowEditing: false, edit: this.editTransactionWarehouse(), visible: false, filter: { type: 'Excel' }, },
        {
          field: 'returnReasonCode', headerText: 'Return Reason', width: 150, type: 'string', textAlign: 'Left',
          foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: this.returnReasons, edit: this.editReturnReason(), visible: false, showInColumnChooser: false
        },
        { field: 'task', headerText: 'Task', type: 'string', width: 145, textAlign: 'Left', edit: this.editTask(), visible: false, filter: { type: 'Excel' }, },
        { field: 'startDate', headerText: 'Start Time', type: 'date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y h:mm a' }, width: 250, edit: this.editStartTime(), visible: false },
        { field: 'endDate', headerText: 'End Time', type: 'date', textAlign: 'Left', format: { type: 'date', format: 'd MMM, y h:mm a' }, width: 250, edit: this.editEndTime(), visible: false },

        { field: 'costComponent', headerText: 'Cost Component', width: 145, type: 'string', textAlign: 'Left', allowEditing: false, edit: this.editCostComponent(), visible: false, filter: { type: 'Excel' }, },
        { field: 'taxpercent', headerText: 'Tax %', width: 160, format: 'n3', type: 'string', textAlign: 'Left', allowEditing: false, edit: this.editTaxPercent(), visible: false },
        { field: 'taxAmount', headerText: 'Tax Amount', width: 160, format: 'n3', type: 'string', textAlign: 'Right', allowEditing: false, edit: this.editTax(), visible: false, filter: { type: 'Excel' }, },
        { field: 'customerCurrency', headerText: 'Currency', type: 'string', width: 160, textAlign: 'Left', allowEditing: false, visible: false, filter: { type: 'Excel' }, },

        {
          field: 'Actions', headerText: 'Actions', showInColumnChooser: false,  textAlign: 'Center', width: 140, allowFiltering: false, allowSorting: false,
          commands: [
            {
              title: 'Create Parts Order',
              type: 'Edit',
              buttonOption: {
                iconCss: `e-btn-icon fas fa-cubes e-icons`,
                cssClass: `e-flat action-button`
              }
            },
            {
              title: 'Edit Assignment Detail',
              type: 'Edit',
              buttonOption: {
                iconCss: `fas fa-pencil-alt`,
                cssClass: `e-flat action-button`
              }
            },
            {
              title: 'Delete Detail',
              type: 'Delete',
              buttonOption: {
                iconCss: `fas fa-trash`,
                cssClass: `e-flat action-button`
              }
            },
          ]
        }
      ]
    };

    if (this.USER.company === Companies.kingslynn) {
      const commentIndex = this.assignmentDetailGrid.columns.findIndex(col => col.field === 'discountAmount') + 1;
      this.assignmentDetailGrid.columns.splice(commentIndex, 0, { field: 'comments', headerText: 'Comments', width: 150, textAlign: 'Left', filter: { type: 'Excel' }, showInColumnChooser: false, allowEditing: true, visible: true, validationRules: { required: false } });
    } else {
      const commentIndex = this.assignmentDetailGrid.columns.findIndex(col => col.field === 'costType') + 1;
      this.assignmentDetailGrid.columns.splice(commentIndex, 0, { field: 'comments', headerText: 'Comments', width: 150, textAlign: 'Left', filter: { type: 'Excel' }, allowEditing: true, visible: false, validationRules: { required: false } });
    }

    const commentIndex = this.assignmentDetailGrid.columns.findIndex(detail => detail.field === 'comments');
    if (this.isIsc || this.isIscManager) {
      const commentsColumn = (this.assignmentDetailGrid.columns as any)?.find(col => col.field === 'comments');
      commentsColumn.visible = true;
      commentsColumn.showInColumnChooser = false;
      const subCostType = {
        field: 'subCostType', headerText: 'Subcost Type', width: 137, textAlign: 'Left', filter: { type: 'Excel' }, showInColumnChooser: false,
        foreignKeyValue: 'text', foreignKeyField: 'value', dataSource: SubCostTypeList, edit: this.editSubCostType()
      }
      this.assignmentDetailGrid.columns.splice(commentIndex, 0, subCostType);
    }
  }

  getMaxRowId(datasource) {
    if (datasource.length) {
      return Math.max.apply(Math, datasource.map(row => row.rowId)) || 0;
    } else {
      return 0;
    }
  }

  setAssignmentDetailRow(detail) {
    let costType = this.USER.company === Companies.kingslynn ?
      this.costTypes.find(type => type.value === CostTypes.otherCost)
      : this.costTypes.find(type => type.value === CostTypes.material);
    const currentTime = this.checkinTime ? new Date(this.checkinTime) : null;
    detail.rowId = this.getMaxRowId(this.assignmentDetailsGrid.dataSource) + 1;
    detail.assignmentId = '';
    detail.assignmentDetailId = '';
    detail.activityId = null;
    detail.comments = '';
    detail.referenceActivityDescription = '';
    detail.referenceActivityCode = '';
    detail.serviceTypeCode = '';
    detail.serviceTypeDescription = '';
    detail.costType = costType ? costType.value : this.costTypes[0].value;
    detail.subCostType = '';
    detail.itemCode = null;
    detail.itemDescription = this.getItemDescription(detail.costType);
    detail.customerCurrency = this.customerCurrency;
    detail.qty = 1;
    detail.price = detail.costType === CostTypes.labor ? this.laborRate : 0;
    detail.totalAmount = 0;
    detail.unit = this.getUnit(detail.costType);
    this.unitCode = detail.unit;
    detail.startDate = currentTime;
    this.assignmentDetailStartTimeValue = currentTime;
    detail.endDate = null;
    detail.task = this.getTask(detail.costType);
    detail.laborType = this.getLaborType(detail.costType);
    detail.warehouse = this.getWarehouse(detail.costType);
    detail.costComponent = this.getCostComponent(detail.costType);
    detail.discountAmount = 0;
    detail.warrantyCoverage = 0;
    detail.contractCoverage = 0;
    detail.taxpercent = this.getTaxRate(detail.costType);
    detail.taxAmount = 0;
    detail.returnReasonCode = null;
    detail.serviceItem = null;
    detail.isDeleted = false;
    detail.warehouse = this.data?.technicianWarehouse;
    return detail;
  }

  showEstimatedQtyCol(showColumn) {
    this.assignmentDetailsGrid.getColumnByField('estimateQty').visible = showColumn;
  }

  getItemDescription(costType, costTypeChanged = false) {
    if (costType === CostTypes.labor) {
      return "Labor charge";
    } else if (costType === CostTypes.material || !costType) {
      return null;
    } else if (costType === CostTypes.other) {
      return "Travel call-out charge";
    } else if (costType === CostTypes.otherCost) {
      return costTypeChanged ? null : this.itemDescriptionObject && this.itemDescriptionObject && this.itemDescriptionObject.value ? this.itemDescriptionObject.value : null;
    }
  }

  getTaxRate(costType) {
    if (this.taxRates) {
      const laborTypeKeys = {
        [CostTypes.labor]: 'LABMOBILE',
        [CostTypes.material]: 'MATERIAL',
        [CostTypes.other]: 'TRAVEL',
        [CostTypes.otherCost]: 'OTHER'
      }
      let taxRateItem = this.taxRates.find(taxRate => taxRate.productCategory === laborTypeKeys[costType]);
      if (taxRateItem && taxRateItem.taxRatePercentage) {
        return taxRateItem.taxRatePercentage;
      } else {
        return 0
      }
    } else {
      return 0;
    }
  }

  getCostComponent(costType: string) {
    if (costType === CostTypes.labor) {
      return this.commonService.userDefaults && this.commonService.userDefaults.lbrCCMP ? this.commonService.userDefaults.lbrCCMP : null;
    } else if (costType === CostTypes.material) {
      return this.commonService.userDefaults && this.commonService.userDefaults.mtlCCMP ? this.commonService.userDefaults.mtlCCMP : null;
    } else if (costType === CostTypes.other || costType === CostTypes.otherCost) {
      return this.USER.company === Companies.kingslynn ? 
              this.commonService.userDefaults && this.commonService.userDefaults.othCCMP ? this.commonService.userDefaults.othCCMP : null 
              : this.commonService.userDefaults && this.commonService.userDefaults.traCallCCMP ? this.commonService.userDefaults.traCallCCMP : null;
    }
  }

  getWarehouse(costType: string) {
    if (costType === CostTypes.material) {
      return this.warehouse;
    } else {
      return null;
    }
  }

  getLaborType(costType: string) {
    if (costType === CostTypes.labor) {
      return this.commonService.userDefaults && this.commonService.userDefaults.lbrCHLT ? this.commonService.userDefaults.lbrCHLT : null;
    } else {
      return null;
    }
  }

  getWarrantyAmount(row?) { //row: if called from calculateAmounts()
    if (row) {
      return row.warrantyCoverage;
    } else {
      let warrantyPercent = 0, warrantyCoverageIndex;
      let costType = this.costTypeObject?.value && (this.costTypeObject?.value as string)?.toLowerCase(); // travel
      if (this.costTypeObject && this.costTypeObject.value === CostTypes.other) {
        costType = 'travel';
      }
      if (this.costTypeObject && this.costTypeObject.value === CostTypes.otherCost) {
        costType = 'other'; // for kingslynn contract and warranty, we will get 'other' in the API getContractAndWarranty
      }
      warrantyCoverageIndex = this.equipmentInfoData?.warrantyDescriptionObj?.findIndex(war => war.costType.toLowerCase() === costType || war.costType === 'all');
      if (warrantyCoverageIndex > -1) {
        warrantyPercent = this.equipmentInfoData.warrantyDescriptionObj[warrantyCoverageIndex].percentageCoverage;
      }
      let totalPrice = (this.quantityObject.value * this.priceObject.value) - this.discountAmountObject.value;
      return totalPrice > 0 ? +(totalPrice * warrantyPercent / 100)?.toFixed(2) : 0;
    }
  }

  getContractAmount(row?) { //row: if called from calculateAmounts()
    if (row) {
      return row.contractCoverage;
    } else {
      let contractPercent = 0, contractCoverageIndex;
      let costType = this.costTypeObject.value && (this.costTypeObject.value as string).toLowerCase();
      if (this.costTypeObject && this.costTypeObject.value === CostTypes.other) {
        costType = 'travel';
      }
      if (this.costTypeObject && this.costTypeObject.value === CostTypes.otherCost) {
        costType = 'other'; // for kingslynn contract and warranty, we will get 'other' in the API getContractAndWarranty
      }
      contractCoverageIndex = this.equipmentInfoData?.serviceContractDescriptionObj?.findIndex(contract => contract.costType.toLowerCase() === costType || contract.costType === 'all');
      if (contractCoverageIndex > -1) {
        contractPercent = this.equipmentInfoData?.serviceContractDescriptionObj[contractCoverageIndex].percentageCoverage;
      }

      let totalPrice = (this.quantityObject.value * this.priceObject.value) - this.discountAmountObject.value;
      return totalPrice > 0 ? +(totalPrice * contractPercent / 100)?.toFixed(2) : 0;
    }
  }

  getUnit(costType: string) {
    if (costType === CostTypes.labor) {
      return this.units.find(unit => unit.description === 'Hours').value;
    } else if (costType === CostTypes.material || costType === CostTypes.other || costType === CostTypes.otherCost) {
      const unit = this.units.find(unit => unit.description === 'Each');
      return unit ? unit.value : this.units[0].value;
    } else {
      return null;
    }
  }

  getTask(costType: string) {
    if (costType === CostTypes.labor) {
      return this.commonService.userDefaults && this.commonService.userDefaults.lbrCTSK ? this.commonService.userDefaults.lbrCTSK : null;
    } else {
      return null;
    }
  }

  addAssignment() {
    this.assignmentsGrid.allowReordering = false;
    this.assignmentsGrid.allowSorting = false;
    this.assignmentsGrid.allowFiltering = false;
    this.serviceOrderService.popupLoader.next(true);
    let newRow = this.setAssignmentRow({});
    (this.assignmentsGrid.dataSource as any).push(newRow);
    this.assignmentsGrid.refresh();
    setTimeout(() => {
      const index = this.assignmentsGrid.getRowIndexByPrimaryKey(newRow.rowId);
      this.assignmentsGrid.selectRow(index);
      this.assignmentsGrid.startEdit();
      this.serviceOrderService.popupLoader.next(false);
    }, 600);
  }

  onRowDataBound(args: any) {
    const data = args.data;
    const $row = args.row,
      actionButtons = $row.querySelectorAll('.action-button');
    if (this.isEditable && this.data.offlineOrder) {
      this.enableAssignmentsActionButton(actionButtons);
    } else {
      if (this.USER.company === Companies.kingslynn && args.data && args.data.rowId > 1) {
        this.enableAssignmentsActionButton(actionButtons);
      } else {
        if (this.isEditable && (!args.data.assignmentId || !args.data.activityIdLN || args.data.activityIdLN && args.data.activityIdLN !== '10')) {
          this.enableAssignmentsActionButton(actionButtons);
        } else {
          actionButtons[0].classList.add('disabled-grid-button');
          if (actionButtons[1]) {
            actionButtons[1].classList.add('disabled-grid-button');
          }
        }
      }
    }
  }

  enableAssignmentsActionButton(actionButtons) {
    actionButtons[0].classList.remove('disabled-grid-button');
    if (actionButtons[1]) {
      actionButtons[1].classList.remove('disabled-grid-button');
    }
  }

  onTransactionRowDataBound(args: any) {
    const data = args.data;
    const $row = args.row, actionButtons = $row.querySelectorAll('.action-button');
    if (args.data.costType === 'Material') {
      actionButtons[0].style.visibility = 'visible';
    } else {
      actionButtons[0].style.visibility = 'hidden';
    }
    if (this.isEditable) {
      actionButtons[0].classList.remove('disabled-grid-button');
      if (actionButtons[1]) {
        actionButtons[1].classList.remove('disabled-grid-button');
      }
      if (actionButtons[2]) {
        actionButtons[2].classList.remove('disabled-grid-button');
      }
    } else {
      actionButtons[0].classList.add('disabled-grid-button');
      if (actionButtons[1]) {
        actionButtons[1].classList.add('disabled-grid-button');
      }
      if (actionButtons[2]) {
        actionButtons[2].classList.add('disabled-grid-button');
      }
    }
  }

  onAssignmentsToolbarClick(args: any) {
    if (args.item.id === 'add-new-assignment') {
      this.assignmentsGrid.endEdit();
      if (this.assignmentsGrid.isEdit) {
        this.commonService.showNotification('warning', 'There is a row in assignment grid, which is already in edit mode!', 'center', 5000);
      } else {
        if (this.assignmentsReadOnly) {
          this.commonService.showPermissionNotification();
        } else {
          this.assignmentDetailsGrid.endEdit();
          if (this.assignmentDetailsGrid.isEdit) {
            this.commonService.showNotification('warning', 'Can not add assignment if trasaction is being edited!', 'center', 5000);
          } else {
            this.addAssignment();
          }
        }
      }
    } else if (args.item.id === 'column-chooser') {
      this.showAssignmentsColumnChooser = !this.showAssignmentsColumnChooser;
    } else if (args.item.id === 'clear-filter') {
      this.assignmentsGrid.clearFiltering();
      this.assignmentsGrid.search('');
    } else if (args.item.id === 'excel-export') {
      const dataSource = getExcelDataSource(this.assignmentsGrid);
      let excelExportProperties: ExcelExportProperties = {
        dataSource,
        hierarchyExportMode: 'Expanded',
        theme: {
          header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
        },
        fileName: `Assignments (${getCurrentDate()}).xlsx`
      };
      this.assignmentsGrid.excelExport(excelExportProperties);
    }
  }

  onAssignmentCommandClick(args: any) {
    if (args.commandColumn.title === 'Delete Assignment') {
      if (this.assignmentsReadOnly) {
        this.commonService.showPermissionNotification();
      } else {
        this.deleteAssignment(args.rowData, this.assignmentsGrid);
      }
    } else if (args.commandColumn.title === 'Edit Assignment') {
      if (this.assignmentsReadOnly) {
        this.commonService.showPermissionNotification();
      } else {
        if (!this.assignmentDetailsGrid.isEdit && !this.assignmentsGrid.isEdit) {
          args.rowData['callGroup'] = this.data.callGroup;
          this.openEditAssignmentPopup(args.rowData);
        } else {
          this.commonService.showNotification('warning', 'Assignment or Transaction is in edit mode!');
        }
      }
    }
  }

  openEditAssignmentPopup(rowData) {
    this.selectedAssignment = rowData;
    this.showAssignmentsPopup = true;
  }

  addAssignmentDetail() {
    this.assignmentDetailsGrid.allowReordering = false;
    this.assignmentDetailsGrid.allowSorting = false;
    this.assignmentDetailsGrid.allowFiltering = false;
    this.serviceOrderService.popupLoader.next(true);
    let newRow = this.setAssignmentDetailRow({});
    (this.assignmentDetailsGrid.dataSource as any).push(newRow);
    // this.addTransactionRow = newRow.rowId;
    this.assignmentDetailsGrid.refresh();
    this.serviceOrderService.hasTransaction.next(true);
    setTimeout(() => {
      const index = this.assignmentDetailsGrid.getRowIndexByPrimaryKey(newRow.rowId);
      const onlyOneAssignment = (this.assignmentsGrid.dataSource as any).filter(x => !x.isDeleted).length === 1;
      this.defaultReferenceActivity = onlyOneAssignment && (this.assignmentsGrid.dataSource as any).find(x => !x.isDeleted) || null;
      this.assignmentDetailsGrid.selectRow(index);
      this.assignmentDetailsGrid.startEdit();
      this.serviceOrderService.popupLoader.next(false);
    }, 600);
  }

  onAssignmentDetailsToolbarClick(args) {
    if (args.item.id === 'add-new-assignment-detail') {
      this.assignmentDetailsGrid.endEdit();
      if (!this.assignmentDetailsGrid.isEdit) {
        if (this.assignments && this.assignments.length) {
          if (this.transactionsReadOnly) {
            this.commonService.showPermissionNotification();
          } else {
            this.assignmentsGrid.endEdit();
            if (!this.assignmentsGrid.isEdit) {
              this.addAssignmentDetail();
            }
          }
        } else {
          this.commonService.showNotification('warning', 'Please add an assignment first to add transaction!', 'center', 5000);
        }
      } else {
        this.commonService.showNotification('warning', 'There is a row in transaction grid, which is already in edit mode!', 'center', 5000);
      }
    } else if (args.item.id === 'column-chooser-assignment-detail') {
      this.showAssignmentDetailsColumnChooser = !this.showAssignmentDetailsColumnChooser;
    } else if (args.item.id === 'clear-filter') {
      this.assignmentDetailsGrid.clearFiltering();
      this.assignmentDetailsGrid.search('');
      this.commonService.saveUserPreference({ userId: this.USER.userId, name: gridNames.serviceOrderTransactionsGrid, value: '' });
      localStorage.setItem('grid' + gridNames.serviceOrderTransactionsGrid, '');
      this.assignmentDetailGrid.refresh();
    } else if (args.item.id === 'excel-export') {
      const dataSource = getExcelDataSource(this.assignmentDetailsGrid);
      let excelExportProperties: ExcelExportProperties = {
        dataSource,
        hierarchyExportMode: 'Expanded',
        theme: {
          header: { bold: true, backColor: '#eeeeee', fontSize: 15 }
        },
        fileName: `Transactions (${getCurrentDate()}).xlsx`
      };
      this.assignmentDetailsGrid.excelExport(excelExportProperties);
    }
  }

  onAssignmentDetailsCommandClick(args: any) {
    if (args.commandColumn.title === 'Create Parts Order') {
      this.commonService.getItems(args.rowData.itemCode, 30, this.USER.company)
        .subscribe((res: ApiResponse) => {
          if (res.isSuccessful) {
            if (this.isIsc || this.isIscManager) {
              this.fromExecutionTab = [args.rowData];
              if (res?.result[0]?.status === PartsAvailabilityStatus.inActive) {
                this.commonService.showNotification('warning', '\nItem ' + res?.result[0]?.item + res?.result[0]?.itemDescription + ' is obsolete');
              } else {
                this.showCartCheckoutPopup = true;
              }
            }
            else {
              if (res?.result[0]?.status === PartsAvailabilityStatus.inActive) {
                this.commonService.showNotification('error', '\nItem ' + res?.result[0]?.item + '-' + res?.result[0]?.itemDescription + ' is obsolete');
                if (res?.result[0]?.alternateItem !== null) {
                  this.commonService.showConfirmation('\nItem ' + res?.result[0]?.item + '-' + res?.result[0]?.itemDescription + ' is obsolete.' + '\n\n Would you like to add the alternate item ' + res?.result[0]?.alternateItem)
                    .then(result => {
                      if (result.isConfirmed) {
                        // this.commonService.getItems(res?.result[0]?.alternateItem, 30, this.USER.company)
                        this.commonService.getAlternateItemData(res?.result[0]?.alternateItem)
                          .subscribe((res2: ApiResponse) => {
                            this.showPartsOrderPopup.emit({
                              update: false,
                              itemCode: res2?.result?.item,
                              itemDescription: res2?.result?.itemDescription,
                              itemGroup: res2?.result?.itemGroup,
                              serviceItemGroup: res2?.result?.serviceItemGroup,
                              serviceOrderId: this.data.serviceOrderId,
                              warehouse: args.rowData.warehouse
                            });
                          })
                      }
                      else {
                        return;
                      }
                    })
                }
                else { // if item is obsolete but alternateItem is null 
                  this.commonService.showNotification('error', '\nItem ' + res?.result[0]?.item + '-' + res?.result[0]?.itemDescription + ' is obsolete');
                  args.cancel = true;
                  return;
                }
              } else {
                if (this.data.serviceOrderId && args.rowData.itemCode) {
                  this.serviceOrderService.popupLoader.next(true);
                  this.inventoryService.GetOpenPartsOrderByServiceOrderId(this.data.serviceOrderId)
                    .subscribe((res: ApiResponse) => {
                      this.serviceOrderService.popupLoader.next(false);
                      if (res.isSuccessful && res.result.length) {
                        const data = res.result[0];
                        if (data.itemList.findIndex(x => x.itemCode === args.rowData.itemCode) === -1) {
                            data.itemList?.push({
                                itemCode: args.rowData.itemCode,
                                itemDescription: args.rowData.itemDescription,
                                itemGroup: args.rowData.itemGroup,
                                serviceItemGroup: args.rowData.serviceItemGroup
                            });
                        }
                        this.showPartsOrderPopup.emit({ data, update: true, serviceOrderId: this.data.serviceOrderId });
                    } else {
                        this.showPartsOrderPopup.emit({
                            update: false,
                            itemCode: args.rowData.itemCode,
                            itemDescription: args.rowData.itemDescription,
                            itemGroup: args.rowData.itemGroup,
                            serviceItemGroup: args.rowData.serviceItemGroup,
                            serviceOrderId: this.data.serviceOrderId,
                            warehouse: args.rowData.warehouse
                        });
                      }
                    });
                }
                else {
                  let warning = !this.data.serviceOrderId && 'Please save the service order before creating a parts order!' || !args.rowData.itemCode && 'Select an item for this transaction first!';
                  this.commonService.showNotification('warning', warning);
                }
              }
            }
          }
          else {
            this.commonService.showNotification('warning', res.message)
          }
        });

    } else if (args.commandColumn.title === 'Edit Assignment Detail') {
        args.cancel = true;
        if (this.transactionsReadOnly) {
            this.commonService.showPermissionNotification();
        } else {
            if (!this.assignmentDetailsGrid.isEdit && !this.assignmentsGrid.isEdit) {
                this.showAssignmentDetailsPopup = true;
                this.selectedAssignmentDetail = args.rowData;
            } else {
                this.commonService.showNotification('warning', 'Assignment or Transaction is in edit mode!');
            }
        }
    } else if (args.commandColumn.title === 'Delete Detail') {
        this.assignmentDetailAction = 'Delete';
        if (this.transactionsReadOnly) {
            this.commonService.showPermissionNotification();
        } else {
            this.deleteTransaction(args.rowData);
        }
    }
  }

  onAssignmentGridActionComplete(args, grid) {
    updateFilterIcon(args, grid);
    if (args.requestType === 'save') {
      this.assignmentsGrid.allowReordering = true;
      this.assignmentsGrid.allowSorting = true;
      this.assignmentsGrid.allowFiltering = true;

      this.calculateAmounts(false);
      this.isTabValid = true;
      this.resetTempValuesForAssignment();
      this.refreshGrids();
    } else if (args.requestType === 'cancel') {
      this.assignmentsGrid.allowReordering = true;
      this.assignmentsGrid.allowSorting = true;
      this.assignmentsGrid.allowFiltering = true;
      if (!args.rowData.assignmentId) {
        const index = grid.dataSource.findIndex(row => row.activityId === args.data.activityId);
        (grid.dataSource as any).splice(index, 1);
        grid.refresh();
      }
    } else if (args.requestType === 'refresh') {
      if (this.addAssignmentRow != null) {
        const index = grid.getRowIndexByPrimaryKey(this.addAssignmentRow);
        grid.selectRow(index);
      }
    }
    this.enableDisableAddAssignmentForKingslynn();
  }

  onAssignmentSelected(args) {
    if (this.addAssignmentRow != null) {
      setTimeout(() => {
        this.assignmentsGrid.startEdit();
      }, 100);
      this.addAssignmentRow = null;
    }
  }

  onTransactionSelected(args) {
    if (this.addTransactionRow != null) {
      setTimeout(() => {
        this.assignmentDetailsGrid.startEdit();
      }, 200);
      this.addTransactionRow = null;
    }
  }

  enableDisableAddAssignmentForKingslynn() {
    if (this.USER.company === Companies.kingslynn) {
      if (this.checkBillable()) {
        document.getElementsByClassName('grid-add-button')[0].classList.add('disabled-grid-button');
      } else {
        document.getElementsByClassName('grid-add-button')[0].classList.remove('disabled-grid-button');
      }
    }
  }

  checkBillable() {
    return this.assignments.length && this.assignments.find(assignment => this.getRefActivityCategory(assignment.referenceActivityCode) === 'Billable Service');
  }

  resetTempValuesForAssignment() {
    this.referenceActivityDescription = '';
    this.serviceTypeCode = '';
    this.reportedProblemDescription = '';
    this.solutionDescription = '';
  }

  onTransactionGridActionComplete(args, grid) {
    updateFilterIcon(args, grid);
    if (GridPersistRequests.includes(args.requestType)) {
      const persistenceData: PersistenceData = {
        userId: this.USER.userId,
        name: gridNames.serviceOrderTransactionsGrid,
        value: grid.getPersistData()
      }
      this.commonService.saveUserPreference(persistenceData);
      localStorage.setItem(`grid${this.transactionGridName}`, grid.getPersistData());
    }

    if (args.requestType === 'save') {
      delete this.assignmentDetailsGrid.getColumnByField('qty').validationRules['max'];
      this.assignmentDetailsGrid.allowReordering = true;
      this.assignmentDetailsGrid.allowSorting = true;
      this.assignmentDetailsGrid.allowFiltering = true;

      const rowData = grid.dataSource.find(row => row.rowId === args.data.rowId);
      rowData.assignmentId = +(this.assignmentId ?? rowData.assignmentId);
      rowData.activityId = this.activityId ?? rowData.activityId;

      if (!this.assignmentDetailsGrid.getColumnByField('unit').visible) {
        rowData.unit = this.getUnit(rowData.costType);
      }

      if (!this.assignmentDetailsGrid.getColumnByField('warrantyCoverage').visible) {
        rowData.warrantyCoverage = this.isKingslynn && this.rowServiceItemGroup === '250SVC' ? 0 : this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Warranty Service' && this.getWarrantyAmount() || 0;
      }

      if (!this.assignmentDetailsGrid.getColumnByField('contractCoverage').visible) {
        rowData.contractCoverage = this.isKingslynn && this.rowServiceItemGroup === '250SVC' ? 0 : this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Contract Service' && this.getContractAmount() || 0;
      }

      if (!this.assignmentDetailsGrid.getColumnByField('totalAmount').visible) {
        rowData.totalAmount = this.totalAmountValue || 0;
      }

      if (!this.assignmentDetailsGrid.getColumnByField('taxpercent').visible) {
        rowData.taxpercent = this.getTaxRate(rowData.costType);
      }

      this.taxAmount = +(rowData.totalAmount * (this.getTaxRate(rowData.costType) / 100)).toFixed(2);
      rowData.taxAmount = this.taxAmount || 0;

      if (!this.assignmentDetailsGrid.getColumnByField('costComponent').visible) {
        rowData.costComponent = this.getCostComponent(rowData.costType);
      }

      if (!this.assignmentDetailsGrid.getColumnByField('warehouse').visible) {
        rowData.warehouse = this.getWarehouse(rowData.costType);
      }

      if (!this.assignmentDetailsGrid.getColumnByField('laborType').visible) {
        rowData.laborType = this.getLaborType(rowData.costType);
      }

      if (!this.assignmentDetailsGrid.getColumnByField('task').visible) {
        rowData.task = this.getTask(rowData.costType);
      }

      if (!this.assignmentDetailsGrid.getColumnByField('startDate').visible) {
        rowData.startDate = rowData.startDate ? rowData.startDate : this.assignmentDetailStartTimeValue;
      }

      if (!this.assignmentDetailsGrid.getColumnByField('customerCurrency').visible) {
        rowData.customerCurrency = this.customerCurrency;
      }
      rowData.assignmentDetailId = this.assignmentDetailId || rowData.assignmentDetailId;
      rowData.serviceTypeDescription = this.assignmentDetailServiceTypDescription || rowData.serviceTypeDescription;
      rowData.itemGroup = this.itemGroup || rowData.itemGroup;
      rowData.serviceItemGroup = this.serviceItemGroup;
      rowData.referenceActivityCode = this.assignmentDetailReferenceActivityCode || rowData.referenceActivityCode;
      rowData.referenceActivityDescription = this.assignmentDetailReferenceActivityDescription || rowData.referenceActivityDescription;
      rowData.serviceTypeCode = this.assignmentDetailServiceTypeCode || rowData.serviceTypeCode;
      // rowData.estQ
      rowData.transactionDescription = rowData.description;
      rowData.isDeleted = false;
      rowData.discountAmount = rowData.discountAmount ?? 0;
      rowData.iscDiscountAmount = rowData.iscDiscountAmount ?? 0;
      this.calculateAmounts(false);
      this.isTabValid = true;
      grid.refreshColumns();
      this.tempUnit = '';
      this.resetTempValuesForTransaction();
      this.serviceItemGroup = '';
      this.rowServiceItemGroup = '';
    } else if (args.requestType === 'cancel') {
      this.assignmentDetailsGrid.allowReordering = true;
      this.assignmentDetailsGrid.allowSorting = true;
      this.assignmentDetailsGrid.allowFiltering = true;
      this.resetTempValuesForTransaction();
      if (!args.rowData.assignmentDetailId) {
        const index = grid.dataSource.findIndex(row => row.rowId === args.data.rowId);
        (grid.dataSource as any).splice(index, 1);
        grid.refresh();
      }
      this.resetTempValuesForTransaction();
    } else if (args.requestType === 'beginEdit') {
      if (args.rowData && args.rowData.referenceActivityDescription) {
        this.USER.company === Companies.conway && this.updateCostTypeDataSource({ itemData: args.rowData }); // we are only using itemData so I am passing an object with itemData to successfully execute this function
      }
      if (this.USER.company === Companies.kingslynn) {
        const anchorElement = document.getElementsByClassName('item-advance-search-icon')[0];
        anchorElement.classList.remove('d-none');
        this.assignmentDetailsGrid.getColumnByField('comments').validationRules['required'] = args.rowData.discountAmount > 0;
        this.discountAmountObject.enabled = this.getRefActivityCategory(args.rowData.referenceActivityCode) === 'Billable Service' || args.rowData.serviceItemGroup === '250SVC';
      } else if (this.USER.company === Companies.conway) {
        if (args.rowData.costType === CostTypes.material) {
          const anchorElement = document.getElementsByClassName('item-advance-search-icon')[0];
          anchorElement.classList.remove('d-none');
        }
      }
      if (this.defaultReferenceActivity) {
        this.assignmentObject.value = this.defaultReferenceActivity.tempId;
        this.defaultReferenceActivity = null;
      }
      this.enableDisableAddAssignmentForKingslynn();
    } else if (args.requestType === 'refresh') {
      if (this.addTransactionRow != null) {
        const index = grid.getRowIndexByPrimaryKey(this.addTransactionRow);
        grid.selectRow(index);
      }
    }
  }

  resetTempValuesForTransaction() {
    this.assignmentDetailServiceTypDescription = this.itemGroup = this.serviceItemGroup = this.rowServiceItemGroup
      = this.assignmentDetailReferenceActivityCode = this.assignmentDetailReferenceActivityDescription
      = this.assignmentDetailServiceTypeCode = this.costTypeObject = this.itemCodeObject = this.itemDescriptionObject
      = this.commentsObject = this.quantityObject = this.priceObject = this.discountAmountObject = this.contractAmountObject
      = this.warrantyAmountObject = this.totalAmountObject = this.returnReasonObject = this.unitObject = this.assignmentObject
      = this.assignmentDetailStartTimeObject = this.assignmentDetailEndTimeObject = this.taskObject = this.taxObject
      = this.taxPercentObject = this.transactionWarehouseObject = this.laborTypeObject = this.assignmentId = this.assignmentDetailId = null;
  }

  onExcelQueryCellInfo(args: any) {
    excelQueryCellInfo(args);
  }

  onAssignmentDoubleClick(args: any) {
    if (this.isEditable) {
      if (this.assignmentsReadOnly) {
        this.commonService.showPermissionNotification();
      } else {
        this.selectedAssignment = args.rowData;
        this.showAssignmentsPopup = true;
      }
    }
  }

  onAssignmentDetailDoubleClick(args: any) {
    if (this.isEditable) {
      if (this.transactionsReadOnly) {
        this.commonService.showPermissionNotification();
      } else {
        this.selectedAssignmentDetail = args.rowData;
        this.showAssignmentDetailsPopup = true;
      }
    }
  }

  saveAssignment(args: any) {
    const index = this.assignments.findIndex(assign => (args.data.assignmentId && assign.assignmentId === args.data.assignmentId) || assign.tempId === args.data.tempId);
    const assignment = {
      ...args.data,
      activityId: args.data.activityId || (this.assignments.length + 1) * 10,
      assignmentId: args.data.assignmentId || 0,
      isDeleted: false
    };
    if (index > -1) {
      this.assignments.splice(index, 1, assignment);
    } else {
      this.assignments.push(assignment);
    }
    if (args.deleteTransactions) {
      this.deleteAssignmentDetailsByAssignment(args.data);
    }
    this.assignmentsGrid.dataSource = this.assignments;
    this.assignmentsGrid.refresh();
    this.emitData();
  }

  saveAssignmentDetail(data: any) {
    this.costComponentValue = data.costComponent;
    data.transactionDescription = data.description;
    data.isDeleted = false;
    if (data.costType !== 'Material') {
      data.itemDescription = this.getItemDescription(data.costType);
    }
    const existingTransactionIndex = this.assignmentDetails.findIndex(tran => (tran.assignmentDetailId && tran.assignmentDetailId === data.assignmentDetailId) || (data.rowId && tran.rowId === data.rowId));
    if (existingTransactionIndex > -1) {
      this.assignmentDetails[existingTransactionIndex] = data;
    } else {
      // data.tempId = this.assignmentDetails.length + 1;
      this.assignmentDetails?.push(data);
    }
    this.assignmentDetailsGrid.dataSource = this.assignmentDetails;
    this.assignmentDetailsGrid.refresh();
    this.calculateAmounts(false);
    // this.emitData();
    this.serviceOrderService.popupLoader.next(false);
  }

  calculateAmounts(isInitialLoad: boolean) {
    this.data.salesAmount = 0;
    this.data.discountAmount = 0;
    this.data.warrantyCoverage = 0;
    this.data.contractCoverage = 0;
    this.data.tax = 0;
    this.assignmentDetails.filter(detail => !detail.isDeleted && detail.qty).forEach(row => {
      this.data.salesAmount += row.qty * row.price;
      this.data.discountAmount += row.discountAmount;
      this.data.warrantyCoverage += row.warrantyCoverage;
      this.data.contractCoverage += row.contractCoverage;
      this.data.tax += row.taxAmount;
    });
    this.data.salesAmount = +this.data.salesAmount?.toFixed(2);
    this.data.discountAmount = +this.data.discountAmount?.toFixed(2);
    this.data.warrantyCoverage = +this.data.warrantyCoverage?.toFixed(2);
    this.data.contractCoverage = +this.data.contractCoverage?.toFixed(2);
    this.data.tax = +this.data.tax?.toFixed(2);

    this.data.totalAmount = this.data.salesAmount - this.data.warrantyCoverage - this.data.contractCoverage - this.data.discountAmount;
    this.data.invoiceAmount = +(this.data.totalAmount + this.data.tax)?.toFixed(2);

    if (!isInitialLoad) {
      this.emitData();
    }
  }

  emitData() {
    this.tabUpdated.emit({
      ...this.data,
      assignments: this.assignments,
      assignmentDetails: this.assignmentDetails
    });
  }

  deleteAssignment(data: any, grid) {
    this.commonService.showConfirmation('Are you sure you want to delete this assignment? Corresponding transactions will also be deleted!')
      .then(result => {
        if (result.isConfirmed) {
          const index = grid.dataSource.findIndex(assgn => assgn.rowId === data.rowId);
          if (index > -1) {
            this.deleteAssignmentDetailsByAssignment(data);
            if (grid.dataSource[index].assignmentId) {
              grid.dataSource[index].isDeleted = true;
            } else {
              grid.dataSource.splice(index, 1);
            }
            this.updateActivityId();
            this.emitData();
            this.enableDisableAddAssignmentForKingslynn();
            grid.refresh();
          }
        }
      });
  }

  deleteAssignmentDetailsByAssignment(assignment: any) {
    const details = [...this.assignmentDetails];
    this.assignmentDetails.forEach((detail, index) => {
      if (detail.assignmentId === assignment.tempId || detail.assignmentId === assignment.assignmentId) {
        if (detail.assignmentDetailId) {
          details[index].isDeleted = true;
        } else {
          details.splice(index, 1);
        }
      }
    });
    this.assignmentDetails = details;
    this.assignmentDetailsGrid.dataSource = this.assignmentDetails;
    this.serviceOrderService.hasTransaction.next(this.assignmentDetails.filter(x => !x.isDeleted).length > 0);
    this.calculateAmounts(false);
    this.assignmentDetailsGrid.refresh();
  }

  updateActivityId() {
    this.assignments.filter(assign => !assign.isDeleted).forEach((row, index) => {
      row.activityId = (index + 1) * 10;
      this.updateDetailsOnAssignmentDeletion(row);
      return row;
    });
  }

  updateDetailsOnAssignmentDeletion(assignment) {
    this.assignmentDetails.map(det => {
      if (assignment.tempId === det.assignmentId) {
        det.activityId = assignment.activityId;
      }
    });
  }

  deleteTransaction(data) {
    this.commonService.showConfirmation('Are you sure you want to delete this transaction?')
      .then(result => {
        if (result.isConfirmed) {
          const index = this.assignmentDetails.findIndex(assgnDet => assgnDet.rowId === data.rowId);
          if (index > -1) {
            if (this.assignmentDetails[index].assignmentDetailId) {
              this.assignmentDetails[index].isDeleted = true;
            } else {
              this.assignmentDetails.splice(index, 1);
            }
            this.serviceOrderService.hasTransaction.next(this.assignmentDetails.filter(x => !x.isDeleted).length > 0);
            this.assignmentDetailsGrid.dataSource = this.assignmentDetails;
            this.assignmentDetailsGrid.refresh();
            this.calculateAmounts(false);
          }
        }
      })
  }

  onClose() {
    //   this.showPartsOrderPopup  = false;
  }

  partsOrderSaved(updatedData) {
  }

  editReferenceActivity() {
    return {
      create: () => {
        this.referenceActivity = document.createElement('input');
        return this.referenceActivity;
      },
      read: () => {
        if (this.referenceActivityObject) {
          return this.referenceActivityObject.value;
        }
      },
      destroy: () => {
        this.referenceActivityObject.destroy();
      },
      write: (args) => {
        const existingRefActivities = [];
        (this.assignmentsGrid.dataSource as any).forEach(assignment => {
          if (assignment.referenceActivityCode !== args.rowData.referenceActivityCode && !assignment.isDeleted) {
            existingRefActivities.push(assignment.referenceActivityCode);
          }
        });
        let refActivitiesDDL = this.updateRefActivityDS(existingRefActivities);
        if (this.USER.company === Companies.kingslynn) {
          refActivitiesDDL = refActivitiesDDL.filter(ref => ref.category === 'Billable Service');
        }
        this.referenceActivityObject = new DropDownList({
          dataSource: refActivitiesDDL,
          fields: { value: 'value', text: 'description' },
          value: args.rowData.referenceActivityCode,
          allowFiltering: true,
          filterType: 'Contains',
          popupWidth: '250px',
          change: event => {
            if (this.hasTransaction(args.rowData) && event.isInteracted) {
              this.commonService.showConfirmation('Are you sure? The transactions added for this assignment will be deleted').then(res => {
                if (res.isConfirmed) {
                  this.setTempValuesForAssignment(event);
                  this.deleteAssignmentDetailsByAssignment(args.rowData);
                } else {
                  this.referenceActivityObject.value = event.previousItemData.value;
                }
              });
            } else {
              this.setTempValuesForAssignment(event);
            }
          }
        });
        this.referenceActivityObject.appendTo(this.referenceActivity);
      }
    }
  }

  setTempValuesForAssignment(event) {
    this.serviceTypeObject.value = event.itemData.serviceTypeDescription;
    this.serviceTypeCode = event.itemData.serviceTypeCode;
    this.referenceActivityDescription = event.itemData.description;
    this.refActivityCategory = event.itemData.category;
  }

  hasTransaction(assignment) {
    const index = (this.assignmentDetailsGrid.dataSource as any).findIndex(ad => ad.assignmentId === assignment.tempId);
    return index > -1;
  }

  updateRefActivityDS(existingRefActivities) {
    return this.referenceActivities.filter(activity => {
      if (activity.description === 'Contract Service' && this.equipmentInfoData) {
        if (this.equipmentInfoData?.serviceContractCode && this.equipmentInfoData.serviceContractDescriptionObj && this.equipmentInfoData.serviceContractDescriptionObj.length) {
          const index = this.equipmentInfoData.serviceContractDescriptionObj.findIndex(contract => new Date(contract.expiryDate) > new Date(this.data.soCreatedDate));
          return index > -1 ? !existingRefActivities.includes(activity.value) : false;
        } else {
          return false;
        }
      } else if (activity.description === 'Warranty Service' && this.equipmentInfoData) {
        if (this.equipmentInfoData.warrantyCode && this.equipmentInfoData.warrantyDescriptionObj && this.equipmentInfoData.warrantyDescriptionObj.length) {
          const index = this.equipmentInfoData.warrantyDescriptionObj.findIndex(warranty => new Date(warranty.expiryDate) > new Date(this.data.soCreatedDate));
          return index > -1 ? !existingRefActivities.includes(activity.value) : false;
        } else {
          return false;
        }
      } else {
        return !existingRefActivities.includes(activity.value);
      }
    });
  }

  editServiceType() {
    return {
      create: () => {
        this.serviceType = document.createElement('input');
        return this.serviceType;
      },
      read: () => {
        if (this.serviceTypeObject) {
          return this.serviceTypeObject.value;
        }
      },
      destroy: () => {
        this.serviceTypeObject.destroy();
      },
      write: (args) => {
        this.serviceTypeObject = new TextBox({
          value: args.rowData.serviceTypeDescription,
          enabled: false
        });
        this.serviceTypeObject.appendTo(this.serviceType);
      }
    }
  }

  editReportedProblem() {
    return {
      create: () => {
        this.reportedProblem = document.createElement('input');
        return this.reportedProblem;
      },
      read: () => {
        if (this.reportedProblemObject) {
          return this.reportedProblemObject.value;
        }
      },
      destroy: () => {
        this.reportedProblemObject.destroy();
      },
      write: (args) => {
        this.reportedProblemObject = new DropDownList({
          dataSource: this.reportedProblems,
          fields: { value: 'value', text: 'description' },
          value: args.rowData.reportedProblem,
          allowFiltering: true,
          filterType: 'Contains',
          popupWidth: '250px',
          change: event => {
            this.reportedProblemDescription = event.itemData.description;
          }
        });
        this.reportedProblemObject.appendTo(this.reportedProblem);
      }
    }
  }

  editSolution() {
    return {
      create: () => {
        this.solution = document.createElement('input');
        return this.solution;
      },
      read: () => {
        if (this.solutionObject) {
          return this.solutionObject.value;
        }
      },
      destroy: () => {
        this.solutionObject.destroy();
      },
      write: (args) => {
        this.solutionObject = new DropDownList({
          dataSource: this.solutions,
          fields: { value: 'value', text: 'text' },
          value: args.rowData.solutionCode,
          allowFiltering: true,
          filterType: 'Contains',
          popupWidth: '250px',
          change: event => {
            this.solutionDescription = event.itemData.text;
          }
        });
        this.solutionObject.appendTo(this.solution);
      }
    }
  }

  editPriority() {
    return {
      create: () => {
        this.priority = document.createElement('input');
        return this.priority;
      },
      read: () => {
        if (this.priorityObject) {
          return this.priorityObject.value;
        }
      },
      destroy: () => {
        this.priorityObject.destroy();
      },
      write: (args) => {
        this.priorityObject = new DropDownList({
          dataSource: this.priorities,
          fields: { value: 'value', text: 'text' },
          value: args.rowData.priority,
          allowFiltering: true,
          filterType: 'Contains',
          popupWidth: '250px',
        });
        this.priorityObject.appendTo(this.priority);
      }
    }
  }

  editStartDate() {
    return {
      create: () => {
        this.startDate = document.createElement('input');
        return this.startDate;
      },
      read: () => {
        if (this.startDateObject) {
          return this.startDateObject.value;
        }
      },
      destroy: () => {
        this.startDateObject.destroy();
      },
      write: (args) => {
        this.startDateObject = new DateTimePicker({
          placeholder: "Select start date time...",
          min: args.rowData.startDate ? args.rowData.startDate : new Date(),
          value: args.rowData.startDate,
          openOnFocus: true,
          allowEdit: false,
          change: ev => {
            this.endDateObject.min = ev.value;
          }
        });
        this.startDateObject.appendTo(this.startDate);
      }
    }
  }

  editEndDate() {
    return {
      create: () => {
        this.endDate = document.createElement('input');
        return this.endDate;
      },
      read: () => {
        if (this.endDateObject) {
          return this.endDateObject.value;
        }
      },
      destroy: () => {
        this.endDateObject.destroy();
      },
      write: (args) => {
        this.endDateObject = new DateTimePicker({
          placeholder: "Select end date time...",
          min: this.startDateObject && this.startDateObject.value ? this.startDateObject.value : args.rowData.startDate || new Date(),
          value: args.rowData.endDate,
          openOnFocus: true,
          allowEdit: false,
          change: ev => {
            if (this.startDateObject) {
              this.startDateObject.max = ev.value;
            }
          }
        });
        this.endDateObject.appendTo(this.endDate);
      }
    }
  }

  editAssignment() {
    return {
      create: () => {
        this.assignment = document.createElement('input');
        return this.assignment;
      },
      read: () => {
        if (this.assignmentObject) {
          return this.assignmentObject.value;
        }
      },
      destroy: () => {
        this.assignmentObject.destroy();
      },
      write: args => {
        this.assignmentObject = new DropDownList({
          dataSource: this.assignments.filter(assignment => !assignment.isDeleted),
          fields: { value: 'tempId', text: 'referenceActivityDescription' },
          value: args.rowData.assignmentId,
          allowFiltering: true,
          filterType: 'Contains',
          popupWidth: '250px',
          itemTemplate: '<span title="${referenceActivityDescription}">${referenceActivityDescription}</span>',
          change: event => {
            this.USER.company === Companies.conway && this.updateCostTypeDataSource(event);
            this.activityId = event.itemData.activityId;
            this.assignmentId = +event.itemData.tempId;
            this.assignmentDetailServiceTypeCode = event.itemData.serviceTypeCode;
            this.assignmentDetailReferenceActivityDescription = event.itemData.referenceActivityDescription;
            this.assignmentDetailReferenceActivityCode = event.itemData.referenceActivityCode;
            this.assignmentDetailServiceTypDescription = event.itemData.serviceTypeDescription;
            if (args?.rowData.assignmentDetailId) { // To reset the row on this change
              this.costTypeObject.value = null;
            }
            if (this.discountAmountObject) {
              this.discountAmountObject.enabled = this.getRefActivityCategory(event.itemData.referenceActivityCode) === 'Billable Service';
            }
            // const linkedAssignmentId = this.assignments.find(x => x.tempId === +event.itemData.tempId).assignmentId;
            if (!event.itemData.assignmentId ) {
              this.assignmentDetailId = 0;
            }
            this.recalculations(args);
          }
        });
        this.assignmentObject.appendTo(this.assignment);
      }
    }
  }

  recalculations(args) {
    // do all the recalculations based on the category of the transaction (Billable Service, Contract Service, Warranty Service or Other)

    const qty = this.quantityObject ? this.quantityObject.value : args.rowData.qty;
    const price = this.priceObject ? this.priceObject.value : args.rowData.price;
    let discount = this.discountAmountObject ? this.discountAmountObject.value : args.rowData.discountAmount;
    const tax = this.getTaxRate(this.costTypeObject.value);
    const warranty = this.isKingslynn && this.rowServiceItemGroup === '250SVC' ? 0 : this.quantityObject.value * (this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Warranty Service' && this.getWarrantyAmount() || 0) || 0;
    const contract = this.isKingslynn && this.rowServiceItemGroup === '250SVC' ? 0 : this.quantityObject.value * (this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Contract Service' && this.getContractAmount() || 0) || 0;

    if (!['Billable Service', 'Warranty Service', 'Contract Service'].includes(this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode))) {
      discount = price * qty;
      if (this.discountAmountObject) {
        this.discountAmountObject.value = price && qty ? price * qty : 0;
        this.discountAmountObject.max = price && qty ? price * qty : null;
      }
    }

    if (this.warrantyAmountObject) {
      this.warrantyAmountObject.value = warranty;
    }
    if (this.contractAmountObject) {
      this.contractAmountObject.value = contract;
    }
    this.calculateTotalAmount({ qty, price, discount, tax, warranty, contract });
  }

  updateCostTypeDataSource(args) {
    let costTypes = this.costTypes;
    if (args.itemData.referenceActivityDescription === 'Warranty Service' && this.equipmentInfoData) {
      this.equipmentInfoData.warrantyDescriptionObj.forEach(ws => {
        if (ws.costType === 'all' && new Date(ws.expiryDate) < new Date(this.data.soCreatedDate)) {
          costTypes = [];
        } else if (ws.costType.toLowerCase() === 'material' && new Date(ws.expiryDate) < new Date(this.data.soCreatedDate)) {
          costTypes = costTypes.filter(x => x.value !== 'Material');
        } else if (ws.costType.toLowerCase() === 'labor' && new Date(ws.expiryDate) < new Date(this.data.soCreatedDate)) {
          costTypes = costTypes.filter(x => x.value !== 'Labor');
        } else if (ws.costType.toLowerCase() === 'travel' && new Date(ws.expiryDate) < new Date(this.data.soCreatedDate)) { // Update the data (Instead of travel it should be callOut from the API.)
          costTypes = costTypes.filter(x => x.value !== 'CallOut');
        }
      });
      if (this.equipmentInfoData.warrantyDescriptionObj.findIndex(x => x.costType.toLowerCase() === 'material') === -1) {
        costTypes = costTypes.filter(x => x.value !== CostTypes.material);
      }
      if (this.equipmentInfoData.warrantyDescriptionObj.findIndex(x => x.costType.toLowerCase() === 'labor') === -1) {
        costTypes = costTypes.filter(x => x.value !== CostTypes.labor);
      }
      if (this.equipmentInfoData.warrantyDescriptionObj.findIndex(x => x.costType.toLowerCase() === 'travel') === -1) {
        costTypes = costTypes.filter(x => x.value !== 'CallOut');
      }
    } else if (args.itemData.referenceActivityDescription === 'Contract Service' && this.equipmentInfoData) {
      this.equipmentInfoData.serviceContractDescriptionObj.forEach(cs => {
        if (cs.costType === 'all' && new Date(cs.expiryDate) < new Date(this.data.soCreatedDate)) {
          costTypes = [];
        } else if (cs.costType.toLowerCase() === 'material' && new Date(cs.expiryDate) < new Date(this.data.soCreatedDate)) {
          costTypes = costTypes.filter(x => x.value !== 'Material');
        } else if (cs.costType.toLowerCase() === 'labor' && new Date(cs.expiryDate) < new Date(this.data.soCreatedDate)) {
          costTypes = costTypes.filter(x => x.value !== 'Labor');
        } else if (cs.costType.toLowerCase() === 'travel' && new Date(cs.expiryDate) < new Date(this.data.soCreatedDate)) {
          costTypes = costTypes.filter(x => x.value !== 'CallOut');
        }
      });
      if (this.equipmentInfoData.serviceContractDescriptionObj.findIndex(x => x.costType.toLowerCase() === 'material') === -1) {
        costTypes = costTypes.filter(x => x.value !== CostTypes.material);
      }
      if (this.equipmentInfoData.serviceContractDescriptionObj.findIndex(x => x.costType.toLowerCase() === 'labor') === -1) {
        costTypes = costTypes.filter(x => x.value !== CostTypes.labor);
      }
      if (this.equipmentInfoData.serviceContractDescriptionObj.findIndex(x => x.costType.toLowerCase() === 'travel') === -1) {
        costTypes = costTypes.filter(x => x.value !== 'CallOut');
      }
    }
    this.costTypeObject.dataSource = costTypes;
  }

  editAssignmentDetailReferenceActivity() {
    return {
      create: () => {
        this.assignmentDetailReferenceActivity = document.createElement('input');
        return this.assignmentDetailReferenceActivity;
      },
      read: () => {
        if (this.assignmentDetailReferenceActivityObject) {
          return this.assignmentDetailReferenceActivityObject.value;
        }
      },
      destroy: () => {
        this.assignmentDetailReferenceActivityObject.destroy();
      },
      write: (args) => {
        this.assignmentDetailReferenceActivityObject = new TextBox({
          value: args.rowData.referenceActivityCode,
          enabled: false
        });
        this.assignmentDetailReferenceActivityObject.appendTo(this.assignmentDetailReferenceActivity);
      }
    }
  }

  editAssignmentDetailServiceType() {
    return {
      create: () => {
        this.assignmentDetailServiceType = document.createElement('input');
        return this.assignmentDetailServiceType;
      },
      read: () => {
        if (this.assignmentDetailServiceTypeObject) {
          return this.assignmentDetailServiceTypeObject.value;
        }
      },
      destroy: () => {
        this.assignmentDetailServiceTypeObject.destroy();
      },
      write: (args) => {
        this.assignmentDetailServiceTypeObject = new TextBox({
          value: args.rowData.serviceTypeDescription,
          enabled: false
        });
        this.assignmentDetailServiceTypeObject.appendTo(this.assignmentDetailServiceType);
      }
    }
  }

  editCostType() {
    return {
      create: () => {
        this.costType = document.createElement('input');
        return this.costType;
      },
      read: () => {
        if (this.costTypeObject) {
          return this.costTypeObject.value;
        }
      },
      destroy: () => {
        this.costTypeObject.destroy();
      },
      write: (args) => {
        this.costTypeObject = new DropDownList({
          dataSource: this.costTypes,
          fields: { value: 'value', text: 'text' },
          value: args.rowData.costType,
          popupWidth: '150px',
          change: event => {
            this.quantityObject.enabled = this.isQuantityEnabled();
            this.setTransactionValues(event.value);
            if (event.value !== CostTypes.material) {
              delete this.assignmentDetailsGrid.getColumnByField('qty').validationRules['max'];
            }
            this.setItemCodeValidation(event.value);

            this.tempUnit = this.getUnit(event.value);
            const tooltipElement = document.getElementById('qty_Error');
            if (tooltipElement) {
              tooltipElement.style.display = 'none';
            }
          }
        });
        this.costTypeObject.appendTo(this.costType);
      }
    }
  }

  setItemCodeValidation(costType) {
    this.assignmentDetailsGrid.getColumnByField('itemCode').validationRules['required'] = costType === CostTypes.material || costType === CostTypes.otherCost;
    costType !== CostTypes.material && document.getElementById('itemCode_Error')?.remove();
  }

  setTransactionValues(costType: string) {
    const anchorElement = document.getElementsByClassName('item-advance-search-icon')[0];
    if (costType === CostTypes.labor) {
      this.priceObject.value = this.laborRate;
      anchorElement.classList.add('d-none');
      if (this.isIsc || this.isIscManager) {
        this.subcostTypeObject.value = null;
        this.subcostTypeObject.enabled = false;
      }
    } else if (costType === CostTypes.other) {
      this.quantityObject.value = 1;
      if (this.isIsc || this.isIscManager) {
        this.subcostTypeObject.enabled = true;
        this.subcostTypeObject.value = SubCostTypes.travel;
      }
      anchorElement.classList.add('d-none');
      this.priceObject.value = this.calloutRate;
    } else if (costType === CostTypes.material || costType === CostTypes.otherCost) {
      this.priceObject.value = 0;
      anchorElement.classList.remove('d-none');
      if (this.isIsc || this.isIscManager) {
        if (this.subcostTypeObject) {
          this.subcostTypeObject.value = null;
          this.subcostTypeObject.enabled = false;
        }
      }
      if (this.USER.company === Companies.kingslynn) {
        this.getItemList();
        this.quantityObject.min = costType === CostTypes.otherCost ? 1 : 0;
      }
    } else {
      this.priceObject.value = 0;
      anchorElement.classList.add('d-none');
    }

    if (this.itemDescriptionObject) {
      this.itemDescriptionObject.value = this.getItemDescription(costType, true);
    }

    if (this.laborTypeObject) {
      this.laborTypeObject.value = this.getLaborType(costType);
    }

    if (this.unitObject) {
      this.unitObject.value = this.getUnit(costType);
    }

    if (this.costComponentObject) {
      this.costComponentObject.value = this.getCostComponent(costType);
    }

    if (this.transactionWarehouseObject) {
      this.transactionWarehouseObject.value = this.getWarehouse(costType);
    }

    if (this.taskObject) {
      this.taskObject.value = this.getTask(costType);
    }

    this.quantityObject.value = 1;
    this.discountAmountObject.value = 0;
    this.totalAmountObject.value = 0;
    this.totalAmount = 0;

    if (this.assignmentDetailStartTimeObject) {
      this.assignmentDetailStartTimeObject.value = this.assignmentDetailStartTimeValue;
    }

    if (this.taxObject) {
      this.taxObject.value = 0;
    }

    if (this.warrantyAmountObject) {
      this.warrantyAmountObject.value = 0;
    }

    if (this.contractAmountObject) {
      this.contractAmountObject.value = 0;
    }
    this.taxAmount = 0;

    if (this.taxPercentObject) {
      this.taxPercentObject.value = this.getTaxRate(costType);
    }

    if (!this.isIsc && !this.isIscManager) {
      this.priceObject.enabled = !this.isKingslynn && costType === CostTypes.material;
      if (this.subcostTypeObject) {
        this.subcostTypeObject.enabled = false;
      }
    } else {
      if (costType === CostTypes.other) {
        if (this.subcostTypeObject) {
          this.subcostTypeObject.enabled = true;
        }
      } else {
        this.assignmentDetailsGrid.getColumnByField('comments').validationRules['required'] = false;
        if (this.commentsObject) {
          this.commentsObject.value = null;
        }
        if (this.subcostTypeObject) {
          this.subcostTypeObject.value = null;
          this.subcostTypeObject.enabled = false;
        }
      }
    }

    if (this.returnReasonObject) {
      this.returnReasonObject.enabled = costType === CostTypes.material;
    }

    this.itemCodeObject.value = null;
    this.itemCodeObject.enabled = costType === CostTypes.material || costType === CostTypes.otherCost;

    this.quantityObject.format = costType === CostTypes.material ? 'n' : 'n2';
    this.quantityObject.step = costType === CostTypes.material ? 1 : 0.1;
    this.quantityObject.decimals = costType === CostTypes.material ? 0 : 2;

    this.priceObject.enabled = this.isOffline && (this.isIsc || this.isIscManager);
  }

  editSubCostType() {
    return {
      create: () => {
        this.subcostType = document.createElement('input');
        return this.subcostType;
      },
      read: () => {
        if (this.subcostTypeObject) {
          return this.subcostTypeObject.value;
        }
      },
      destroy: () => {
        if (this.subcostTypeObject) {
          this.subcostTypeObject.destroy();
        }
      },
      write: (args) => {
        this.subcostTypeObject = new DropDownList({
          dataSource: SubCostTypeList,
          fields: { value: 'value', text: 'text' },
          value: args.rowData.subCostType || '',
          popupWidth: '150px',
          change: event => {
            this.quantityObject.enabled = this.isQuantityEnabled();
            switch (event.value) {
              case SubCostTypes.travel:
                // same as FST
                if (this.descriptionObject)
                  this.descriptionObject.value = "Travel call-out charge";
                this.priceObject.enabled = this.isOffline;
                this.priceObject.value = this.calloutRate;
                this.quantityObject.value = 1;
                break;
              case SubCostTypes.mileage:
                // get mileageRate form this.USER into price
                // quantity is mandatory
                if (this.descriptionObject)
                  this.descriptionObject.value = null;
                this.priceObject.value = this.USER.mileageRate;
                break;
              case SubCostTypes.tax:
                // price will be the sum of taxAmount of all transactions.
                // price will be editable/enabled:true
                if (this.descriptionObject)
                  this.descriptionObject.value = null;
                this.priceObject.enabled = true;
                this.priceObject.value = this.getTotalTaxAmount();
                break;
              case SubCostTypes.miscellaneous:
              case SubCostTypes.install:
              case SubCostTypes.freight:
                // price will be enabled and mandatory
                // comments will be enabled and mandatory
                this.priceObject.value = 0;
                if (event.value === SubCostTypes.miscellaneous) {
                  if (this.commentsObject)
                    this.commentsObject.enabled = true;
                  this.assignmentDetailsGrid.getColumnByField('comments').validationRules['required'] = true;
                }
                if (this.descriptionObject)
                  this.descriptionObject.value = null;
                this.priceObject.enabled = true;
                this.assignmentDetailsGrid.getColumnByField('price').validationRules['required'] = true;
                break;
            }
          }
        });
        this.subcostTypeObject.appendTo(this.subcostType);
      }
    }
  }

  getTotalTaxAmount() {
    let tax = 0;
    (this.assignmentDetailsGrid?.dataSource as any)?.forEach(trasaction => {
      tax += trasaction.taxAmount;
    });
    return +tax?.toFixed(2);
  }

  showItemPopup() {
    if (this.costTypeObject && this.costTypeObject.value && this.costTypeObject.value === CostTypes.otherCost) {
      this.serviceItemGroup = '350SVC';
    } else {
      this.serviceItemGroup = '';
    }
    this.showItemAdvancedSearchPopup = true;
  }

  onItemAdvancedSearchPopupClose(data: any) {
    this.itemChangedFromAdvanceSearch = true;
    this.rowServiceItemGroup = data?.serviceItemGroup;
    if (this.assignmentDetailsGrid.isEdit) {
      this.serviceOrderService.popupLoader.next(true);
      if (data) {
        this.itemDescriptionObject.value = data?.itemDescription;
        this.itemCodeObject.dataSource = [{
          text: `${data?.item} | ${data?.itemDescription}`,
          value: data?.item,
          ...data
        }];
        this.itemCodeObject.value = data?.item;
        if (this.isKingslynn) {
          this.discountAmountObject.enabled = this.getRefActivityCategory(this.assignmentObject?.itemData?.referenceActivityCode) === 'Billable Service' || data.serviceItemGroup === '250SVC';
        }
      }
      setTimeout(() => {
        if (this.isKingslynn && data && this.coreItemGroup.includes(data?.serviceItemGroup)) {
          this.commonService.showNotification('warning', 'Return Required', 'center', 5000);
        }
        this.showItemAdvancedSearchPopup = false;
        this.serviceOrderService.popupLoader.next(false);
      }, 400);
    } else {
      const rowIndex = this.assignmentDetailsGrid.getRowIndexByPrimaryKey(this.selectedRowId);
      this.serviceOrderService.popupLoader.next(true);
      this.showItemAdvancedSearchPopup = false;
      setTimeout(() => {
        this.assignmentDetailsGrid.selectRow(rowIndex);
        this.skipBeginEditEvent = true;
        this.assignmentDetailsGrid.startEdit();
        if (data) {
          setTimeout(() => {
            if (this.isKingslynn) {
              this.coreItemGroup.includes(data?.serviceItemGroup)
                && this.commonService.showNotification('warning', 'Return Required', 'center', 5000);
              this.discountAmountObject.enabled = this.getRefActivityCategory(this.assignmentObject?.itemData?.referenceActivityCode) === 'Billable Service' || data.serviceItemGroup === '250SVC';
            }
            this.serviceOrderService.popupLoader.next(false);
            this.itemDescriptionObject.value = data?.itemDescription;
            this.itemCodeObject.dataSource = [{
              text: `${data?.item} | ${data?.itemDescription}`,
              value: data?.item,
              ...data
            }];
            this.itemCodeObject.value = data?.item;
          }, 400);
        } else {
          this.serviceOrderService.popupLoader.next(false);
        }
      }, 2000);
    }
  }

  addAdvancedSearchIcon(costType) {
    const itemAnchor = document.createElement('a');
    itemAnchor.classList.add('item-advance-search-icon');
    if (costType !== CostTypes.material || costType !== CostTypes.otherCost) {
      itemAnchor.classList.add('d-none');
    }
    itemAnchor.setAttribute('href', 'javascript:');
    itemAnchor.setAttribute('title', 'Advanced Search');
    itemAnchor.addEventListener('click', this.showItemPopup.bind(this));
    const icon = document.createElement('i');
    icon.classList.add('fas');
    icon.classList.add('fa-info');
    itemAnchor.appendChild(icon);
    this.itemCode.parentElement.parentElement.append(itemAnchor);
  }

  editItem() {
    return {
      create: args => {
        this.selectedRowId = args.data.rowId;
        this.itemCode = document.createElement('input');
        return this.itemCode;
      },
      read: () => {
        if (this.itemCodeObject) {
          return this.itemCodeObject.value;
        }
      },
      destroy: () => {
        this.itemCodeObject.destroy();
      },
      write: (args) => {
        this.itemCodeObject = new DropDownList({
          dataSource: this.itemsList,
          fields: { value: 'value', text: 'text' },
          value: args.rowData.itemCode,
          cssClass: 'generic-items-dropdown-list',
          // itemTemplate: '<span title="${text}">${text}</span>',
          allowFiltering: true,
          showClearButton: true,
          popupWidth: '350px',
          filterType: 'Contains',
          enabled: this.costTypeObject.value === CostTypes.material || this.costTypeObject.value === CostTypes.otherCost,
          filtering: args => { this.onItemUpdate.next(args.text) },
          focus: args => { this.itemCodeObject.showPopup() },
          blur: args => {
            if (this.itemCodeObject['isSelectCustom']) {
              this.itemCodeObject.value = null;
            }
          },
          change: event => {
            if (event.value && event.item) {
              this.getPriceForItem = event.isInteracted || this.itemChangedFromAdvanceSearch;
              if (!this.isIsc && !this.isIscManager && this.costTypeObject.value !== CostTypes.otherCost && this.data.mode === 'Actual') {
                this.checkOnHandQuantity(event.itemData?.value, args.rowData.qty, args.rowData.issuedQty, args.rowData);
              }
              const unit = this.tempUnit;
              if (event.itemData?.value !== 'LOCAL' && this.getPriceForItem) {
                this.getItemPrice(event.itemData?.value, unit, args.rowData, this.quantityObject.value);
              }
              if (this.isKingslynn && event.itemData && this.coreItemGroup.includes(event.itemData.serviceItemGroup)) {
                this.commonService.showNotification('warning', 'Return Required', 'center', 5000);
              }
              this.itemDescriptionObject.value = event.itemData?.itemDescription ? event.itemData.itemDescription : null;
              this.serviceItemGroup = event.itemData?.serviceItemGroup;
              this.rowServiceItemGroup = event.itemData?.serviceItemGroup;
              this.itemGroup = event.itemData?.itemGroup;
              if (this.discountAmountObject && this.isKingslynn) {
                this.discountAmountObject.enabled = this.getRefActivityCategory(this.assignmentObject?.itemData?.referenceActivityCode) === 'Billable Service' || event.itemData.serviceItemGroup === '250SVC';
              }
              this.itemChangedFromAdvanceSearch = false;
            }
          }
        });
        this.itemCodeObject.appendTo(this.itemCode);
        this.addAdvancedSearchIcon(args.rowData.costType);
      }
    }
  }

  getItemPrice(itemCode, unit, rowData, quantity = 1) {
    this.showLoader = true;
    const data = {
      company: this.USER.company,
      item: itemCode,
      unit: unit,
      quantity,
      configuration: this.data.customerCode
    };
    this.serviceOrderService.getPrice(data)
      .subscribe((res: ApiResponse) => {
        if (res && res.isSuccessful && res.result) {
          this.priceObject.value = res.result.bookPrice ? +res.result.bookPrice.bookPriceValue?.toFixed(2) : 0;
          this.discountAmountObject.value = res.result?.discountAmount ? +res.result.discountAmount.discountAmountValue?.toFixed(2) : 0;
        } else {
          this.commonService.showDialogueWithNoTimer('error', "RIMS failed to get the price of this item. \n Please Contact Support or Enter the price of the item manually.");
        }
        this.showLoader = false;
      }, (error: HttpErrorResponse) => {
        this.priceObject.value = 0;
        this.discountAmountObject.value = 0;
          this.commonService.showDialogueWithNoTimer('error', "RIMS failed to get the price of this item. \n Please Contact Support or Enter the price of the item manually.");
        this.showLoader = false;
      });
  }

  checkOnHandQuantity(itemCode, rowQty = 1, issuedQty, rowData) {
    if (itemCode) {
      this.serviceOrderService.popupLoader.next(true);
      this.inventoryService.getTechnicianOnHandCount(itemCode, this.warehouse)
        .subscribe((res: ApiResponse) => {
          if (res && res.result) {
            const actualOnHand = this.updateItemOnHandQuantity(itemCode, res.result.onHandCount, rowData);//issuedQty != null ? res.result.onHandCount + issuedQty : res.result.onHandCount;
            this.assignmentDetailsGrid.getColumnByField('qty').validationRules['max'] = [actualOnHand, 'On Hand: ' + actualOnHand];
            this.onHandQuantity = actualOnHand;
            if (!actualOnHand) {
              this.quantityObject && (this.quantityObject.value = 0);
              this.totalAmountObject && (this.totalAmountObject.value = 0);
            } else {
              this.quantityObject && (this.quantityObject.value = rowQty);
            }
          } else {
            if (this.costTypeObject && this.costTypeObject.value === CostTypes.material) {
              this.onHandQuantity = 0;
              this.quantityObject && (this.quantityObject.value = 0);
              this.totalAmountObject && (this.totalAmountObject.value = 0);
              this.assignmentDetailsGrid.getColumnByField('qty').validationRules['max'] = [0, 'On Hand: ' + 0];
            }
          }
          this.serviceOrderService.popupLoader.next(false);
        }, (error: HttpErrorResponse) => {
          if (this.costTypeObject && this.costTypeObject.value === CostTypes.material) {
            this.onHandQuantity = 0;
            this.quantityObject && (this.quantityObject.value = 0);
            this.totalAmountObject && (this.totalAmountObject.value = 0);
            this.assignmentDetailsGrid.getColumnByField('qty').validationRules['max'] = [0, 'On Hand: ' + 0];
          }
          this.serviceOrderService.popupLoader.next(false);
          throw error;
        });
    }
  }

  updateItemOnHandQuantity(itemCode, onHandQty, rowData) {
    let calculatedOnHand = onHandQty;
    this.loadedAssignmentItemDetails.forEach(x => {
      if (itemCode === x.itemCode) {
        calculatedOnHand += x.issuedQty;
      }
    });
    (this.assignmentDetailsGrid.dataSource as any).forEach(x => {
      if (itemCode === x.itemCode && !x.isDeleted) {
        if (!this.assignmentDetailsGrid.isEdit || rowData.rowId != x.rowId)
          calculatedOnHand -= x.qty;
      }
    });
    return calculatedOnHand;
  }

  editItemDescription() {
    return {
      create: () => {
        this.itemDescription = document.createElement('input');
        return this.itemDescription;
      },
      read: () => {
        if (this.itemDescriptionObject) {
          return this.itemDescriptionObject.value;
        }
      },
      destroy: () => {
        this.itemDescriptionObject.destroy();
        this.itemDescriptionObject = null;
      },
      write: (args) => {
        this.itemDescriptionObject = new TextBox({
          value: args.rowData.itemDescription,
          enabled: false
        });
        this.itemDescriptionObject.appendTo(this.itemDescription);
      }
    }
  }

  editComments() {
    return {
      create: () => {
        this.comments = document.createElement('input');
        return this.comments;
      },
      read: () => {
        if (this.commentsObject) {
          return this.commentsObject.value;
        }
      },
      destroy: () => {
        this.commentsObject.destroy();
      },
      write: (args) => {
        this.commentsObject = new TextBox({
          value: args.rowData.comments,
          // enabled: false
        });
        this.commentsObject.appendTo(this.comments);
      }
    }
  }

  editQuantity() {
    let tooltip;
    return {
      create: () => {
        this.quantity = document.createElement('input');
        return this.quantity;
      },
      read: () => {
        if (this.quantityObject) {
          return this.quantityObject.value;
        }
      },
      destroy: () => {
        this.onHandQuantity = undefined;
        tooltip && tooltip.destroy();
        this.quantityObject.destroy();
      },
      write: (args) => {
        tooltip = new Tooltip({
          //Set tooltip content
          content: 'On Hand:' + this.onHandQuantity,
          opensOn: 'Focus',
          beforeOpen: args => {
            if (this.costTypeObject.value !== CostTypes.material || this.onHandQuantity === undefined) {
              args.cancel = true;
            }
            tooltip.content = 'On Hand: ' + this.onHandQuantity;
          }
        });
        //Render initialized Tooltip component
        tooltip.appendTo(this.quantity);
        this.quantityObject = new NumericTextBox({
          value: args.rowData.qty,
          showSpinButton: false,
          format: this.costTypeObject.value === CostTypes.material ? 'n' : 'n2',
          min: 0,
          step: this.costTypeObject.value === CostTypes.material ? 1 : 0.1,
          decimals: this.costTypeObject.value === CostTypes.material ? 0 : 2,
          validateDecimalOnType: true,
          enabled: this.isQuantityEnabled(),
          created: args => {
            this.quantity.onkeyup = event => {
              if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                this.quantityObject.value = event.target.value;
              }
            }
          },
          change: event => {
            const qty = +event.value?.toFixed(2);
            const costType = this.costTypeObject.value;

            if (costType === CostTypes.material && this.itemCodeObject.value) {
              this.tempUnit = this.getUnit(costType);
              const data = {
                company: this.USER.company,
                item: this.itemCodeObject.value,
                unit: this.tempUnit,
                quantity: qty,
                configuration: this.data.customerCode
              };
              this.showLoader = true;
              this.serviceOrderService.getPrice(data).subscribe((res: ApiResponse) => {
                if (res && res.isSuccessful && res.result) {
                  this.priceObject.value = res.result.bookPrice ? +res.result.bookPrice.bookPriceValue?.toFixed(2) : 0;
                  this.discountAmountObject.value = res.result.discountAmount ? +res.result.discountAmount.discountAmountValue?.toFixed(2) : 0;
                  this.onQuantityChange(args, qty);
                } else {
                  this.commonService.showDialogueWithNoTimer('error', "RIMS failed to get the price of this item. \n Please Contact Support or Enter the price of the item manually.");
                }
                this.showLoader = false;
              }, (error: HttpErrorResponse) => {
                this.priceObject.value = 0;
                this.discountAmountObject.value = 0;
                this.commonService.showDialogueWithNoTimer('error', "RIMS failed to get the price of this item. \n Please Contact Support or Enter the price of the item manually.");
                this.showLoader = false;
              });
            } else { // for cost types other & labor
              this.onQuantityChange(args, qty);
            }
          }
        });
        this.quantityObject.appendTo(this.quantity);
      }
    }
  }

  isQuantityEnabled() {
    if (this.isKingslynn) 
      return true;
    else 
      return this.costTypeObject.value !== CostTypes.other;
  }

  onQuantityChange(args, qty) {
    const price = this.priceObject ? +this.priceObject.value?.toFixed(2) : +args.rowData.price.toFixed(2);
    let discount = this.discountAmountObject ? +this.discountAmountObject.value?.toFixed(2) : +args.rowData.discountAmount.toFixed(2);
    const tax = +args.rowData.taxpercent?.toFixed(2);
    const warranty = this.isKingslynn && this.rowServiceItemGroup === '250SVC' ? 0 : this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Warranty Service' && this.getWarrantyAmount() || 0;
    const contract = this.isKingslynn && this.rowServiceItemGroup === '250SVC' ? 0 : this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Contract Service' && this.getContractAmount() || 0;

    if (!['Billable Service', 'Warranty Service', 'Contract Service'].includes(this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode))) {
      discount = price * qty;
      if (this.discountAmountObject) {
        this.discountAmountObject.value = price && qty ? price * qty : 0;
        this.discountAmountObject.max = price && qty ? price * qty : null;
      }
    }
    if (this.warrantyAmountObject) {
      this.warrantyAmountObject.value = warranty;
    }
    if (this.contractAmountObject) {
      this.contractAmountObject.value = contract;
    }
    this.calculateTotalAmount({ qty, price, discount, tax, warranty, contract });
  }

  getRefActivityCategory(refActivityCode) {
    const refActivity = this.referenceActivities.find(act => act.value === refActivityCode);
    return refActivity ? refActivity.category : '';
  }

  editPrice() {
    return {
      create: () => {
        this.price = document.createElement('input');
        return this.price;
      },
      read: () => {
        if (this.priceObject) {
          return this.priceObject.value;
        }
      },
      destroy: () => {
        this.priceObject.destroy();
      },
      write: (args) => {
        this.priceObject = new NumericTextBox({
          value: args.rowData.price,
          showSpinButton: false,
          format: 'n2',
          min: 0,
          decimals: 2,
          enabled: !this.isKingslynn && (args.rowData.costType === CostTypes.material || this.isIsc || this.isIscManager),
          created: () => {
            this.price.onkeyup = event => {
              if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                this.priceObject.value = event.target.value;
              }
            }
          },
          change: (event) => {
            const qty = this.quantityObject ? this.quantityObject.value : args.rowData.qty;
            const price = +event.value?.toFixed(2);
            let discount = this.discountAmountObject ? this.discountAmountObject.value : args.rowData.discountAmount;
            const tax = args.rowData.taxpercent;
            const warranty = this.isKingslynn && this.rowServiceItemGroup === '250SVC' ? 0 : this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Warranty Service' && this.getWarrantyAmount() || 0;
            const contract = this.isKingslynn && this.rowServiceItemGroup === '250SVC' ? 0 : this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Contract Service' && this.getContractAmount() || 0;

            if (!['Billable Service', 'Warranty Service', 'Contract Service'].includes(this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode))) {
              discount = price * qty;
              if (this.discountAmountObject) {
                this.discountAmountObject.value = price && qty ? price * qty : 0;
                this.discountAmountObject.max = price && qty ? price * qty : null;
              }
            }
            if (this.warrantyAmountObject) {
              this.warrantyAmountObject.value = warranty;
            }
            if (this.contractAmountObject) {
              this.contractAmountObject.value = contract;
            }
            this.calculateTotalAmount({ qty, price, discount, tax, warranty, contract });
          }
        });
        this.priceObject.appendTo(this.price);
      }
    }
  }

  calculateTotalAmount(data) {
    const { qty, price, discount, warranty, contract, tax } = data;
    this.totalAmountValue = +(qty && (price * qty) - (discount || 0) - (warranty || 0) - (contract || 0))?.toFixed(2);
    this.taxAmount = +(this.totalAmountValue * tax / 100)?.toFixed(2);

    if (this.totalAmountObject) {
      this.totalAmountObject.value = this.totalAmountValue;
    }
  }

  editDiscountAmount() {
    return {
      create: () => {
        this.discountAmount = document.createElement('input');
        return this.discountAmount;
      },
      read: () => {
        if (this.discountAmountObject) {
          return this.discountAmountObject.value;
        }
      },
      destroy: () => {
        this.discountAmountObject.destroy();
      },
      write: (args) => {
        this.discountAmountObject = new NumericTextBox({
          value: args.rowData.discountAmount || 0,
          format: 'n2',
          min: 0,
          decimals: 2,
          max: this.priceObject.value && this.quantityObject.value && this.priceObject.value * this.quantityObject.value || null,
          enabled: this.getRefActivityCategory(this.assignmentObject?.itemData?.referenceActivityCode) === 'Billable Service' || args.rowData.serviceItemGroup === '250SVC',
          showSpinButton: false,
          created: args => {
            this.discountAmount.onkeyup = event => {
              if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode >= 96 && event.keyCode <= 105) {
                if (event.target.value.includes('.0') && event.target.value.split('.')[1].length === 1) {
                  return;
                }
                this.discountAmountObject.value = event.target.value;
              }
            }
          },
          focus: args => {
            const price = this.priceObject && this.priceObject.value,
              qty = this.quantityObject && this.quantityObject.value;
            this.discountAmountObject.max = price && qty ? price * qty : null;
          },
          change: (event) => {
            this.discountValue = +event.value?.toFixed(2);
            const qty = this.quantityObject ? this.quantityObject.value : args.rowData.qty;
            const price = this.priceObject ? this.priceObject.value : args.rowData.price;
            let discount = +event.value?.toFixed(2);
            const tax = args.rowData.taxpercent;

            const warranty = this.isKingslynn && this.rowServiceItemGroup === '250SVC' ? 0 : this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Warranty Service' && this.getWarrantyAmount() || 0;
            const contract = this.isKingslynn && this.rowServiceItemGroup === '250SVC' ? 0 : this.assignmentObject.value && this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode) === 'Contract Service' && this.getContractAmount() || 0;

            if (!['Billable Service', 'Warranty Service', 'Contract Service'].includes(this.getRefActivityCategory(this.assignmentObject.itemData.referenceActivityCode))) {
              discount = price * qty;
              if (this.discountAmountObject) {
                this.discountAmountObject.value = price && qty ? price * qty : 0;
                this.discountAmountObject.max = price && qty ? price * qty : null;
              }
            }

            if (this.warrantyAmountObject) {
              this.warrantyAmountObject.value = warranty;
            }
            if (this.contractAmountObject) {
              this.contractAmountObject.value = contract;
            }
            this.calculateTotalAmount({ qty, price, discount, tax, warranty, contract });
            if (this.USER.company === Companies.kingslynn) {
              this.assignmentDetailsGrid.getColumnByField('comments').validationRules['required'] = this.discountValue > 0;
            }
          }
        });
        this.discountAmountObject.appendTo(this.discountAmount);
      }
    }
  }

  editContractAmount() {
    return {
      create: () => {
        this.contractAmount = document.createElement('input');
        return this.contractAmount;
      },
      read: () => {
        if (this.contractAmountObject) {
          return this.contractAmountObject.value;
        }
      },
      destroy: () => {
        this.contractAmountObject.destroy();
      },
      write: (args) => {
        this.contractAmountObject = new NumericTextBox({
          value: args.rowData.contractCoverage || 0,
          min: 0,
          enabled: false,
          showSpinButton: false
        });
        this.contractAmountObject.appendTo(this.contractAmount);
      }
    }
  }

  editWarrantyAmount() {
    return {
      create: () => {
        this.warrantyAmount = document.createElement('input');
        return this.warrantyAmount;
      },
      read: () => {
        if (this.warrantyAmountObject) {
          return this.warrantyAmountObject.value;
        }
      },
      destroy: () => {
        this.warrantyAmountObject.destroy();
      },
      write: (args) => {
        this.warrantyAmountObject = new NumericTextBox({
          value: args.rowData.warrantyCoverage || 0,
          min: 0,
          enabled: false,
          showSpinButton: false
        });
        this.warrantyAmountObject.appendTo(this.warrantyAmount);
      }
    }
  }

  editTotalAmount() {
    return {
      create: () => {
        this.totalAmount = document.createElement('input');
        return this.totalAmount;
      },
      read: () => {
        if (this.totalAmountObject) {
          return this.totalAmountObject.value;
        }
      },
      destroy: () => {
        this.totalAmountObject.destroy();
      },
      write: (args) => {
        this.totalAmountObject = new NumericTextBox({
          value: args.rowData.totalAmount,
          showSpinButton: false,
          enabled: false,
          min: 0,
          change: (event) => {
            this.taxAmount = +(this.totalAmountObject.value * this.getTaxRate(this.costTypeObject.value) / 100)?.toFixed(2);
            if (this.taxObject) {
              this.taxObject.value = this.taxAmount;
            }
          }
        });
        this.totalAmountObject.appendTo(this.totalAmount);
      }
    }
  }

  editUnit() {
    return {
      create: () => {
        this.unit = document.createElement('input');
        return this.unit;
      },
      read: () => {
        if (this.unitObject) {
          return this.unitObject.value;
        }
      },
      destroy: () => {
        this.unitObject.destroy();
      },
      write: (args) => {
        this.unitObject = new DropDownList({
          dataSource: this.units,
          fields: { value: 'value', text: 'text' },
          value: args.rowData.unit,
          allowFiltering: true,
          filterType: 'Contains',
          enabled: false
        });
        this.unitObject.appendTo(this.unit);
      }
    }
  }

  editReturnReason() {
    return {
      create: () => {
        this.returnReason = document.createElement('input');
        return this.returnReason;
      },
      read: () => {
        if (this.returnReasonObject) {
          return this.returnReasonObject.value;
        }
      },
      destroy: () => {
        this.returnReasonObject.destroy();
      },
      write: (args) => {
        this.returnReasonObject = new DropDownList({
          dataSource: this.returnReasons,
          fields: { value: 'value', text: 'text' },
          itemTemplate: '<span title="${text}">${text}</span>',
          popupWidth: '250px',
          enabled: this.costTypeObject.value === CostTypes.material,
          value: args.rowData.returnReasonCode
        });
        this.returnReasonObject.appendTo(this.returnReason);
      }
    }
  }

  editStartTime() {
    return {
      create: () => {
        this.assignmentDetailStartTime = document.createElement('input');
        return this.assignmentDetailStartTime;
      },
      read: () => {
        if (this.assignmentDetailStartTimeObject) {
          return this.assignmentDetailStartTimeObject.value;
        }
      },
      destroy: () => {
        this.assignmentDetailStartTimeObject.destroy();
      },
      write: (args) => {
        this.assignmentDetailStartTimeObject = new DateTimePicker({
          placeholder: "Select start date time...",
          value: args.rowData.startDate,
          min: args.rowData.startDate ? args.rowData.startDate : this.checkinTime,
          format: 'd MMM, y h:mm a',
          openOnFocus: true,
          allowEdit: false,
          change: ev => {
            this.assignmentDetailStartTimeValue = ev.value;
            if (this.assignmentDetailEndTimeObject) {
              this.assignmentDetailEndTimeObject.min = ev.value;
            }
          }
        });
        this.assignmentDetailStartTimeObject.appendTo(this.assignmentDetailStartTime);
      }
    }
  }

  editEndTime() {
    return {
      create: () => {
        this.assignmentDetailEndTime = document.createElement('input');
        return this.assignmentDetailEndTime;
      },
      read: () => {
        if (this.assignmentDetailEndTimeObject) {
          return this.assignmentDetailEndTimeObject.value;
        }
      },
      destroy: () => {
        this.assignmentDetailEndTimeObject.destroy();
      },
      write: (args) => {
        this.assignmentDetailEndTimeObject = new DateTimePicker({
          placeholder: "Select end date time...",
          value: args.rowData.endDate,
          min: args.rowData.startDate || new Date,
          format: 'd MMM, y h:mm a',
          openOnFocus: true,
          allowEdit: false,
          change: ev => {
            if (this.assignmentDetailStartTimeObject) {
              this.assignmentDetailStartTimeObject.max = ev.value;
            }
          }
        });
        this.assignmentDetailEndTimeObject.appendTo(this.assignmentDetailEndTime);
      }
    }
  }

  editTask() {
    return {
      create: () => {
        this.task = document.createElement('input');
        return this.task;
      },
      read: () => {
        if (this.taskObject) {
          return this.taskObject.value;
        }
      },
      destroy: () => {
        this.taskObject.destroy();
      },
      write: (args) => {
        this.taskObject = new TextBox({
          value: args.rowData.task,
          enabled: false
        });
        this.taskObject.appendTo(this.task);
      }
    }
  }

  editTax() {
    return {
      create: () => {
        this.tax = document.createElement('input');
        return this.tax;
      },
      read: () => {
        if (this.taxObject) {
          return this.taxObject.value;
        }
      },
      destroy: () => {
        this.taxObject.destroy();
      },
      write: (args) => {
        this.taxObject = new NumericTextBox({
          value: args.rowData.taxAmount,
          showSpinButton: false,
          enabled: false,
          change: args => {
          }
        });
        this.taxObject.appendTo(this.tax);
      }
    }
  }

  editTaxPercent() {
    return {
      create: () => {
        this.taxPercent = document.createElement('input');
        return this.taxPercent;
      },
      read: () => {
        if (this.taxPercentObject) {
          return this.taxPercentObject.value;
        }
      },
      destroy: () => {
        this.taxPercentObject.destroy();
      },
      write: (args) => {
        this.taxPercentObject = new NumericTextBox({
          value: args.rowData.taxpercent,
          showSpinButton: false,
          enabled: false,
          change: args => {
          }
        });
        this.taxPercentObject.appendTo(this.taxPercent);
      }
    }
  }

  editCostComponent() {
    return {
      create: () => {
        this.costComponent = document.createElement('input');
        return this.costComponent;
      },
      read: () => {
        if (this.costComponentObject) {
          return this.costComponentObject.value;
        }
      },
      destroy: () => {
        this.costComponentObject.destroy();
      },
      write: (args) => {
        this.costComponentObject = new TextBox({
          value: args.rowData.costComponent,
          enabled: false
        });
        this.costComponentObject.appendTo(this.costComponent);
      }
    }
  }

  editTransactionWarehouse() {
    return {
      create: () => {
        this.transactionWarehouse = document.createElement('input');
        return this.transactionWarehouse;
      },
      read: () => {
        if (this.transactionWarehouseObject) {
          return this.transactionWarehouseObject.value;
        }
      },
      destroy: () => {
        this.transactionWarehouseObject.destroy();
      },
      write: (args) => {
        this.transactionWarehouseObject = new TextBox({
          value: args.rowData.warehouse,
          enabled: false
        });
        this.transactionWarehouseObject.appendTo(this.transactionWarehouse);
      }
    }
  }

  editLaborType() {
    return {
      create: () => {
        this.laborType = document.createElement('input');
        return this.laborType;
      },
      read: () => {
        if (this.laborTypeObject) {
          return this.laborTypeObject.value;
        }
      },
      destroy: () => {
        this.laborTypeObject.destroy();
      },
      write: (args) => {
        this.laborTypeObject = new TextBox({
          value: args.rowData.laborType,
          enabled: false
        });
        this.laborTypeObject.appendTo(this.laborType);
      }
    }
  }

  ngOnDestroy() {
    this.loadedAssignmentItemDetails = null;
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
